<template>
  <template v-if="!isLoading">
    <div class="d-flex flex-row section-header">
      <div class="flex-grow-1">
        Neuanlage
      </div>
    </div>

    <VenueSpecsAdminItemEdit
      :editId="editId"
      :editMode="editId !== 0"
      :insertAfterItems="insertAfterItems()"
      :isEdited="editId === 0 || editId === -1"
      :venueSpecsChapterType="venueSpecsChapterType"
      :venueSpecsSection="newVenueSpecsSection"
      @refresh="refresh"
      @resetEdit="resetEdit"
      @setEdit="setEdit"
    ></VenueSpecsAdminItemEdit>

    <v-row>
      <v-col>
        <hr style="margin: 5px 0" />
      </v-col>
    </v-row>

    <draggable
      :list="allVenueSpecsSections"
      item-key="id"
      v-bind="dragOptions"
      @end="draggingEnd"
      @start="dragging = true"
    >
      <template #item="{ element }">
        <VenueSpecsAdminItemEdit
          :editId="editId"
          :editMode="editId !== 0"
          :insertAfterItems="[]"
          :isEdited="editId === element.id"
          :venueSpecsChapterType="venueSpecsChapterType"
          :venueSpecsSection="element"
          @refresh="refresh"
          @resetEdit="resetEdit"
          @setEdit="setEdit"
        ></VenueSpecsAdminItemEdit>
      </template>
    </draggable>

  </template>
</template>

<script lang="ts" setup>

import draggable                                from "vuedraggable"
import { onMounted, reactive, Ref, ref, watch } from "vue"
import VenueSpecsSection                        from "@/interfaces/VenueSpecsSection"
import VenueSpecsAdminItemEdit                  from "@/components/VenueSpecsAdminItemEdit.vue"
import VenueSpecsService                        from "@/api/VenueSpecsService"

onMounted(async () => {
  await init()
})

const isLoading = ref(true)

const tab = ref("general")
const editId = ref()

const newVenueSpecsSection = new reactive({} as VenueSpecsSection)
const allVenueSpecsSections: Ref<VenueSpecsSection[]> = ref([])

const venueSpecsService = ref(new VenueSpecsService())

const dragging = ref(false)

const props = defineProps<{
  venueSpecsChapterType: string
}>()

const emit = defineEmits(["setEditMode"])

const dragOptions = ref({
  animation: 200,
  ghostClass: "ghost",
  forceFallback: true,
  scrollSensitivity: 200,
  handle: ".handle"
})

const init = async () => {
  newVenueSpecsSection.id = -1
  newVenueSpecsSection.defaultColumnLayout = 1

  await venueSpecsService.value.getVenueSpecsSections(props.venueSpecsChapterType).then(data => {
    if (data.success) {
      allVenueSpecsSections.value = data.data
    }
  })

  editId.value = 0

  isLoading.value = false
}

const resetEdit = () => {
  editId.value = 0
}

const setEdit = (val) => {
  editId.value = val?.id ?? 0
}

const refresh = (val) => {
  allVenueSpecsSections.value = val.allVenueSpecsSections
}

watch(() => editId.value,
  () => {
    emit("setEditMode", { editMode: (editId.value !== 0) })
  }
)

const draggingEnd = () => {
  venueSpecsService.value.resortSections(allVenueSpecsSections.value, props.venueSpecsChapterType).then(data => {
    if (data.success) {
      allVenueSpecsSections.value = data.data.venueSpecsSections
    }
  })
  dragging.value = false
}

const insertAfterItems = () => {
  let arr = []

  allVenueSpecsSections.value.forEach(item => {
    arr.push({ newSortorder: (item.sortorder + 5), name: item.name })
  })

  return arr
}
</script>

<style>
.section-header > div {
  padding: 10px;
  font-weight: bold;
}
</style>
