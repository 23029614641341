<template>
  <v-container v-if="!isLoading" class="pt-14">
    <div class="navigation-button back" @click="deleteCurrentAndBackOrLogout('back')">zurück</div>
    <div class="navigation-button logout" @click="deleteCurrentAndBackOrLogout('logout')">Logout</div>

    <div class="text-center">
      <h2>
        {{ tourCashRegister.tourName }}
      </h2>
      <div class="tour-cash-register-headline">
        Akt. Kassenbestand: {{ formatAmountAndCurrency(tourCashRegister.amount, tourCashRegister.currency) }}<br>
      </div>

      <div class="tour-cash-register-head-info mt-3">
        Bitte <b>vor dem Fotografieren</b> und Hochladen folgende Nummer auf den Beleg schreiben.
      </div>

      <div class="tour-cash-register-receipt-document-number mt-3">
        {{ tourCashRegisterReceipt.documentNumber }}
      </div>

      <v-form @submit.prevent="saveReceipt" ref="myForm" v-model="isValid" class="mt-3">
        <div>
          <v-file-input
            v-model="tourCashRegisterReceipt.documentFile"
            accept="application/pdf,image/png,image/jpeg"
            label="Beleg fotografieren"
            prepend-icon="mdi-camera"
            :rules="requiredRule"
          ></v-file-input>
        </div>

        <div>
          <v-row>
            <v-col sm="12" md="6">
              <v-btn
                v-if="type === 'multiple'"
                @click="saveReceipt('save-and-new')"
                color="success"
                :disabled="isSaving"
                style="width: 100%"
              >
                <span
                  class="pr-2"
                  v-if="isSaving"
                >
                  <v-icon icon="fa:fas fa-rotate fa-spin"></v-icon>
                </span>
                Hochladen und Weiter
              </v-btn>
            </v-col>

            <v-col sm="12" md="6">
              <v-btn
                v-if="type === 'multiple'"
                @click="saveReceipt('save-and-done')"
                color="success"
                :disabled="isSaving"
                style="width: 100%"
              >
                <span
                  class="pr-2"
                  v-if="isSaving"
                >
                  <v-icon icon="fa:fas fa-rotate fa-spin"></v-icon>
                </span>
                Hochladen und Beenden
              </v-btn>
            </v-col>
          </v-row>

          <v-btn
            v-if="type === 'single'"
            @click="saveReceipt('save-and-edit')"
            color="success"
            :disabled="isSaving"
          >
            <span
              class="pr-2"
              v-if="isSaving"
            >
              <v-icon icon="fa:fas fa-rotate fa-spin"></v-icon>
            </span>
            Weiter
          </v-btn>

          <hr class="mt-5" />

          <v-btn
            @click="deleteCurrentAndBackOrLogout('back')"
            :disabled="isSaving"
            class="mt-5"
            size="x-small"
          >
            Abbrechen
          </v-btn>
        </div>
      </v-form>
    </div>
  </v-container>
</template>

<style scoped>
@import "@/styles/tour-cash-register.css";
@import "@/styles/navigation-button.css";
</style>

<script lang="ts">

import { useRoute, useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import TourCashRegisterService from "@/api/TourCashRegisterService";
import { useNumberFormatter } from "@/helpers/formatters";
import TourCashRegisterNavigationButtonBack from "@/components/TourCashRegisterNavigationButtonBack.vue";
import TourCashRegisterNavigationButtonLogout from "@/components/TourCashRegisterNavigationButtonLogout.vue";
import TourCashRegisterReceipt from "@/interfaces/TourCashRegisterReceipt";
import { useStore } from "vuex";
import { VForm } from "vuetify/components";

export default {
  components: { TourCashRegisterNavigationButtonBack, TourCashRegisterNavigationButtonLogout },
  setup() {
    onMounted(async () => {
      await init()
    })

    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const myForm = ref({} as VForm)
    const isLoading = ref(true)
    const isSaving = ref(false)
    const isValid = ref(true)
    const tourCashRegister = ref()
    const tourCashRegisterReceipt = ref({} as TourCashRegisterReceipt)

    let type = route.params.type

    const tourCashRegisterService = ref(new TourCashRegisterService())

    const { formatAmountAndCurrency } = useNumberFormatter()

    const documentNumber = ref()

    const init = async () => {
      let success = true
      let id = parseInt(route.params.id.toString())

      await tourCashRegisterService.value.getTourCashRegister(id).then(data => {
        if (data.success) {
          tourCashRegister.value = data.data
        } else {
          success = false
        }
      })

      await tourCashRegisterService.value.createEmptyTourCashRegisterReceipt(id).then(data => {
        if (data.success) {
          tourCashRegisterReceipt.value = data.data
        } else {
          success = false
          store.commit('setErrorNotification', 'Es ist ein Fehler aufgetreten.')
          router.push({ name: 'tour-cash-register-receipt-index', params: { id: tourCashRegister.value.id } })
        }
      })

      isLoading.value = false
    }

    const saveReceipt = async (action) => {
      isSaving.value = true
      await validate()

      if (isValid.value) {
        tourCashRegisterService.value.saveReceipt('simple', tourCashRegisterReceipt.value).then(data => {
          if (!!data && !!data.data && data.data.success) {

            if (action === 'save-and-new' || action === 'save-and-done') {
              store.commit('setSuccessNotification', 'Der Beleg wurde erfolgreich übermittelt.')
            }

            if (action === 'save-and-new') {
              router.push({ name: 'tour-cash-register-receipt-create', params: { type: 'multiple', id: tourCashRegister.value.id } })
              location.reload()
            }
            if (action === 'save-and-done') {
              router.push({ name: 'tour-cash-register-receipt-index', params: { id: tourCashRegister.value.id } })
            }
            if (action === 'save-and-edit') {
              router.push({ name: 'tour-cash-register-receipt-edit', params: { type: 'single', id: tourCashRegisterReceipt.value.id } })
            }
          } else {
            isSaving.value = false
            let msg = 'Der Beleg konnte nicht übermittelt werden.'
            if (!!data && !!data.data && !!data.data.msg) {
              msg = data.data.msg
            }
            store.commit('setErrorNotification', msg)
          }
        });
      } else {
        isSaving.value = false
        store.commit('setErrorNotification', 'Bitte prüfen Sie die Felder auf Fehler.')
      }
    }

    const validate = async () => {
      await myForm.value.validate()

      if (!tourCashRegisterReceipt.value.documentFile) {
        isValid.value = false
      }
    }

    const deleteCurrentAndBackOrLogout = async (type) => {
      tourCashRegisterService.value.deleteReceipt(tourCashRegisterReceipt.value, 'empty').then(data => {
        if (data.success) {
          if (type === 'back') {
            router.push({ name: 'tour-cash-register-receipt-index', params: { id: tourCashRegister.value.id } })
          }
          if (type === 'logout') {
            window.location.href = import.meta.env.VITE_CONCERTON_URL + '/site/logout';
          }
        }
      })
    }

    const requiredRule = ref([
      v => !!v || 'Darf nicht leer sein',
    ])

    return {
      type,
      myForm,
      isValid,
      isLoading,
      isSaving,
      requiredRule,
      tourCashRegister,
      tourCashRegisterReceipt,
      formatAmountAndCurrency,
      documentNumber,
      saveReceipt,
      deleteCurrentAndBackOrLogout,
    }
  },
}

</script>
