import axios from 'axios'
import BaseApiService from './BaseApiService'
import IncomingInvoice from "../interfaces/IncomingInvoice";

export default class IncomingInvoiceService extends BaseApiService {

  sendAndSave(incomingInvoice: IncomingInvoice) {
    const url = this.getApiUrl('/incoming-invoice/send-and-save');

    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    return axios.post(url,
      { incomingInvoice: incomingInvoice },
      { headers }
    )
      .then(async res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  checkInvoicePdf(incomingInvoice: IncomingInvoice) {
    const url = this.getApiUrl('/incoming-invoice/check-invoice-pdf');

    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    return axios.post(url,
      { incomingInvoice: incomingInvoice },
      { headers }
    )
      .then(async res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  getGroups() {
    return axios.get(this.getApiUrl('/invoice-group/get-groups'))
      .then(res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  findTours(searchString: string) {
    return axios.get(this.getApiUrl('/incomingInvoice/tour/index'), {params: {searchString: searchString}})
      .then(res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  getTour(id: number) {
    return axios.get(this.getApiUrl('/incomingInvoice/tour/view'), {params: {id: id, expand: 'incomingInvoiceContainers'}})
      .then(res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  findCompanies(searchString: string) {
    return axios.get(this.getApiUrl('/incomingInvoice/company/find-companies'), {params: {searchString: searchString, expand: 'address'}})
      .then(res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  findPossibleRecipientCompanies() {
    return axios.get(this.getApiUrl('/incoming-invoice/find-possible-recipient-companies'))
      .then(res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  findCompanyPersonRelations(searchString: string) {
    return axios.get(this.getApiUrl('/incomingInvoice/company-person-relation/find-company-person-relations'), {params: {searchString: searchString}})
      .then(res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  checkDuplicateInvoiceNumberAndCompany(invoiceNumber: string, invoiceCompanyId: number) {
    return axios.get(this.getApiUrl('/incoming-invoice/check-duplicate-by-invoice-number-and-company'), {params: {invoiceNumber: invoiceNumber, comId: invoiceCompanyId}})
      .then(res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  getIncomingInvoiceData(iinId: number) {
    return axios.get(
      this.getApiUrl('/incoming-invoice/get-invoice'),
      {params: {id: iinId, expand: 'invoicePdf,attachments,incomingInvoiceGroups,incomingInvoiceTours,incomingInvoiceTours.incomingInvoiceContainers,copyRecipients,approvingPersons'}}
    )
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processResult(error.message)
      })
  }

  getFileByGuid(guid: string) {
    return axios.get(this.getApiUrl('/incoming-invoice/get-file-by-guid'), {responseType: 'blob', params: {guid: guid}})
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processResult(error.message)
      })
  }
}
