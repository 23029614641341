<template>
  <div class="navigation-button logout" @click="logout()">Logout</div>
</template>

<style scoped>
@import "@/styles/navigation-button.css";
</style>

<script lang="ts">
export default {
  setup() {
    const logout = () => {
      window.location.href = import.meta.env.VITE_CONCERTON_URL + '/site/logout';
    }

    return { logout }
  }
}
</script>
