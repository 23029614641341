<template>
  <div :class="venueSpecsSection.id > 0 ? 'section-item' : ''">
    <div
      v-if="!isLoading"
      :class="sectionClass()"
    >
      <v-form
        ref="myForm"
        v-model="isValid"
        @submit.prevent="saveVenueSpecsSection"
      >
        <div class="d-flex flex-row section-row">
          <div
            v-if="venueSpecsSection.id > 0 && !editMode"
            class="section-column centered"
            style="width: 50px;"
          >
            <v-icon
              class="handle"
              icon="mdi-drag-horizontal-variant"
            ></v-icon>
          </div>


          <div class="flex-grow-1 section-column centered">
            <template v-if="isEdited || venueSpecsSection.id === 0 || venueSpecsSection.id === -1">
              <v-text-field
                v-model="venueSpecsSection.name"
                :disabled="!isEdited"
                :rules="requiredRule"
                :tabindex="(venueSpecsSection.sortorder ?? 0) * 10 + 1"
                label="Abschnittsbezeichnung *"
                @keyup="checkIfNewIsChanged"
              ></v-text-field>
            </template>

            <template v-else>
              {{ venueSpecsSection.name }}

              <div
                v-if="venueSpecsSection.comment"
                class="ml-2"
              >
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <v-icon icon="mdi-information" v-bind="props"></v-icon>
                  </template>
                  <span v-html="nl2br(venueSpecsSection.comment)"></span>
                </v-tooltip>
              </div>
            </template>
          </div>

          <div class="section-column centered" style="width: 350px;">
            <template v-if="isEdited || !venueSpecsSection.id || venueSpecsSection.id === 0 || venueSpecsSection.id === -1">
              <v-radio-group
                v-model="venueSpecsSection.defaultColumnLayout"
                :disabled="!isEdited"
                inline
              >
                <v-radio
                  :label="getColumnLayoutTextByValue(1)"
                  :tabindex="(venueSpecsSection.sortorder ?? 0) * 10 + 2"
                  :value="1"
                ></v-radio>
                <v-radio
                  :label="getColumnLayoutTextByValue(2)"
                  :tabindex="(venueSpecsSection.sortorder ?? 0) * 10 + 3"
                  :value="2"
                ></v-radio>
                <v-radio
                  :label="getColumnLayoutTextByValue(3)"
                  :tabindex="(venueSpecsSection.sortorder ?? 0) * 10 + 4"
                  :value="3"
                ></v-radio>
              </v-radio-group>
            </template>

            <template v-else>
              {{ getColumnLayoutTextByValue(venueSpecsSection.defaultColumnLayout) }}
            </template>
          </div>

          <div class="section-column centered" style="width: 250px">
            <template v-if="venueSpecsSection.id < 1">
              <v-select
                v-model="venueSpecsSection.insertAfter"
                :disabled="!isEdited"
                :items="getItemsForInsertAfter()"
                :menu-props="{maxHeight: 320, eager: true}"
                :tabindex="(venueSpecsSection.sortorder ?? 0) * 10 + 5"
                clearable
                item-title="name"
                item-value="newSortorder"
                label="Einfügen nach"
                return-object
              ></v-select>
            </template>
          </div>

          <div class="section-column" style="width: 140px">
            <template v-if="isEdited">
              <div class="d-flex">
                <SaveButton :disabled="isSaving"
                            :is-saving="isSaving"
                            :tabindex="(venueSpecsSection.sortorder ?? 0) * 10 + 7"
                            @callback="saveVenueSpecsSection"
                ></SaveButton>

                <CancelButton :disabled="isSaving"
                              :is-saving="isSaving"
                              :tabindex="(venueSpecsSection.sortorder ?? 0) * 10 + 8"
                              @callback="reset"
                ></CancelButton>
              </div>
            </template>

            <template v-else-if="!editMode">
              <div class="d-flex">
                <EditButton :disabled="isSaving"
                            :tabindex="(venueSpecsSection.sortorder ?? 0) * 10 + 7"
                            @callback="edit"
                ></EditButton>

                <DeleteButton v-if="!venueSpecsSection.systemKey"
                              :archive="!venueSpecsSection.canBeDeleted"
                              :disabled="isSaving"
                              :is-saving="isSaving"
                              :tabindex="(venueSpecsSection.sortorder ?? 0) * 10 + 8"
                              @callback="deleteVenueSpecsSection"
                ></DeleteButton>
              </div>
            </template>
          </div>
        </div>

        <template v-if="isEdited || !venueSpecsSection.id || venueSpecsSection.id === 0 || venueSpecsSection.id === -1">
          <div class="d-flex flex-row section-row">
            <div class="flex-grow-1 section-column centered">
              <v-text-field
                v-model="venueSpecsSection.comment"
                :disabled="!isEdited"
                :tabindex="(venueSpecsSection.sortorder ?? 0) * 10 + 6"
                label="Erklärung"
                @keyup="checkIfNewIsChanged"
              ></v-text-field>
            </div>
          </div>
        </template>
      </v-form>
    </div>
  </div>
</template>

<script lang="ts" setup>

import {onMounted, ref}  from "vue"
import VenueSpecsSection from "@/interfaces/VenueSpecsSection"
import {useString}       from "@/helpers/string"
import VenueSpecsService from "@/api/VenueSpecsService"
import {VForm}           from "vuetify/components"
import DeleteButton      from "@/components/DeleteButton.vue"
import SaveButton        from "@/components/SaveButton.vue"
import CancelButton      from "@/components/CancelButton.vue"
import EditButton        from "@/components/EditButton.vue"

onMounted(async () => {
  await init()
})

const props = defineProps<{
  venueSpecsSection: VenueSpecsSection,
  editId: number,
  isEdited: boolean,
  editMode: boolean,
  venueSpecsChapterType: string,
  insertAfterItems: []
}>()

const emit = defineEmits(["resetEdit", "setEdit", "refresh"])

const isValid = ref()
const isLoading = ref(true)
const isSaving = ref(false)
const tab = ref()

const venueSpecsService = ref(new VenueSpecsService())
const {nl2br} = useString()

const id = ref()
const objNoReference = ref()
const myForm = ref({} as VForm)

const init = async () => {
  isLoading.value = false
  id.value = props.venueSpecsSection.id

  objNoReference.value = JSON.parse(JSON.stringify(props.venueSpecsSection))
}

const reset = () => {
  props.venueSpecsSection.name = objNoReference.value.name
  props.venueSpecsSection.defaultColumnLayout = objNoReference.value.defaultColumnLayout
  props.venueSpecsSection.comment = objNoReference.value.comment
  props.venueSpecsSection.insertAfter = objNoReference.value.insertAfter

  emit("resetEdit")

  resetValidation()
}

const resetValidation = () => {
  myForm.value.resetValidation()
}

const edit = () => {
  objNoReference.value = JSON.parse(JSON.stringify(props.venueSpecsSection))
  emit("setEdit", {id: id.value})
}

const saveVenueSpecsSection = async () => {
  isSaving.value = true
  await validate()

  if (isValid.value) {
    await venueSpecsService.value.saveVenueSpecsSection(props.venueSpecsSection, props.venueSpecsChapterType).then(data => {
      if (!!data && !!data.data && data.data.success) {
        let allVenueSpecsSections = data.data.venueSpecsSections
        emit("refresh", {allVenueSpecsSections})

        reset()
      }
    })

    isSaving.value = false
  }
}

const deleteVenueSpecsSection = async () => {
  isSaving.value = true

  await venueSpecsService.value.deleteVenueSpecsSection(props.venueSpecsSection.id, props.venueSpecsChapterType).then(data => {
    if (!!data && !!data.data && data.data.success) {
      let allVenueSpecsSections = data.data.venueSpecsSections
      emit("refresh", {allVenueSpecsSections})
    }
  })

  isSaving.value = false
}

const checkIfNewIsChanged = () => {
  if (props.venueSpecsSection.id < 1) {
    if (props.venueSpecsSection.name || props.venueSpecsSection.comment) {
      props.venueSpecsSection.id = -1
    } else {
      props.venueSpecsSection.id = 0
    }

    id.value = props.venueSpecsSection.id
    emit("setEdit", {id: id.value})
  }
}

const getColumnLayoutTextByValue = (val: number) => {
  if (val === 1) {
    return "Fließtext"
  }

  if (val === 2) {
    return "2-spaltig"
  }

  if (val === 3) {
    return "3-spaltig"
  }
}

const sectionClass = () => {
  if (!props.isEdited && props.editMode) {
    return "section-locked"
  }
  if (props.venueSpecsSection.id > 0 && !props.editMode) {
    return "section-block"
  }
  if (props.isEdited && props.editMode) {
    return "section-editing"
  }

  return ""
}

const getItemsForInsertAfter = () => {
  return props.insertAfterItems
}
const validate = async () => {
  await myForm.value.validate()
}

const requiredRule = ref([
  v => !!v || "Darf nicht leer sein"
])
</script>


<style>
div.section-row > .section-column {
  padding: 10px;
}

div.section-row > .section-column.centered {
  display: flex;
  align-items: center;
}

div.section-item {
  border: 1px solid #bbb;
}

div.section-editing {
  border: 1px dashed #444;
}

div.section-locked {
  background-color: #f0f0f0;
  color: #bbb;
}

div.section-locked .v-input {
  color: black;
}
</style>
