import axios from "axios"
import BaseApiService from "./BaseApiService"
import TourCashRegisterReceipt from "@/interfaces/TourCashRegisterReceipt"

export default class TourCashRegisterService extends BaseApiService {
  getTourCashRegisters(tourId: number) {
    return axios.get(this.getApiUrl("/tour-cash-register/get-tour-cash-registers"), {
      params: {
        tourId: tourId
      }
    })
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getTourCashRegistersGrouped() {
    return axios.get(this.getApiUrl("/tour-cash-register/get-tour-cash-registers-grouped"), { params: { expand: "tourCashRegisters" } })
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getTourCashRegister(id: number) {
    return axios.get(this.getApiUrl("/tour-cash-register/get-tour-cash-register"), {
      params: {
        id: id,
        expand: "tourCashRegisterReceipts,tourCashRegisterReceipts.tourCashRegisterReceiptPositions"
      }
    })
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getNextUnfinishedReceiptId(currentTcrrId: number) {
    return axios.get(this.getApiUrl("/tour-cash-register/get-next-unfinished-tour-cash-register-receipt-id"), { params: { currentTcrrId: currentTcrrId } })
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getTourCashRegisterReceipts(tcrId: number) {
    return axios.get(this.getApiUrl("/tour-cash-register/get-tour-cash-register-receipts"), { params: { tcrId: tcrId } })
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getTourCashRegisterReceipt(tcrrId: number, type: string | null) {
    if (!type) {
      type = "default"
    }
    return axios.get(this.getApiUrl("/tour-cash-register/get-tour-cash-register-receipt"), {
      params: {
        tcrrId: tcrrId,
        type: type,
        expand: "tourCashRegister,tourCashRegisterFile,tourCashRegisterReceiptPositions"
      }
    })
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  createEmptyTourCashRegisterReceipt(tcrId: number) {
    return axios.get(this.getApiUrl("/tour-cash-register/create-empty-tour-cash-register-receipt"), { params: { tcrId: tcrId } })
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  saveReceipt(type: String, tourCashRegisterReceipt: TourCashRegisterReceipt) {

    const url = this.getApiUrl("/tour-cash-register/save-receipt")

    const headers = {
      "Content-Type": "multipart/form-data"
    }

    return axios.post(url,
      { type: type, tourCashRegisterReceipt: tourCashRegisterReceipt },
      { headers }
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  deleteReceipt(tourCashRegisterReceipt: TourCashRegisterReceipt, type: String) {
    return axios.get(this.getApiUrl("/tour-cash-register/delete-receipt"), {
      params: {
        tcrrId: tourCashRegisterReceipt.id,
        type: type
      }
    })
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getOracleCodesForTour(tourId: number) {
    return axios.get(this.getApiUrl("/tour-cash-register/get-oracle-codes-for-tour"), { params: { tourId: tourId } })
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getTourCashRegisterReceiptGroups(type: String) {
    return axios.get(this.getApiUrl("/tour-cash-register/get-tour-cash-register-receipt-groups"), { params: { type: type } })
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getAllFees() {
    return axios.get(this.getApiUrl("/tour-cash-register/get-all-fees"))
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getLoggedInPerson() {
    return axios.get(this.getApiUrl("/tour-cash-register/get-logged-in-person"))
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getFileByGuid(guid: string, inline: boolean | null) {
    return axios.get(this.getApiUrl("/tour-cash-register/get-file-by-guid"), {
      responseType: "blob",
      params: { guid: guid, inline: inline }
    })
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processResult(error.message)
      })
  }
}
