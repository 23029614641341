<template>
  <div :class="wikiSubCategoryItem.id > 0 ? 'section-item' : ''">
    <v-form
      ref="myForm"
      v-model="isValid"
      @submit.prevent="saveItem"
    >
      <div class="d-flex flex-row section-row" :class="wikiSubCategoryItem.id > 0 && !wikiSubCategoryItem.isVisible ? 'not-visible' : ''">
        <div
          v-if="wikiSubCategoryItem.id > 0 && !editMode"
          class="section-column centered"
          style="width: 50px;"
        >
          <v-icon
            class="handle"
            icon="mdi-drag-horizontal-variant"
          ></v-icon>
        </div>

        <div class="flex-grow-1 section-column centered">
          <template v-if="isEdited || wikiSubCategoryItem.id < 1">
            <v-text-field
              v-model="wikiSubCategoryItem.name"
              :disabled="!isEdited"
              :rules="requiredRule"
              :tabindex="(wikiSubCategoryItem.sortorder ?? 0) * 10 + 1"
              label="Name *"
              @keyup="checkIfNewIsChanged"
            ></v-text-field>
          </template>
          <template v-else>
              {{ wikiSubCategoryItem.name }}
          </template>
        </div>

        <template v-if="wikiSubCategoryItem.id < 1 && insertAfterItems.length > 0">
          <div class="flex-grow-1 section-column centered">
          <v-select
            v-model="wikiSubCategoryItem.insertAfter"
            :disabled="!isEdited"
            :items="getItemsForInsertAfter()"
            :menu-props="{maxHeight: 320, eager: true}"
            :tabindex="(wikiSubCategoryItem.sortorder ?? 0) * 10 + 5"
            clearable
            item-title="name"
            item-value="newSortorder"
            label="Einfügen nach"
            return-object
          ></v-select>
          </div>
        </template>

        <div class="section-column" style="width: 280px">
          <div class="d-flex">
            <template v-if="isEdited || wikiSubCategoryItem.id < 1">
              <SaveButton :disabled="isSaving || !isEdited"
                          :is-saving="isSaving"
                          :tabindex="(wikiSubCategoryItem.sortorder ?? 0) * 10 + 7"
                          @callback="saveItem"
              ></SaveButton>

              <CancelButton :disabled="isSaving || !isEdited"
                            :is-saving="isSaving"
                            :tabindex="(wikiSubCategoryItem.sortorder ?? 0) * 10 + 8"
                            @callback="reset"
              ></CancelButton>
            </template>

            <template v-else>
              <EditButton :disabled="isSaving || editMode"
                          :tabindex="(wikiSubCategoryItem.sortorder ?? 0) * 10 + 7"
                          @callback="edit"
              ></EditButton>

              <v-btn
                v-if="wikiSubCategoryItem.id > 0"
                :disabled="isSaving || editMode"
                @click="changeVisibility()"
              >
                <v-icon v-if="isSaving" icon="fa:fas fa-rotate fa-spin"></v-icon>
                <v-icon v-else :icon="wikiSubCategoryItem.isVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"></v-icon>

                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  <template v-if="wikiSubCategoryItem.isVisible">
                    Im Lesemodus ausblenden
                  </template>
                  <template v-else>
                    Im Lesemodus anzeigen
                  </template>
                </v-tooltip>

              </v-btn>

              <DeleteButton :archive="!wikiSubCategoryItem.canBeDeleted"
                            :disabled="isSaving || editMode"
                            :is-saving="isSaving"
                            :tabindex="(wikiSubCategoryItem.sortorder ?? 0) * 10 + 8"
                            deleteText="Wirklich löschen?<br /><br />Achtung: Hiermit werden auch alle Inhalte mitgelöscht."
                            @callback="deleteItem"
              ></DeleteButton>
            </template>

            <v-btn
              v-if="wikiSubCategoryItem.id > 0"
              @click="redirectToContent"
              :disabled="isSaving || editMode"
            >
              <v-icon v-if="isSaving" icon="fa:fas fa-rotate fa-spin"></v-icon>
              <v-icon v-else icon="mdi-arrow-right"></v-icon>

              <v-tooltip
                activator="parent"
                location="top"
              >
                Zu Unterkategorien
              </v-tooltip>

            </v-btn>
          </div>
        </div>

      </div>
    </v-form>
  </div>
</template>

<script lang="ts" setup>

import useEventsBus          from "@/composables/eventBus"
import {onMounted, ref}      from "vue"
import {useRoute, useRouter} from "vue-router"
import WikiService           from "@/api/WikiService"
import {VForm}               from "vuetify/components"
import SaveButton            from "@/components/SaveButton.vue"
import CancelButton          from "@/components/CancelButton.vue"
import EditButton            from "@/components/EditButton.vue"
import DeleteButton          from "@/components/DeleteButton.vue"
import WikiSubCategory from "@/interfaces/WikiSubCategory";
import {useStore}            from "vuex"

onMounted(async () => {
  await init()
})

const init = async () => {
  objNoReference.value = JSON.parse(JSON.stringify(props.wikiSubCategoryItem))
  id.value = props.wikiSubCategoryItem.id

  isLoading.value = false
}

const props = defineProps<{
  wikiSubCategoryItem: WikiSubCategory,
  wikiCategoryId: number|null,
  editMode: boolean,
  isEdited: boolean,
  insertAfterItems: []
}>()

const emit = defineEmits(["resetEdit", "setEdit", "refresh", "removeItem"])

const route = useRoute()
const router = useRouter()
const store = useStore()

const wikiService = ref(new WikiService())


const {bus} = useEventsBus()

const id = ref()
const objNoReference = ref()
const isSaving = ref(false)
const isLoading = ref(true)
const isValid = ref()
const myForm = ref({} as VForm)

const reset = () => {
  props.wikiSubCategoryItem.name = objNoReference.value.name
  props.wikiSubCategoryItem.insertAfter = objNoReference.value.insertAfter

  emit("resetEdit")

  resetValidation()
}

const resetValidation = () => {
  myForm.value.resetValidation()
}

const saveItem = async () => {
  await validate()

  if (isValid.value) {

    wikiService.value.saveWikiSubCategory(props.wikiSubCategoryItem, props.wikiCategoryId).then(data => {
      if (!!data && !!data.data && data.data.success) {
        let allWikiSubCategories = data.data.wikiSubCategories
        emit("refresh", {allWikiSubCategories})

        store.commit('setSuccessNotification', 'Der Eintrag wurde erfolgreich gespeichert.')

        reset()
      } else {
        store.commit('setErrorNotification', 'Der Eintrag konnte nicht gespeichert werden.')
      }
    })
  }
}

const changeVisibility = async () => {
  wikiService.value.changeVisibilityForSubCategoryItem(props.wikiSubCategoryItem, !props.wikiSubCategoryItem.isVisible).then(data => {
    if (!!data && !!data.data && data.data.success) {
      let wikiSubCategory = data.data.wikiSubCategory
      props.wikiSubCategoryItem.isVisible = wikiSubCategory.isVisible

      store.commit('setSuccessNotification', 'Die Sichtbarkeit des Eintrags wurde erfolgreich geändert.')
    } else {
      store.commit('setErrorNotification', 'Die Sichtbarkeit des Eintrags konnte nicht geändert werden.')
    }
  })
}

const deleteItem = async () => {
  await wikiService.value.deleteWikiSubCategory(props.wikiSubCategoryItem.id).then(data => {
    if (data.success) {
      emit("removeItem", {id: id.value})

      store.commit('setSuccessNotification', 'Der Eintrag wurde erfolgreich gelöscht.')
    } else {
      store.commit('setErrorNotification', 'Der Eintrag konnte nicht gelöscht werden.')
    }
  })
}

const checkIfNewIsChanged = () => {
  if (props.wikiSubCategoryItem.id < 1) {
    if (props.wikiSubCategoryItem.name) {
      props.wikiSubCategoryItem.id = -1
    } else {
      props.wikiSubCategoryItem.id = 0
    }

    id.value = props.wikiSubCategoryItem.id
    emit("setEdit", {id: id.value})
  }
}

const getItemsForInsertAfter = () => {
  return props.insertAfterItems
}

const edit = () => {
  objNoReference.value = JSON.parse(JSON.stringify(props.wikiSubCategoryItem))
  emit("setEdit", {id: id.value})
}

const redirectToContent = () => {
  router.push({ name: 'wiki-content', params: { id: props.wikiSubCategoryItem.id }})
}

const validate = async () => {
  await myForm.value.validate()
}

const requiredRule = ref([
  v => !!v || "Darf nicht leer sein"
])

</script>



<style>
div.section-row > .section-column {
  padding: 10px;
}

div.section-row > .section-column.centered {
  display: flex;
  align-items: center;
}

div.section-item {
  border: 1px solid #bbb;
}

div.section-editing {
  border: 1px dashed #444;
}

div.section-locked {
  background-color: #f0f0f0;
  color: #bbb;
}

div.section-locked .v-input {
  color: black;
}
.not-visible {
  background-color: #eee;
}
</style>
