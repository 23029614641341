<template>
  <div class="mt-6 px-8">
    <v-row>
      <v-col>
        <h3>Watchdog</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-icon icon="mdi-check"></v-icon>
        System running
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  setup() {
    return {}
  },
}
</script>
