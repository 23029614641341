<template>
  <v-container v-if="!isLoading" class="pt-10">
    <h1>Venue Specs Abschnitt-Verwaltung</h1>


    <v-tabs v-model="tab" bg-color="grey-lighten-2">
      <v-tab :tabindex="20001" value="general" :disabled="tab !== 'general' && editMode">
        General
        <v-icon
          v-if="tab === 'general' && editMode"
          class="ml-2"
          icon="mdi-content-save"
          size="medium"
        ></v-icon>
      </v-tab>

      <v-tab :tabindex="20002" value="production" :disabled="tab !== 'production' && editMode">
        Production
        <v-icon
          v-if="tab === 'production' && editMode"
          class="ml-2"
          icon="mdi-content-save"
          size="medium"
        ></v-icon>
      </v-tab>
    </v-tabs>

    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="general">
          <VenueSpecsAdminBlock venueSpecsChapterType="general" @setEditMode="setEditMode"></VenueSpecsAdminBlock>
        </v-window-item>

        <v-window-item value="production">
          <VenueSpecsAdminBlock venueSpecsChapterType="production" @setEditMode="setEditMode"></VenueSpecsAdminBlock>
        </v-window-item>
      </v-window>
    </v-card-text>

    <v-row>
      <v-col>
        <v-btn :tabindex="20000" @click="backToSystem">Zurück</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts" setup>

import { onMounted, ref }   from "vue"
import VenueSpecsAdminBlock from "@/components/VenueSpecsAdminBlock.vue"


onMounted(async () => {
  await init()
})

const init = async () => {
  isLoading.value = false
}

const isLoading = ref(true)
const editMode = ref(false)

const tab = ref()

const setEditMode = (val) => {
  editMode.value = val.editMode
}

const backToSystem = () => {
  window.location.href = import.meta.env.VITE_CONCERTON_URL
}
</script>
