import BaseApiService from "./BaseApiService";
import RequirementValue from "@/interfaces/RequirementValue";

export default class RequirementService extends BaseApiService {
  public async getAllRequirementContainers(): Promise<any> {
    return await this.makeRequest('get', '/requirement/get-all-requirement-containers', { expand: "tour" });
  }

  public async getAllRequirementContainersForTour(tourId: number): Promise<any> {
    return await this.makeRequest('get', '/requirement/get-all-requirement-containers-for-tour', { tourId: tourId });
  }

  public async getRequirementPositionsForRequirementContainer(containerId: number): Promise<any> {
    return await this.makeRequest('get', '/requirement/get-requirement-tour-positions-for-requirement-container', { containerId: containerId, expand: "requirementValues.concert" });
  }

  public async addNewPositionForContainer(containerId: number, currencyId: number | null): Promise<any> {
    return await this.makeRequest('get', '/requirement/add-new-position-for-container', { containerId:containerId, currencyId: currencyId });
  }

  public async deletePosition(positionId: number): Promise<any> {
    return await this.makeRequest('get', '/requirement/delete-position', { positionId: positionId });
  }

  public async deleteFile(fileId: number): Promise<any> {
    return await this.makeRequest('get', '/requirement/delete-file', { id: fileId });
  }

  public async saveRequirementValue(requirementValue: RequirementValue): Promise<any> {
    return await this.makeRequest('post', '/requirement/save-requirement-value', null, { requirementValue: requirementValue });
  }

  public async uploadFile(requirementValue: any, file: File): Promise<any> {
    return await this.makeRequest('post', '/requirement/upload-file', null, { requirementValue: requirementValue, file: file });
  }

  public async getAllConcertsForTour(tourId: number): Promise<any> {
    return await this.makeRequest('get', '/requirement/get-all-concerts-for-tour', { tourId: tourId });
  }

  public async getFileByGuid(guid: string, inline: boolean | null): Promise<any> {
    return await this.makeRequest('get', '/requirement/get-file-by-guid', { guid: guid, inline: inline });
  }
}
