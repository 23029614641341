<template>
  <v-container class="pt-14">
    <TourCashRegisterNavigationButtonBack
      :router-name="routerName"
      :router-params="routerParams"
    ></TourCashRegisterNavigationButtonBack>
    <TourCashRegisterNavigationButtonLogout></TourCashRegisterNavigationButtonLogout>

    <div class="text-center" v-if="!isLoading">
      <h2>
        Tourkassen Übersicht für <br />
        {{ tourCashRegisters[0].tourName }}
      </h2>
      <div>Bitte wählen Sie die Währung der Tourkasse aus.</div>

      <div class="mt-5">
        <template v-for="tourCashRegister in tourCashRegisters" :key="tourCashRegister.id">
          <router-link
            class="button-box"
            :to="{ name: 'tour-cash-register-receipt-overview', params: { id: tourCashRegister.id } }">
            {{ tourCashRegister.currency.symbol }}
          </router-link>
        </template>
      </div>
    </div>
  </v-container>
</template>

<style scoped>
@import "@/styles/tour-cash-register.css";
</style>

<script lang="ts">

import TourCashRegisterNavigationButtonBack from "@/components/TourCashRegisterNavigationButtonBack.vue"
import TourCashRegisterNavigationButtonLogout from "@/components/TourCashRegisterNavigationButtonLogout.vue"
import { useRoute, useRouter } from "vue-router"
import { onMounted, Ref, ref } from "vue"
import TourCashRegisterService from "@/api/TourCashRegisterService"
import TourCashRegister from "@/interfaces/TourCashRegister"

export default {
  components: { TourCashRegisterNavigationButtonLogout, TourCashRegisterNavigationButtonBack },
  setup() {
    onMounted(async () => {
      await getTourCashRegisters()

      isLoading.value = false
    })

    const route = useRoute()
    const router = useRouter()

    const isLoading = ref(true)

    const tourCashRegisterService = ref(new TourCashRegisterService())
    const tourCashRegisters: Ref<TourCashRegister[]> = ref([])

    let id = route.params.id

    // these are for navigate back button
    let routerName = "tour-cash-register"
    let routerParams = {}

    const getTourCashRegisters = async () => {
      await tourCashRegisterService.value.getTourCashRegisters(parseInt(id.toString())).then(data => {
        if (data.success) {
          tourCashRegisters.value = data.data
          if (tourCashRegisters.value.length === 1) {
            router.push({ name: "tour-cash-register-receipt-overview", params: { id: tourCashRegisters.value[0].id } })
          }
        }
      })
    }

    return { routerName, routerParams, tourCashRegisters, isLoading }
  },
}

</script>
