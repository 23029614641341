<template>
  <div>
    <v-btn
      :disabled="disabled || historyItems?.length === 0"
      :size="size"
      :tabindex="tabindex"
      :variant="variant"
    >
      <v-icon icon="mdi-history"></v-icon>
      <v-tooltip
        activator="parent"
        location="top"
      >
        Änderungs-Historie
      </v-tooltip>
      <v-overlay
        v-model="overlay"
        location-strategy="connected"
        scroll-strategy="block"
        activator="parent"
        :close-on-content-click="false"
      >
        <v-card>
          <v-card-title>Änderungs-Historie</v-card-title>
          <v-card-text>
            <v-table
              density="compact"
            >
              <thead>
              <tr>
                <th>Feld</th>
                <th>Letzte Änderung</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in historyItems">
                <td>{{ item.fieldName }}</td>
                <td>{{ item.changeText }}</td>
              </tr>
              </tbody>
            </v-table>

            <div class="text-right">
              <v-btn
                class="mt-4"
                @click="overlay = false"
              >Schließen
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-overlay>
    </v-btn>
  </div>
</template>

<script lang="ts" setup>
import {PropType, ref} from "vue"
import HistoryItem     from "@/interfaces/HistoryItem"

const overlay = ref(false)

enum variants {
  elevated = "elevated",
  outlined = "outlined"
}

const emits = defineEmits(["callback"])
const props = defineProps({
  size: {
    type: String,
    default: "default"
  },
  disabled: {
    type: Boolean,
    default: false
  },
  tabindex: {
    type: Number,
    default: null
  },
  variant: {
    type: String as PropType<variants>,
    default: variants.elevated
  },
  historyItems: Array as PropType<HistoryItem[]>
})
</script>
