import { BaseKit, Bold, BulletList, createVuetifyProTipTap, Heading, History, Italic, OrderedList, Table, Link, VuetifyTiptap, VuetifyViewer } from "vuetify-pro-tiptap"
import "vuetify-pro-tiptap/style.css"
import "../styles/markdown/tiptap_custom.scss"

export const vuetifyProTipTap = createVuetifyProTipTap({
  lang: "en",
  components: {
    VuetifyTiptap,
    VuetifyViewer
  },
  extensions: [
    BaseKit.configure({
      placeholder: {
        placeholder: "Text eingeben ..."
      }
    }),
    Bold,
    Italic.configure({ divider: true }),
    Heading.configure({
      levels: [1, 2]
    }).configure({ divider: true }),
    BulletList,
    OrderedList.configure({ divider: true }),
    Table.configure({ divider: true }),
    Link,
    History
  ]
})
