import axios from 'axios';
import BaseApiService from "@/api/BaseApiService";

export default class AuthService extends BaseApiService {
    getUserToken(usrId: number, oneTimeToken: string) {
      return axios.get(this.getApiUrl('/auth/check-token'), {params: {usrId: usrId, ott: oneTimeToken}})
        .then(res => {
            return res.data;
        })
        .catch(error => {
            return {success: false};
        });
    }
}
