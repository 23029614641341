<template>
  <v-container class="pt-14">
    <TourCashRegisterNavigationButtonBack
      :router-name="routerName"
      :router-params="routerParams"
    ></TourCashRegisterNavigationButtonBack>
    <TourCashRegisterNavigationButtonLogout></TourCashRegisterNavigationButtonLogout>

    <div class="text-center" v-if="!isLoading">
      <h2>
        Belegübersicht für<br/>
        {{ tourCashRegister.tourName }} ({{ tourCashRegister.currency.symbol }})
      </h2>

      <template v-if="filteredReceipts">
        <v-text-field
          @keyup="filterReceipts()"
          v-model="filter"
          density="compact"
          placeholder="Filtern nach ..."
          class="mt-3"
        ></v-text-field>

        <template v-for="tourCashRegisterReceipt in filteredReceipts" :key="tourCashRegisterReceipt.id">
          <v-card class="mb-3">
            <v-row
              no-gutters
            >
              <v-col
                cols="3"
                class="text-left pa-2"
                @click="(shownBlock !== tourCashRegisterReceipt.id ? shownBlock = tourCashRegisterReceipt.id : shownBlock = null)"
              >
                {{ tourCashRegisterReceipt.documentNumber }}
              </v-col>

              <v-col
                cols="4"
                style="border-left: 1px solid #eee; border-right: 1px solid #eee;"
                class="pa-2"
                @click="(shownBlock !== tourCashRegisterReceipt.id ? shownBlock = tourCashRegisterReceipt.id : shownBlock = null)"
              >
                <div v-if="tourCashRegisterReceipt.type">
                  {{ tourCashRegisterReceipt.date }}
                </div>

                <div
                  v-if="!tourCashRegisterReceipt.type"
                  class="font-italic"
                >
                  Entwurf
                </div>
              </v-col>

              <v-col
                cols="5"
                class="text-right pa-2"
                @click="(shownBlock !== tourCashRegisterReceipt.id ? shownBlock = tourCashRegisterReceipt.id : shownBlock = null)"
              >
                <template v-if="tourCashRegisterReceipt.type === TourCashRegisterReceiptType.EXPENDITURE">
                  {{ formatAmountAndCurrency(tourCashRegisterReceipt.amount * -1, tourCashRegister.currency) }}
                </template>

                <template v-else>
                  {{ formatAmountAndCurrency(tourCashRegisterReceipt.amount, tourCashRegister.currency) }}
                </template>
              </v-col>

              <v-col
                cols="12"
                v-if="shownBlock === tourCashRegisterReceipt.id"
                style="border-top: 1px solid #eee;"
                class="text-left pa-2"
              >
                <template v-if="tourCashRegisterReceipt.oracleCodeMixedId?.id">
                  <div>Zuordnung:</div>

                  <div class="pl-2">
                    <div v-if="tourCashRegisterReceipt.oracleCodeMixedId.type === 'oracleCode'">
                      <div>{{
                          tourCashRegisterReceipt.oracleCodeMixedId.project
                        }}-{{ tourCashRegisterReceipt.oracleCodeMixedId.department }}
                      </div>
                      <div>{{ tourCashRegisterReceipt.oracleCodeMixedId.desc }}</div>
                    </div>

                    <div v-if="tourCashRegisterReceipt.oracleCodeMixedId.type === 'concert'">
                      <div>{{ tourCashRegisterReceipt.oracleCodeMixedId.name }}</div>
                      <div>Projekt: {{ tourCashRegisterReceipt.oracleCodeMixedId.project }}</div>
                      <div>Department: {{ tourCashRegisterReceipt.oracleCodeMixedId.department }}</div>
                    </div>
                  </div>
                </template>

                <template v-if="tourCashRegisterReceipt.type === TourCashRegisterReceiptType.EXPENDITURE">
                  <div class="mt-4">Buchungstext:</div>
                  <div class="pl-2">
                    <template v-if="tourCashRegisterReceipt.text">
                      {{ tourCashRegisterReceipt.text }}
                    </template>

                    <template v-else>
                      <span class="font-italic">nicht gesetzt</span>
                    </template>
                  </div>
                </template>

                <template v-if="tourCashRegisterReceipt.type === TourCashRegisterReceiptType.EXPENDITURE">
                  <div class="mt-4">Budgetline:</div>
                  <div class="pl-2">
                    <template v-if="tourCashRegisterReceipt.budgetline">
                      {{ tourCashRegisterReceipt.budgetline }}
                    </template>

                    <template v-else>
                      <span class="font-italic">nicht gesetzt</span>
                    </template>
                  </div>
                </template>

                <template v-if="tourCashRegisterReceipt.type">
                  <div class="mt-4">Bemerkung:</div>
                  <div class="pl-2">
                    <template v-if="tourCashRegisterReceipt.comment">
                      {{ tourCashRegisterReceipt.comment }}
                    </template>

                    <template v-else>
                      <span class="font-italic">nicht gesetzt</span>
                    </template>
                  </div>
                </template>

                <template v-if="tourCashRegisterReceipt.tourCashRegisterReceiptPositions.length > 0">
                  <div class="mt-4">Beträge:</div>
                  <div class="pl-2">
                    <v-table
                      density="compact"
                      class="position-table"
                    >
                      <tbody>
                      <tr
                        v-for="position in tourCashRegisterReceipt.tourCashRegisterReceiptPositions"
                        :key="position.id"
                      >
                        <td class="text-right text-no-wrap">
                          <template v-if="tourCashRegisterReceipt.type === TourCashRegisterReceiptType.EXPENDITURE">
                            {{ formatAmountAndCurrency(position.amountNetCalculated * -1, tourCashRegister.currency) }}
                          </template>

                          <template v-else>
                            {{ formatAmountAndCurrency(position.amountNetCalculated, tourCashRegister.currency) }}
                          </template>
                        </td>

                        <td style="width: 1%; padding: 0;">+</td>

                        <td class="text-right text-no-wrap">
                          {{ formatPercentage(position.fee?.value ?? 0) }} %
                        </td>

                        <td style="width: 1%; padding: 0;">=</td>

                        <td class="text-right text-no-wrap">
                          <template v-if="tourCashRegisterReceipt.type === TourCashRegisterReceiptType.EXPENDITURE">
                            {{
                              formatAmountAndCurrency(position.amountGrossCalculated * -1, tourCashRegister.currency)
                            }}
                          </template>

                          <template v-else>
                            {{ formatAmountAndCurrency(position.amountGrossCalculated, tourCashRegister.currency) }}
                          </template>
                        </td>
                      </tr>
                      </tbody>
                    </v-table>
                  </div>
                </template>

                <template v-if="tourCashRegisterReceipt.type === TourCashRegisterReceiptType.EXPENDITURE">
                  <div class="mt-4">Gruppe:</div>
                  <div class="pl-2">
                    <template v-if="tourCashRegisterReceipt.tourCashRegisterReceiptGroup">
                      {{ tourCashRegisterReceipt.tourCashRegisterReceiptGroup.name }}
                    </template>

                    <template v-else>
                      <span class="font-italic">nicht gesetzt</span>
                    </template>
                  </div>
                </template>

                <div>
                  <div class="mt-4">Sonstiges:</div>
                  <div class="pl-2">
                    Angelegt von {{ tourCashRegisterReceipt.creatorName }}<br/>
                    am {{ tourCashRegisterReceipt.creatorDatetime }}
                  </div>
                </div>

                <v-btn
                  size="x-small"
                  class="mt-5"
                  style="width: 100%"
                  @click="downloadFile(tourCashRegisterReceipt.fileGuid, tourCashRegisterReceipt.documentNumber)"
                >
                  Beleg herunterladen
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </template>

      <template v-else>
        Keine Belege vorhanden
      </template>
    </div>
  </v-container>
</template>

<style scoped>
@import "@/styles/tour-cash-register.css";

.position-table tr td {
  padding-left: 4px !important;
  padding-right: 4px !important;
  height: 24px !important;
}
</style>

<script lang="ts">

import TourCashRegisterNavigationButtonBack from "@/components/TourCashRegisterNavigationButtonBack.vue"
import TourCashRegisterNavigationButtonLogout from "@/components/TourCashRegisterNavigationButtonLogout.vue"
import { useRoute } from "vue-router"
import { onMounted, Ref, ref } from "vue"
import TourCashRegisterService from "@/api/TourCashRegisterService"
import TourCashRegister from "@/interfaces/TourCashRegister"
import TourCashRegisterReceipt from "@/interfaces/TourCashRegisterReceipt"
import { useNumberFormatter } from "@/helpers/formatters"
import { TourCashRegisterReceiptType } from "@/modules/TourCashRegisterReceiptType"

export default {
  computed: {
    TourCashRegisterReceiptType() {
      return TourCashRegisterReceiptType
    }
  },
  components: { TourCashRegisterNavigationButtonLogout, TourCashRegisterNavigationButtonBack },
  setup() {
    onMounted(async () => {
      await getTourCashRegister()
      await getTourCashRegisters()

      if (tourCashRegisters.value.length > 1) {
        routerName.value = "tour-cash-register-overview"
        routerParams.value = { id: tourCashRegister.value?.tourId }
      }

      await filterReceipts()

      isLoading.value = false
    })

    const route = useRoute()

    const tourCashRegisterService = ref(new TourCashRegisterService())
    const tourCashRegisters = ref()
    const tourCashRegister: Ref<TourCashRegister> = ref(null as TourCashRegister)

    const { formatAmountAndCurrency, formatPercentage } = useNumberFormatter()

    const filter = ref()

    const isLoading = ref(true)

    const shownBlock = ref()

    let id = route.params.id

    // these are for navigate back button
    const routerName = ref("tour-cash-register")
    const routerParams = ref({})

    const getTourCashRegister = async () => {
      await tourCashRegisterService.value.getTourCashRegister(parseInt(id.toString())).then(data => {
        if (data.success) {
          tourCashRegister.value = data.data
        }
      })
    }

    const getTourCashRegisters = async () => {
      await tourCashRegisterService.value.getTourCashRegisters(tourCashRegister.value.tourId).then(data => {
        if (data.success) {
          tourCashRegisters.value = data.data
        }
      })
    }

    const filteredReceipts: Ref<TourCashRegisterReceipt[]> = ref([])
    const filterReceipts = async () => {
      filteredReceipts.value = tourCashRegister.value?.tourCashRegisterReceipts ?? []

      if (tourCashRegister.value?.tourCashRegisterReceipts && filter.value) {
        filter.value.split(" ").forEach((item) => {
          item = item.toLowerCase()

          filteredReceipts.value = filteredReceipts.value?.filter((tourCashRegisterReceipt) => {
            if (isValidDate(item)) {
              if (tourCashRegisterReceipt.date && tourCashRegisterReceipt.date.includes(item)) {
                return true
              }
            }

            if (tourCashRegisterReceipt.oracleCodeMixedId.name && tourCashRegisterReceipt.oracleCodeMixedId.name.toLowerCase().includes(item)) {
              return true
            }

            if (tourCashRegisterReceipt.oracleCodeMixedId.project && tourCashRegisterReceipt.oracleCodeMixedId.project.toLowerCase().includes(item)) {
              return true
            }

            if (tourCashRegisterReceipt.oracleCodeMixedId.department && tourCashRegisterReceipt.oracleCodeMixedId.department.toLowerCase().includes(item)) {
              return true
            }

            if (tourCashRegisterReceipt.oracleCodeMixedId.desc && tourCashRegisterReceipt.oracleCodeMixedId.desc.toLowerCase().includes(item)) {
              return true
            }

            if (tourCashRegisterReceipt.budgetline && tourCashRegisterReceipt.budgetline.toLowerCase().includes(item)) {
              return true
            }

            if (tourCashRegisterReceipt.documentNumber && tourCashRegisterReceipt.documentNumber.includes(item)) {
              return true
            }

            if (tourCashRegisterReceipt.comment && tourCashRegisterReceipt.comment.toLowerCase().includes(item)) {
              return true
            }

            if (tourCashRegisterReceipt.text && tourCashRegisterReceipt.text.toLowerCase().includes(item)) {
              return true
            }

            if (tourCashRegisterReceipt.tourCashRegisterReceiptGroup?.name && tourCashRegisterReceipt.tourCashRegisterReceiptGroup.name.toLowerCase().includes(item)) {
              return true
            }

            return false
          }) ?? []
        })
      }
    }

    function isValidDate(dateString) {
      let regexFullDate = /^\d{2}\.\d{2}\.\d{4}$/
      let regexDateWithoutYear = /^\d{2}\.\d{2}\.?$/

      if (regexFullDate.test(dateString)) {
        return true
      }

      if (regexDateWithoutYear.test(dateString)) {
        return true
      }

      return false
    }

    const downloadFile = (guid, documentNumber) => {
      tourCashRegisterService.value.getFileByGuid(guid, false).then(data => {
        if (data.success) {
          const url = URL.createObjectURL(data.data)
          const link = document.createElement("a")
          link.href = url
          link.download = documentNumber.toString()
          link.click()
          URL.revokeObjectURL(url)
        }
      })
    }

    return {
      routerName,
      routerParams,
      isLoading,
      tourCashRegister,
      filteredReceipts,
      filterReceipts,
      filter,
      shownBlock,
      formatAmountAndCurrency,
      formatPercentage,
      downloadFile
    }
  },
}

</script>
