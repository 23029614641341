<template>
  <v-btn :disabled="disabled"
         :size="size"
         :tabindex="tabindex"
         @click="callback"
  >
    <v-icon icon="mdi-pencil"></v-icon>

    <v-tooltip
      activator="parent"
      location="top"
    >
      Bearbeiten
    </v-tooltip>
  </v-btn>
</template>

<script lang="ts" setup>

const emits = defineEmits(["callback"])
const props = defineProps({
  size: {
    type: String,
    default: "default"
  },
  disabled: {
    type: Boolean,
    default: false
  },
  callBackId: {
    type: Number,
    default: null
  },
  tabindex: {
    type: Number,
    default: null
  }
})

const callback = () => {
  emits("callback", props.callBackId)
}
</script>
