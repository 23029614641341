<template>
    <v-footer app class="bg-black-ln">
        <v-row justify="center" no-gutters>
            <v-btn
                v-for="link in links"
                :key="link"
                color="white"
                variant="text"
                class="footer-link mx-2"
                :href="link.url"
                target="_blank"
            >
                {{ link.label }}
            </v-btn>
            <v-col class="text-center text-white mt-2" cols="12">
                <div>© Live Nation GmbH {{ year() }} </div>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script lang="ts">
import {ref} from 'vue'

export default {
    setup() {
        const links = ref([
            {label: 'Impressum', url: 'https://www.livenation.de/about'},
            {label: 'Datenschutzerklärung', url: 'https://www.livenation.de/privacy'},
        ])

        const year = () => {
            let date = new Date();
            return date.getFullYear();
        }

        return {links, year}
    },
}
</script>

<style scoped>
    .footer-link {
        padding: 3px;
    }
    .bg-black-ln {
      background-color: #1E262B;
    }
</style>
