<template>
  <v-container v-if="!isLoading" class="pt-10">
    <h1>Wiki (<a href="#" @click="backToSystem">zurück zum System</a>)</h1>

    <div>
      <h2>Neue Kategorie anlegen</h2>
      <WikiCategoryEdit
        :wikiCategoryItem="newWikiCategory"
        :editMode="editId !== 0"
        :isEdited="editId === 0 || editId === -1"
        :insertAfterItems="insertAfterItems()"
        @refresh="refresh"
        @resetEdit="resetEdit"
        @setEdit="setEdit"
      ></WikiCategoryEdit>
    </div>

    <h2>Kategorien-Übersicht</h2>
    <template v-if="allWikiCategories.length > 0">
      <draggable
        :list="allWikiCategories"
        item-key="id"
        v-bind="dragOptions"
        @end="draggingEnd"
        @start="dragging = true"
      >
        <template #item="{ element }">
          <WikiCategoryEdit
            :wikiCategoryItem="element"
            :editMode="editId !== 0"
            :isEdited="editId === element.id"
            :insertAfterItems="insertAfterItems()"
            @refresh="refresh"
            @resetEdit="resetEdit"
            @setEdit="setEdit"
            @removeItem="removeItem"
          ></WikiCategoryEdit>
        </template>
      </draggable>
    </template>

    <template v-else>
      Aktuell sind noch keine Kategorien vorhanden.
    </template>

  </v-container>
</template>

<script lang="ts" setup>

import useEventsBus          from "@/composables/eventBus"
import {onMounted, Ref, ref} from "vue"
import {useRoute, useRouter} from "vue-router"
import WikiService           from "@/api/WikiService"
import WikiCategory          from "@/components/WikiCategoryEdit.vue"
import WikiCategoryEdit      from "@/components/WikiCategoryEdit.vue"
import draggable             from "vuedraggable"


onMounted(async () => {
  await init()
})

const init = async () => {
  await wikiService.value.getAllWikiCategories().then(data => {
    if (data.success) {
      allWikiCategories.value = data.data
    }
  })

  // by default "new category" is set for editing
  editId.value = 0
  newWikiCategory.value.id = 0

  isLoading.value = false
}

const route = useRoute()
const router = useRouter()

const wikiService = ref(new WikiService())


const {bus} = useEventsBus()

const isLoading = ref(true)

const editMode = ref(false)
const editId = ref()

const newWikiCategory = ref({} as WikiCategory)

const allWikiCategories: Ref<WikiCategory[]> = ref([])

const dragging = ref(false)

const dragOptions = ref({
  animation: 200,
  ghostClass: "ghost",
  forceFallback: true,
  scrollSensitivity: 200,
  handle: ".handle"
})

const draggingEnd = () => {
  wikiService.value.resortWikiCategories(allWikiCategories.value).then(data => {
    if (data.success) {
      allWikiCategories.value = data.data.wikiCategories
    }
  })
  dragging.value = false
}

const insertAfterItems = () => {
  let arr = []

  allWikiCategories.value.forEach(item => {
    arr.push({newSortorder: (item.sortorder + 5), name: item.name})
  })

  return arr
}

const resetEdit = () => {
  editId.value = 0
}

const setEdit = (val) => {
  editId.value = val?.id ?? 0
}

const removeItem = (val) => {
  let id = val?.id ?? 0
  allWikiCategories.value = allWikiCategories.value.filter(item => {
    return item.id !== id
  })
}

const refresh = (val) => {
  allWikiCategories.value = val.allWikiCategories
}

const backToSystem = () => {
  window.location.href = import.meta.env.VITE_CONCERTON_URL + ""
}
</script>
