<template>
  <div>
    <div v-if="!isLoading">
      <v-form
        ref="myForm"
        v-model="isValid"
        @submit.prevent="saveItem"
      >
        <template v-if="wikiContentItem.id < 1">
          <div class="d-flex flex-row">
            <div class="flex-grow-1 centered">
              <v-select
                v-model="selectedType"
                :disabled="!isEdited"
                :items="getAllTypes()"
                :menu-props="{maxHeight: 320, eager: true}"
                :tabindex="(wikiContentItem.sortorder ?? 0) * 10 + 1"
                item-title="name"
                item-value="type"
                label="Typ *"
                :rules="requiredRule"
                return-object
              ></v-select>
            </div>
          </div>
        </template>

        <div class="d-flex flex-row">
          <div
            v-if="wikiContentItem.id > 0 && !editMode"
            class="centered pt-2"
            style="width: 50px; min-width: 50px;"
          >
            <v-icon
              class="handle"
              icon="mdi-drag-horizontal-variant"
            ></v-icon>
          </div>

          <div
            v-if="selectedType.type === 'text'"
            class="flex-grow-1 centered"
            :class="isEdited || wikiContentItem.id === 0 || wikiContentItem.id === -1 ? '' : 'border pa-2'"
          >
            <template v-if="isEdited || wikiContentItem.id === 0 || wikiContentItem.id === -1">
              <VuetifyTiptap
                v-model="wikiContentItem.text"
                :disabled="!isEdited"
                :disableToolbar="!isEdited"
                markdown-theme="tiptap_custom"
                :hideBubble="true"
              ></VuetifyTiptap>
              <template v-if="!specialTiptapIsValid">
                <div class="v-input__details">
                  <div class="v-messages">
                    <div class="v-messages__message pl-3" style="transform-origin: center top 0;">
                      Text darf nicht leer sein
                    </div>
                  </div>
                </div>
              </template>
            </template>

            <template v-else>
              <div class="tiptap-wysiwyg">
                <TextClamp
                  :max-lines="3"
                  :text="nl2br(wikiContentItem.text ?? '')"
                  :use-icon="false"
                ></TextClamp>
              </div>

              <WikiContentCreationInfo :wikiContentItem="wikiContentItem"></WikiContentCreationInfo>
            </template>
          </div>

          <div
            v-if="selectedType.type === 'video'"
            class="flex-grow-1 centered"
            :class="isEdited || wikiContentItem.id === 0 || wikiContentItem.id === -1 ? '' : 'border pa-2'"
          >
            <template v-if="isEdited || wikiContentItem.id === 0 || wikiContentItem.id === -1">
              <div class="flex-grow-1 centered">
                <v-select
                  v-model="wikiContentItem.video"
                  :disabled="!isEdited"
                  :items="allVideoUrls"
                  :menu-props="{maxHeight: 320, eager: true}"
                  :tabindex="(wikiContentItem.sortorder ?? 0) * 10 + 1"
                  item-title="name"
                  item-value="filename"
                  label="Video-URL *"
                  :rules="requiredVideoUrlRule"
                  return-object
                ></v-select>
              </div>
            </template>

            <template v-else>
              <div>
                <video controls class="wiki-content-video" v-if="video">
                  <source :src="video" type="video/mp4" />
                </video>
              </div>

              <WikiContentCreationInfo :wikiContentItem="wikiContentItem"></WikiContentCreationInfo>
            </template>
          </div>

          <div
            v-if="selectedType.type === 'image'"
            class="flex-grow-1 centered"
            :class="isEdited || wikiContentItem.id === 0 || wikiContentItem.id === -1 ? '' : 'border pa-2'"
          >
            <template v-if="isEdited || wikiContentItem.id === 0 || wikiContentItem.id === -1">
              <WikiContentFileUploadEdit :wikiContent="wikiContentItem"></WikiContentFileUploadEdit>
            </template>

            <template v-else>
              <div class="image-col">
                <img
                  v-if="preview"
                  :src="preview"
                  alt="image"
                />
              </div>

              <WikiContentCreationInfo :wikiContentItem="wikiContentItem"></WikiContentCreationInfo>
            </template>
          </div>

          <div class="d-flex flex-column pl-3" style="width: 140px">
            <template v-if="isEdited || wikiContentItem.id < 1">
              <div class="d-flex">
                <SaveButton
                  :disabled="isSaving || !isEdited || (selectedType.type === 'image' && (!wikiContentItem.file || wikiContentItem.file.length === 0))"
                  :is-saving="isSaving"
                  :tabindex="(wikiContentItem.sortorder ?? 0) * 10 + 7"
                  @callback="saveItem"
                ></SaveButton>

                <CancelButton
                  :disabled="isSaving || !isEdited || (selectedType.type === 'image' && (!wikiContentItem.file || wikiContentItem.file.length === 0))"
                  :is-saving="isSaving"
                  :tabindex="(wikiContentItem.sortorder ?? 0) * 10 + 8"
                  @callback="reset"
                ></CancelButton>
              </div>
            </template>

            <template v-else>
              <div class="d-flex">
                <EditButton
                  :disabled="isSaving || editMode || wikiContentItem.type === 'image'"
                  :tabindex="(wikiContentItem.sortorder ?? 0) * 10 + 7"
                  @callback="edit"
                ></EditButton>

                <DeleteButton
                  :archive="!wikiContentItem.canBeDeleted"
                  :disabled="isSaving || editMode"
                  :is-saving="isSaving"
                  :tabindex="(wikiContentItem.sortorder ?? 0) * 10 + 8"
                  @callback="deleteItem"
                ></DeleteButton>
              </div>
            </template>
          </div>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script lang="ts" setup>

import useEventsBus              from "@/composables/eventBus"
import { onMounted, ref, watch } from "vue"
import { useRoute, useRouter }   from "vue-router"
import WikiService               from "@/api/WikiService"
import { VForm }                 from "vuetify/components"
import SaveButton                from "@/components/SaveButton.vue"
import CancelButton              from "@/components/CancelButton.vue"
import EditButton                from "@/components/EditButton.vue"
import DeleteButton              from "@/components/DeleteButton.vue"
import WikiContent               from "@/interfaces/WikiContent"
import TextClamp                 from "@/components/TextClamp.vue"
import { useString }             from "@/helpers/string"
import WikiContentFileUploadEdit from "@/components/WikiContentFileUploadEdit.vue"
import { useStore }              from "vuex"
import WikiContentCreationInfo   from "@/components/WikiContentCreationInfo.vue"


onMounted(async () => {
  await init()
})

const init = async () => {
  objNoReference.value = JSON.parse(JSON.stringify(props.wikiContentItem))
  id.value = props.wikiContentItem.id

  selectedType.value = { name: "Text", type: "text" }
  if (props.wikiContentItem.id < 1) {
    selectedType.value = { name: "Text", type: "text" }
  } else if (props.wikiContentItem.video) {
    selectedType.value = { name: "Video", type: "video" }
  } else if (props.wikiContentItem.imageGuid) {
    selectedType.value = { name: "Bild", type: "image" }
  }

  if (props.wikiContentItem.id > 0 && props.wikiContentItem.imageGuid && props.wikiContentItem.type === "image") {
    await wikiService.value.getFileByGuid(props.wikiContentItem.imageGuid).then(data => {
      if (data.success) {
        preview.value = URL.createObjectURL(new File([data.data], "image", { type: "image/jpg" }))
      }
    })
  }

  if (props.wikiContentItem.vidId && props.wikiContentItem.type === "video") {
    await wikiService.value.getVideoById(props.wikiContentItem.vidId).then(data => {
      if (data.success) {
        video.value = URL.createObjectURL(new File([data.data], "video", { type: "video/mp4" }))
      }
    })
  }

  isLoading.value = false
}

const props = defineProps<{
  wikiContentItem: WikiContent,
  wikiSubCategoryId: number | null,
  allVideoUrls: [],
  editMode: boolean,
  isEdited: boolean
}>()

const content = ref()

const emit = defineEmits(["resetEdit", "setEdit", "refresh", "removeItem"])

const route = useRoute()
const router = useRouter()
const store = useStore()

const wikiService = ref(new WikiService())
const { nl2br } = useString()

const { bus } = useEventsBus()

const id = ref()
const objNoReference = ref()
const isSaving = ref(false)
const isLoading = ref(true)
const isValid = ref()
const myForm = ref({} as VForm)

const selectedType = ref()

const file = ref()
const preview = ref()
const video = ref()

const specialTiptapIsValid = ref(true)

const reset = async () => {
  if (props.wikiContentItem.id < 1) {
    props.wikiContentItem.text = "<p></p>"
    props.wikiContentItem.video = null
    props.wikiContentItem.imageGuid = null
    props.wikiContentItem.imageMimetype = null
    props.wikiContentItem.file = []
  } else {
    props.wikiContentItem.text = objNoReference.value.text
    props.wikiContentItem.video = objNoReference.value.video
    props.wikiContentItem.imageGuid = objNoReference.value.imageGuid
    props.wikiContentItem.imageMimetype = objNoReference.value.imageMimetype
    props.wikiContentItem.file = objNoReference.value.file
  }

  await emit("resetEdit")

  await resetValidation()
}

const validateText = () => {
  specialTiptapIsValid.value = true

  if (selectedType.value.type === "text") {
    let tmp = stripHTML(props.wikiContentItem.text ?? "")
    specialTiptapIsValid.value = !!tmp
  }
}

const stripHTML = (value) => {
  const div = document.createElement("div")
  div.innerHTML = value
  return div.textContent || div.innerText || null
}

const resetValidation = async () => {
  await myForm.value.resetValidation()
  specialTiptapIsValid.value = true
}

const saveItem = async () => {
  await validate()
  isSaving.value = true

  if (isValid.value && specialTiptapIsValid.value) {
    await wikiService.value.saveWikiContent(props.wikiContentItem, selectedType.value.type, props.wikiSubCategoryId).then(data => {
      if (!!data && !!data.data && data.data.success) {
        let allWikiContents = data.data.wikiContents
        emit("refresh", { allWikiContents })

        store.commit("setSuccessNotification", "Der Eintrag wurde erfolgreich gespeichert.")

        reset()
      } else {
        store.commit("setErrorNotification", "Der Eintrag konnte nicht gespeichert werden.")
      }
    })

    if (props.wikiContentItem.vidId && props.wikiContentItem.type === "video") {
      video.value = null
      await wikiService.value.getVideoById(props.wikiContentItem.vidId).then(data => {
        if (data.success) {
          video.value = URL.createObjectURL(new File([data.data], "video", { type: "video/mp4" }))
        }
      })
    }
  }

  isSaving.value = false
}

const checkIfNewIsChanged = async () => {
  if (props.wikiContentItem.id < 1) {
    await validateText()
    if (selectedType.value.type === "text" && specialTiptapIsValid.value) {
      props.wikiContentItem.id = -1
    } else {
      props.wikiContentItem.id = 0
    }

    id.value = props.wikiContentItem.id
    await emit("setEdit", { id: id.value })
  }
}

const edit = () => {
  objNoReference.value = JSON.parse(JSON.stringify(props.wikiContentItem))
  emit("setEdit", { id: id.value })
}

const deleteItem = async () => {
  await wikiService.value.deleteWikiContent(props.wikiContentItem.id).then(data => {
    if (data.success) {
      emit("removeItem", { id: id.value })

      store.commit("setSuccessNotification", "Der Eintrag wurde erfolgreich gelöscht.")
    } else {
      store.commit("setErrorNotification", "Der Eintrag konnte nicht gelöscht werden.")
    }
  })
}

const getAllTypes = () => {
  return [
    { name: "Text", type: "text" },
    { name: "Video", type: "video" },
    { name: "Bild", type: "image" }
  ]
}

const hrefToVideo = () => {
  return import.meta.env.VITE_CONCERTON_URL + "/video/play?id=" + props.wikiContentItem.video.id
}

const validate = async () => {
  await myForm.value.validate()
  await customValidate()
}

const customValidate = async () => {
  validateText()
}

const requiredRule = ref([
  v => !!v || "Darf nicht leer sein"
])

const requiredVideoUrlRule = ref([
  v => {
    if (!v && selectedType.value.type === "video") {
      return "Darf nicht leer sein"
    }

    return true
  }
])

watch(props.wikiContentItem, () => {
  checkIfNewIsChanged()
  validateText()
})

</script>


<style>
div.section-row > .section-column {
  padding: 10px;
}

div.section-row > .section-column.centered {
  display: flex;
  align-items: center;
}

div.section-item {
  border: 1px solid #bbb;
}

div.section-editing {
  border: 1px dashed #444;
}

div.section-locked {
  background-color: #f0f0f0;
  color: #bbb;
}

div.section-locked .v-input {
  color: black;
}

.image-col {
  border: 1px dashed #aaa;
  width: 160px;
  height: 120px;
}

.image-col img {
  max-width: 100%;
  max-height: 100%;
}
</style>
