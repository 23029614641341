<template>
  <v-container v-if="!isLoading" class="pt-10">
    <h1>Wiki (<a href="#" @click.prevent="backToCategories">zurück zu Kategorien</a> | <a
      href="#"
      @click="backToSystem"
    >zurück zum System</a>)</h1>

    <div>
      <h2>Neue Unter-Kategorie für "{{ wikiCategory.name }}" anlegen</h2>
      <WikiSubCategoryEdit
        :wikiSubCategoryItem="newWikiSubCategory"
        :wikiCategoryId="wikiCategory.id"
        :editMode="editId !== 0"
        :isEdited="editId === 0 || editId === -1"
        :insertAfterItems="insertAfterItems()"
        @refresh="refresh"
        @resetEdit="resetEdit"
        @setEdit="setEdit"
      ></WikiSubCategoryEdit>
    </div>

    <h2>Unter-Kategorien-Übersicht</h2>
    <template v-if="allWikiSubCategories.length > 0">
      <draggable
        :list="allWikiSubCategories"
        item-key="id"
        v-bind="dragOptions"
        @end="draggingEnd"
        @start="dragging = true"
      >
        <template #item="{ element }">
          <WikiSubCategoryEdit
            :wikiSubCategoryItem="element"
            :wikiCategoryId="wikiCategory.id"
            :editMode="editId !== 0"
            :isEdited="editId === element.id"
            :insertAfterItems="insertAfterItems()"
            @refresh="refresh"
            @resetEdit="resetEdit"
            @setEdit="setEdit"
            @removeItem="removeItem"
          ></WikiSubCategoryEdit>
        </template>
      </draggable>
    </template>

    <template v-else>
      Aktuell sind noch keine Unter-Kategorien vorhanden.
    </template>

  </v-container>
</template>

<script lang="ts" setup>

import useEventsBus          from "@/composables/eventBus"
import {onMounted, Ref, ref} from "vue"
import {useRoute, useRouter} from "vue-router"
import WikiService           from "@/api/WikiService"
import draggable             from "vuedraggable"
import WikiSubCategoryEdit   from "@/components/WikiSubCategoryEdit.vue"
import WikiSubCategory       from "@/interfaces/WikiSubCategory"
import WikiCategory          from "@/interfaces/WikiCategory"

onMounted(async () => {
  await init()
})

const init = async () => {
  categoryId.value = parseInt(route.params.id.toString())

  await wikiService.value.getWikiCategoryByCategoryId(categoryId.value).then(data => {
    if (data.success) {
      wikiCategory.value = data.data
    }
  })

  await wikiService.value.getWikiSubCategoriesByCategoryId(categoryId.value).then(data => {
    if (data.success) {
      allWikiSubCategories.value = data.data
    }
  })

  // by default "new category" is set for editing
  editId.value = 0
  newWikiSubCategory.value.id = 0

  isLoading.value = false
}

const route = useRoute()
const router = useRouter()

const wikiService = ref(new WikiService())

const categoryId = ref()
const wikiCategory: Ref<WikiCategory> = ref({})

const {bus} = useEventsBus()

const isLoading = ref(true)

const editMode = ref(false)
const editId = ref()

const newWikiSubCategory = ref({} as WikiSubCategory)

const allWikiSubCategories: Ref<WikiSubCategory[]> = ref([])

const dragging = ref(false)

const dragOptions = ref({
  animation: 200,
  ghostClass: "ghost",
  forceFallback: true,
  scrollSensitivity: 200,
  handle: ".handle"
})

const draggingEnd = () => {
  wikiService.value.resortWikiSubCategories(allWikiSubCategories.value, categoryId.value).then(data => {
    if (data.success) {
      allWikiSubCategories.value = data.data.wikiSubCategories
    }
  })
  dragging.value = false
}

const insertAfterItems = () => {
  let arr = []

  allWikiSubCategories.value.forEach(item => {
    arr.push({newSortorder: (item.sortorder + 5), name: item.name})
  })

  return arr
}

const resetEdit = () => {
  editId.value = 0
}

const setEdit = (val) => {
  editId.value = val?.id ?? 0
}

const refresh = (val) => {
  allWikiSubCategories.value = val.allWikiSubCategories
}

const removeItem = (val) => {
  let id = val?.id ?? 0
  allWikiSubCategories.value = allWikiSubCategories.value.filter(item => {
    return item.id !== id
  })
}

const backToCategories = () => {
  // window.location.href = "/wiki-admin"
  router.push({name: "wiki-admin"})
}

const backToSystem = () => {
  window.location.href = import.meta.env.VITE_CONCERTON_URL + ""
}
</script>
