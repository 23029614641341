import axios from 'axios'
import BaseApiService from './BaseApiService'

export default class CurrencyService extends BaseApiService {
  getCurrencies() {
    return axios.get(this.getApiUrl('/currency/get-currencies'))
      .then(res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }
}
