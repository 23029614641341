export module TourCashRegisterReceiptType {
  export const EXPENDITURE = 'expenditure'
  export const INCREASE = 'increase'
  export const INCOME = 'income'

  export const EXPENDITURE_TEXT = 'Ausgabe'
  export const INCREASE_TEXT = 'Kassenbestand aufstocken'
}

export function getTextByType(type) {
  if (type === TourCashRegisterReceiptType.EXPENDITURE) {
    return 'Ausgabe'
  }

  if (type === TourCashRegisterReceiptType.INCREASE) {
    return 'Kassenbestand aufstocken'
  }
}
