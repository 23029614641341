<template>
  <template v-if="!isLoading">

    <div v-if="wikiContentItems">
      <v-card
        class="align-center elevation-5 pa-5"
      >
        <div v-for="wikiContentItem in wikiContentItems">
          <WikiViewContent :wikiContentItem="wikiContentItem"></WikiViewContent>
        </div>
      </v-card>
    </div>

    <template v-else>
      Nicht vorhanden!
    </template>

  </template>
</template>

<script lang="ts" setup>

import {onMounted, ref} from "vue"
import WikiContent           from "@/interfaces/WikiContent"
import WikiViewContent       from "@/components/WikiViewContent.vue"

const isLoading = ref(true)

onMounted(async () => {
  await init()
})

const props = defineProps<{
  wikiContentItems: WikiContent[],
}>()

const init = async () => {
  isLoading.value = false
}
</script>
