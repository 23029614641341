import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { createStore } from 'vuex'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router/index.js'
import store from './stores/store'
import './style.css'

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import { vuetifyProTipTap } from './plugins/tiptap'

// i18n
const i18n = new createI18n({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: 'de', fallbackLocale: 'en',
})

loadFonts()

const stores = createStore({
  modules: {
    store,
  },
  state: {
    dirty: false,
  },
  getters: {
    isDirty(state) {
      return state.dirty;
    },
  },
  mutations: {
    setDirty(state) {
        state.dirty = true;
    },
    unsetDirty(state) {
      state.dirty = false;
    },
  }
})

window.onbeforeunload = () => stores.getters.isDirty ? true : null

const app = createApp(App)
  .use(vuetify)
  .use(router)
  .use(i18n)
  .use(stores)
  .use(vuetifyProTipTap);

app.component('Datepicker', Datepicker);

router.isReady().then(() => {
  app.mount('#app')
});
