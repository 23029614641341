import axios from "axios"
import BaseApiService from "./BaseApiService"

export default class CreditCardService extends BaseApiService {
  getCreditCards() {
    return axios.get(this.getApiUrl("/credit-card/get-credit-cards"))
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }
}
