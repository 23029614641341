<template>
  <template v-if="!isLoading">
    <div v-cloak class="flex-grow-1 file-input-item" @drop.prevent="addDropFile" @dragover.prevent>
      <v-file-input
        v-model="wikiContent.file"
        :clearable="false"
        :prepend-icon="''"
        :accept="allowedMimeTypes.toString()"
        label="Bild * (.jpg, .jpeg, .png)"
        @change="previewImage"
        @click:clear="clearPreviewImage"
      ></v-file-input>
    </div>
  </template>
</template>

<script lang="ts" setup>

import {onMounted, Ref, ref} from "vue"
import useEventsBus          from "@/composables/eventBus"
import {useRoute, useRouter} from "vue-router"
import {useString}           from "@/helpers/string"
import WikiService           from "@/api/WikiService"
import WikiContent           from "@/interfaces/WikiContent"

onMounted(async () => {
  await init()
})

const showFullsize = ref(false)

const init = async () => {
  isLoading.value = false
}

const {bus} = useEventsBus()
const {emit} = useEventsBus()
const {nl2br} = useString()

const route = useRoute()
const router = useRouter()

const isLoading = ref(true)
const isSaved = ref(true)

const preview = ref()
const objNoReference = ref()

const props = defineProps<{
  wikiContent: WikiContent,
}>()

const allowedMimeTypes = ['image/jpeg', 'image/png']

const previewImage = async () => {
  preview.value = null
  if (props.wikiContent.file && mimeTypeCheck(props.wikiContent.file[0].type ?? '')) {
    isSaved.value = false
    setTimeout(function() {
      preview.value = URL.createObjectURL(props.wikiContent.file[0])
    }, 10)
  }
}

const addDropFile = (e) => {
  if (mimeTypeCheck(e.dataTransfer.files[0].type ?? '')) {
    props.wikiContent.file = Array.from(e.dataTransfer.files).splice(0, 1)
    preview.value = URL.createObjectURL(props.wikiContent.file[0])
    isSaved.value = false
  }
}

const clearPreviewImage = () => {
  resetToOriginal()
  props.wikiContent.file = []
}

const setOriginal = () => {
  // save previous values in case of reset
  objNoReference.value = JSON.parse(JSON.stringify(preview.value))
}

const resetToOriginal = () => {
  if (objNoReference.value) {
    preview.value = JSON.parse(JSON.stringify(objNoReference.value))
  } else {
    preview.value = null
  }
  isSaved.value = true
}

function mimeTypeCheck(mimeType: string) {
  return allowedMimeTypes.includes(mimeType)
}
</script>

<style>
.section-header > div {
  padding: 10px;
  font-weight: bold;
}

.file-input-item .mdi-content-save {
  color: green;
}

.file-input-item .mdi-content-save-alert {
  color: red;
}

.prev {
  max-width: 100%;
}
</style>
