<template>
    <v-btn
      :disabled="disabled"
      :size="size"
      :tabindex="tabindex"
      @click="callback"
    >
      <v-icon v-if="isSaving" icon="fa:fas fa-rotate fa-spin"></v-icon>
      <v-icon v-else icon="mdi-close-circle"></v-icon>

      <v-tooltip
        activator="parent"
        location="top"
      >
        Abbrechen
      </v-tooltip>
    </v-btn>
</template>

<script lang="ts" setup>

const emits = defineEmits(["callback"])
const props = defineProps({
  size: {
    type: String,
    default: "default"
  },
  disabled: {
    type: Boolean,
    default: false
  },
  isSaving: {
    type: Boolean,
    default: false
  },
  callBackId: {
    type: Number,
    default: null
  },
  tabindex: {
    type: Number,
    default: null
  },
})

const callback = () => {
  emits("callback", props.callBackId)
}
</script>
