<template id="my-scope">
  <v-row dense>
    <v-col cols="6">
      Rechnungsgruppen

      <template v-if="!amountsMatch()">
        <span class="v-messages" style="padding-left: 30px">
          <span class="v-messages__message" style="transform-origin: center top 0; color: rgb(var(--v-theme-error))">Summen stimmen nicht überein</span>
        </span>

        <br />
      </template>
    </v-col>
  </v-row>

  <template v-for="(item, index) in incomingInvoice.incomingInvoiceGroups" v-if="incomingInvoice">
    <v-row dense justify="space-between" v-if="invoiceGroups(item.invoiceGroup ? item.invoiceGroup.id : null).length > 0">
      <v-col cols="6">
        <v-autocomplete
          v-model="item.invoiceGroup"
          item-title="name"
          item-value="id"
          label="Rechnungsgruppe"
          :items="invoiceGroups(item.invoiceGroup ? item.invoiceGroup.id : null)"
          :disabled="!groupCanBeChangedOrDeleted(item.invoiceGroup ? item.invoiceGroup.id : null)"
          :menu-props="{maxHeight: 320, eager: true}"
          return-object
        ></v-autocomplete>
      </v-col>

      <v-col cols="3">
        <CurrencyInput
          v-if="item.invoiceGroup && item.invoiceGroup.id"
          v-model="item.amount"
          :options="{ }"
          :currency="incomingInvoice.currency"
          label="Kosten *"
        />
      </v-col>

      <v-col>
        <v-btn
          v-if="item.invoiceGroup && item.invoiceGroup.id"
          icon="mdi-calculator"
          size="large"
        >
          <v-icon icon="mdi-calculator"></v-icon>
          <v-overlay
            v-model="item.calculatorOverlay"
            location-strategy="connected"
            scroll-strategy="block"
            activator="parent"
            :close-on-content-click="false"
          >
            <v-card class="pa-2">
              <v-text-field
                v-model="item.formula"
                style="width:300px;"
                :errorMessages="calculationError"
              ></v-text-field>
              <v-btn
                color="success"
                @click="closeCalculator(item)"
                class="mt-2"
              >Übernehmen
              </v-btn>
              <v-btn
                @click="item.calculatorOverlay = false"
                class="mt-2 float-right"
              >Abbrechen
              </v-btn>
            </v-card>
          </v-overlay>
        </v-btn>
      </v-col>

      <v-col>
        <v-btn
          v-if="item.invoiceGroup && item.invoiceGroup.id"
          size="large"
          style="padding-top: 6px;"
          icon="mdi-delete"
          :disabled="!groupCanBeChangedOrDeleted(item.invoiceGroup ? item.invoiceGroup.id : null)"
          @click="removeIncomingInvoiceGroup(index)"
        ></v-btn>
      </v-col>

      <v-col>
        <v-btn
          v-if="checkRestCanBeAssignedToGroup(item.invoiceGroup ? item.invoiceGroup.id : null)"
          size="large"
          style="padding-top: 6px;"
          icon="mdi-arrow-left"
          @click="assignRestToIncomingInvoiceGroup(item.invoiceGroup ? item.invoiceGroup.id : null)"
        >
          <v-icon class="mdi-arrow-left"></v-icon>
          <v-tooltip
            activator="parent"
            location="top"
          >
            Rest der Gesamtsumme als Kosten eintragen
          </v-tooltip>
        </v-btn>
      </v-col>
    </v-row>
  </template>
</template>

<script lang="ts">

import {computed, PropType, reactive, ref, watch} from "vue";
import IncomingInvoiceGroup from "@/interfaces/IncomingInvoiceGroup";
import IncomingInvoice from "@/interfaces/IncomingInvoice";
import InvoiceGroup from "@/interfaces/InvoiceGroup";
import CurrencyInput from "./CurrencyInput.vue"
import IncomingInvoicePosition from "@/interfaces/IncomingInvoicePosition";
import { useIncomingInvoiceFunctions } from "@/helpers/incomingInvoice"

export default {
  props: {
    incomingInvoice: { type: Object as PropType<IncomingInvoice>, required: true },
    availableInvoiceGroups: { type: Object as PropType<InvoiceGroup[]>, required: true },
  },
  components: { CurrencyInput },
  setup(props) {
    if (!props.incomingInvoice.incomingInvoiceGroups) {
      const iig = reactive({} as IncomingInvoiceGroup)
      props.incomingInvoice.incomingInvoiceGroups = [];
      props.incomingInvoice.incomingInvoiceGroups.push(iig)
    }

    const { calculateFormula } = useIncomingInvoiceFunctions()

    const calculationError = ref("")

    const invoiceGroups = (id) => {
      return props.availableInvoiceGroups.filter((item) => {
        return id === item.id || !props.incomingInvoice.incomingInvoiceGroups.map(i => i.invoiceGroup ? i.invoiceGroup.id : null).includes(item.id)
      })
    }

    const removeIncomingInvoiceGroup = (index) => {
      props.incomingInvoice.incomingInvoiceGroups.splice(index, 1)
    }

    const lastIncomingInvoiceGroupWithoutCostId = computed(() => {
      let emptyInvoiceGroups = props.incomingInvoice.incomingInvoiceGroups.filter((group) => {
        return !group.amount && !!group.invoiceGroup
      })
      if (emptyInvoiceGroups.length === 1) {
        let group = emptyInvoiceGroups.find((x) => typeof x !== 'undefined')
        return group.invoiceGroup.id
      }
      return null
    })

    const checkRestCanBeAssignedToGroup = (groupId) => {
      if (!props.incomingInvoice.totalAmount || (props.incomingInvoice.totalAmount - getIncomingInvoiceGroupsSum() === 0)) {
        return false
      }
      return (groupId !== null) && lastIncomingInvoiceGroupWithoutCostId.value === groupId
    }

    const assignRestToIncomingInvoiceGroup = (groupId) => {
      let group = props.incomingInvoice.incomingInvoiceGroups.filter((group) => {
        return !!group.invoiceGroup ? (group.invoiceGroup.id === groupId) : false
      }).find((x) => typeof x !== 'undefined')
      group.amount = props.incomingInvoice.totalAmount - getIncomingInvoiceGroupsSum()
    }

    const amountsMatch = () => {
      let sum = getIncomingInvoiceGroupsSum();

      if (!props.incomingInvoice.totalAmount && sum === 0) {
        return true
      }

      return props.incomingInvoice.totalAmount === sum;
    }

    function getIncomingInvoiceGroupsSum() {
      let sum = props.incomingInvoice.incomingInvoiceGroups.reduce((sum, item) => {
        return sum + (item.amount || 0)
      }, 0);

      return Math.round(sum * 1000000) / 1000000
    }

    watch(() => props.incomingInvoice.incomingInvoiceGroups,
      (currentValue) => {
        let hasEmpty = false;
        currentValue.forEach((item) => {
          if (!item.invoiceGroup) {
            hasEmpty = true;
          }
        })
        if (!hasEmpty) {
          const iig = reactive({} as IncomingInvoiceGroup)
          props.incomingInvoice.incomingInvoiceGroups.push(iig)
        }

        currentValue.forEach((item) => {
          if (item.invoiceGroup) {
            let groupId = item.invoiceGroup.id

            props.incomingInvoice.incomingInvoiceTours.forEach((tour) => {
              tour.incomingInvoiceContainers.forEach((container) => {

                // check if a group needs to be added to positions
                let existingPosItems = container.incomingInvoicePositions.filter((posItem) => {
                  return groupId === posItem.groupId
                })
                if (existingPosItems.length === 0) {
                  let incomingInvoicePosition = reactive({} as IncomingInvoicePosition)
                  incomingInvoicePosition.groupId = groupId
                  container.incomingInvoicePositions.push(incomingInvoicePosition)
                }

                // check if a group needs to be removed from positions
                container.incomingInvoicePositions.forEach((position, index) => {
                  let existingGroupItems = props.incomingInvoice.incomingInvoiceGroups.filter((group) => {
                    let groupId = group.invoiceGroup ? group.invoiceGroup.id : 0
                    return groupId === position.groupId;
                  })

                  if (existingGroupItems.length === 0) {
                    container.incomingInvoicePositions.splice(index, 1);
                  }
                })


                container.incomingInvoiceConcerts.forEach((concert) => {

                  // check if a group needs to be added to positions
                  let existingPosItems = concert.incomingInvoicePositions.filter((posItem) => {
                    return groupId === posItem.groupId
                  })
                  if (existingPosItems.length === 0) {
                    let incomingInvoicePosition = reactive({} as IncomingInvoicePosition)
                    incomingInvoicePosition.groupId = groupId
                    concert.incomingInvoicePositions.push(incomingInvoicePosition)
                  }

                  // check if a group needs to be removed from positions
                  concert.incomingInvoicePositions.forEach((position, index) => {
                    let existingGroupItems = props.incomingInvoice.incomingInvoiceGroups.filter((group) => {
                      let groupId = group.invoiceGroup ? group.invoiceGroup.id : 0
                      return groupId === position.groupId;
                    })

                    if (existingGroupItems.length === 0) {
                      concert.incomingInvoicePositions.splice(index, 1);
                    }
                  })
                })
              })
            })
          }
        })
      },
      { deep: true }
    );

    const groupCanBeChangedOrDeleted = (groupId) => {
      let canBeChanged = true

      props.incomingInvoice.incomingInvoiceTours.forEach((tourItem) => {
        tourItem.incomingInvoiceContainers.forEach((container) => {
          container.incomingInvoiceConcerts.forEach((concertItem) => {
            let existingPosItems = concertItem.incomingInvoicePositions.filter((item) => {
              return item.groupId === groupId && item.amount
            })

            if (existingPosItems.length > 0) {
              canBeChanged = false
            }
          })
        })
      })

      return canBeChanged
    }

    const invoiceGroupAmountRule = ref([
      v => !!v || 'Darf nicht leer sein',
    ])

    const closeCalculator = (invoiceGroupItem) => {
      let formula = invoiceGroupItem.formula || null
      let res = ""
      calculationError.value = ""
      if (formula) {
        let resObj = calculateFormula(formula)
        res = resObj.res
        calculationError.value = resObj.errorMessage
      } else {
        invoiceGroupItem.calculatorOverlay = false
      }

      if (!calculationError || calculationError.value === "" || calculationError.value === null) {
        invoiceGroupItem.amount = res
        invoiceGroupItem.calculatorOverlay = false
      }
    }

    return {
      invoiceGroups,
      removeIncomingInvoiceGroup,
      lastIncomingInvoiceGroupWithoutCostId,
      checkRestCanBeAssignedToGroup,
      assignRestToIncomingInvoiceGroup,
      amountsMatch,
      groupCanBeChangedOrDeleted,
      invoiceGroupAmountRule,
      closeCalculator,
      calculationError
    }
  },
}

</script>
