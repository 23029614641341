<template>
  <template v-if="!isLoading">
    <v-form ref="myForm" v-model="isValid" @submit.prevent="save">
      <div class="d-flex mb-3">
        <div v-if="preview" class="flex-shrink-1 preview-image">
          <div style="position: absolute; z-index: 1; text-align: center; top: 50px; left: 72px;">
            <v-icon icon="fa:fas fa-rotate fa-spin"></v-icon>
          </div>
          <img
            :src="preview"
            alt="image"
            type="image/jpg"
          />
        </div>

        <div :class="preview ? 'ml-2' : ''" class="flex-grow-1">
          <div v-cloak class="flex-grow-1 file-input-item" @drop.prevent="addDropFile" @dragover.prevent>
            <v-file-input
              v-model="file"
              :append-icon="isSaved ? 'mdi-content-save' : 'mdi-content-save-alert'"
              :clearable="false"
              :disabled="editMode && !isEditing"
              :accept="allowedMimeTypes.toString()"
              label="Bild * (.jpg, .jpeg, .png)"
              prepend-icon=""
              @change="previewImage"
              @click:clear="clearPreviewImage"
            ></v-file-input>
          </div>

          <div v-if="preview && venueSpecsChapterType === 'venue_overlay'" class="flex-grow-1">
            <v-text-field
              v-model="venueSpecsFile.headline"
              label="Überschrift"
            ></v-text-field>
          </div>

          <div v-if="preview && venueSpecsChapterType === 'impressions'" class="flex-grow-1">
            <v-text-field
              v-model="venueSpecsFile.subline"
              :rules="requiredRule"
              label="Unterschrift *"
            ></v-text-field>
          </div>

          <div v-if="preview && venueSpecsChapterType === 'impressions'" class="flex-grow-1">
            <v-text-field
              v-model="venueSpecsFile.url"
              :rules="urlRule"
              label="Link (https://...)"
            ></v-text-field>
          </div>

          <div v-if="preview && venueSpecsChapterType === 'impressions'" class="flex-grow-1">
            <v-radio-group
              v-model="venueSpecsFile.flgFullwidth"
              inline
            >
              <v-radio
                :value="false"
                label="Bild hat 50% der Breite"
              ></v-radio>

              <v-radio
                :value="true"
                label="Bild nutzt die volle Breite"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>

        <div class="ml-2">
          <SaveButton :disabled="!file || file.length === 0 || isSaving"
                      :is-saving="isSaving"
                      size="x-large"
                      @callback="save"
          ></SaveButton>

          <CancelButton :disabled="!file || file.length === 0 || isSaving"
                        :is-saving="isSaving"
                        class="ml-2"
                        size="x-large"
                        @callback="clearPreviewImage"
          ></CancelButton>
        </div>
      </div>
    </v-form>
  </template>
  <hr v-if="preview" class="mb-2" />

</template>

<script lang="ts" setup>

import {onMounted, Ref, ref} from "vue"
import VenueSpecsService     from "@/api/VenueSpecsService"
import useEventsBus          from "@/composables/eventBus"
import VenueSpecsFile        from "@/interfaces/VenueSpecsFile"
import {useString}           from "@/helpers/string"
import CancelButton          from "@/components/CancelButton.vue"
import SaveButton            from "@/components/SaveButton.vue"

onMounted(async () => {
  await init()
})

const init = async () => {
  venueSpecsFile.value.flgFullwidth = false
  isLoading.value = false
}

const props = defineProps<{
  companyId: number,
  venueSpecsChapterType: string,
  editMode: boolean
}>()

const {emit} = useEventsBus()
const emits = defineEmits(["addItem"])
const {isValidHttpUrl} = useString()

const venueSpecsService = ref(new VenueSpecsService())

const isLoading = ref(true)
const isSaved = ref(true)
const isSaving = ref(false)
const isValid = ref(true)
const isEditing = ref(false)
const myForm = ref()

const file = ref()
const preview = ref()
const venueSpecsFile: Ref<VenueSpecsFile> = ref({})

const allowedMimeTypes = ['image/jpeg', 'image/png']

const previewImage = () => {
  if (file && mimeTypeCheck(file.value[0].type)) {
    preview.value = null
    isSaved.value = false
    setTimeout(function() {
      preview.value = URL.createObjectURL(file.value[0])
    }, 10)
    emit("setEditMode", true)
    isEditing.value = true
  }
}

const addDropFile = (e) => {
  if (mimeTypeCheck(e.dataTransfer.files[0].type ?? '')) {
    preview.value = null
    file.value = Array.from(e.dataTransfer.files).splice(0, 1)
    setTimeout(function() {
      preview.value = URL.createObjectURL(file.value[0])
    }, 10)
    isSaved.value = false
    emit("setEditMode", true)
    isEditing.value = true
  }
}

const clearPreviewImage = () => {
  resetToOriginal()
  file.value = []
  emit("setEditMode", false)
  isEditing.value = false
}

const save = async () => {
  isSaving.value = true
  await myForm.value.validate()

  if (isValid.value) {
    await venueSpecsService.value.addNewFile(props.companyId, props.venueSpecsChapterType, file.value, venueSpecsFile.value).then(data => {
      if (data.data.success) {
        isSaved.value = true
        resetToOriginal()
        file.value = []
        emits("addItem", data.data.venueSpecsFile)
        emit("setEditMode", false)
        isEditing.value = false
      } else {
        if (data.data.errorMessage) {
          alert(data.data.errorMessage)
        }
      }
    })
  }

  isSaving.value = false
}

const resetToOriginal = () => {
  venueSpecsFile.value.headline = null
  venueSpecsFile.value.subline = null
  venueSpecsFile.value.url = null
  venueSpecsFile.value.flgFullwidth = false
  preview.value = null
  isSaved.value = true
}

const requiredRule = ref([
  v => !!v || "Darf nicht leer sein"
])

const urlRule = ref([
  v => {
    if (!!v && !isValidHttpUrl(v)) {
      return "Ungültiger Link"
    }

    return true
  }
])

function mimeTypeCheck(mimeType: string) {
  return allowedMimeTypes.includes(mimeType)
}
</script>

<style>
.preview-image {
  border: 1px dashed #aaa;
  position: relative;
  width: 160px;
  height: 120px;
}

.preview-image img {
  position: absolute;
  object-fit: contain;
  width: 100%;
  height: 100%;
  z-index: 2
}
</style>
