<template>
  <div v-if="isAuthenticated">
    <router-view/>
  </div>
</template>

<script>
import AuthService from "@/api/AuthService"
import { onMounted, ref } from "vue"
import { useRoute } from "vue-router"

export default {
  components: {},
  setup() {
    onMounted(async () => {
      if (route && route.name === "watchdog-page") {
        isAuthenticated.value = true
      } else {
        await checkAuth()
        if (!isAuthenticated.value) {
          redirectToLogin()
        }
      }
    })

    const route = useRoute()

    const redirectToLogin = () => {
      location.href = import.meta.env.VITE_CONCERTON_URL
    }

    const processingAuthentication = ref(false)
    const isAuthenticated = ref(false)

    const checkAuth = async () => {
      processingAuthentication.value = true

      // try to get one-time-token from uri-params
      const search = window.location.search.substring(1)
      const params = new URLSearchParams(search)
      if (params && params.has("usrId") && params.has("ott")) {
        const usrId = parseInt(params.get("usrId") || "")
        const oneTimeToken = params.get("ott")
        if (oneTimeToken) {
          const authService = new AuthService()
          await authService.getUserToken(usrId, oneTimeToken).then(data => {
            if (data.success && data.token) {
              sessionStorage.setItem("token", data.token)
              isAuthenticated.value = true
            } else {
              sessionStorage.removeItem("token")
              isAuthenticated.value = false
            }
          })
        }

        const newUri = window.location.href.replace(/ott=.*?$/i, "").replace(/usrId=.*?$/i, "")
        window.history.replaceState({}, document.title, newUri)
      } else if (sessionStorage.getItem("token")) {
        isAuthenticated.value = true
        processingAuthentication.value = false
      }
    }

    return { isAuthenticated }
  },
}
</script>

