<template>
    <v-snackbar
        v-model="showNotification"
        :timeout="notification.timeout"
        :color="notification.color"
        location="top"
    >
        {{ notification.message }}
        <template v-slot:actions>
            <v-btn
                variant="text"
                @click="showNotification = false"
            >
                X
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script lang="ts">
import {computed} from 'vue'
import {useStore} from 'vuex'

export default {
    setup() {
        const store = useStore();

        const notification = computed(() => {
            return store.getters.getGlobalNotification
        });

        const showNotification = computed({
            get() {
                return notification.value && !!notification.value.message;
            },
            set(value) {
                store.commit('clearNotification');
            }
        });

        return { notification, showNotification }
    }
}
</script>
