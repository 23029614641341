import axios                    from "axios"
import BaseApiService           from "./BaseApiService"
import VenueSpecsSection        from "@/interfaces/VenueSpecsSection"
import VenueSpecsSectionCompany from "@/interfaces/VenueSpecsSectionCompany"
import VenueSpecsFile           from "@/interfaces/VenueSpecsFile"

export default class VenueSpecsService extends BaseApiService {
  getCompany(companyId: number) {
    const url = this.getApiUrl("/venue-specs/get-company-by-id")

    return axios.get(url, {
      params: {
        comId: companyId
      }
    })
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getVenueSpecsSections(chapter: string) {
    const url = this.getApiUrl("/venue-specs-admin/get-venue-specs-sections-by-chapter")

    return axios.get(url, {
      params: {
        chapter: chapter
      }
    })
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  saveVenueSpecsSection(venueSpecsSection: VenueSpecsSection, chapter: string) {
    const url = this.getApiUrl("/venue-specs-admin/save-venue-specs-section")

    return axios.post(url,
      {
        venueSpecsSection: venueSpecsSection,
        chapter: chapter
      }
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  deleteVenueSpecsSection(id: number, chapter: string) {
    const url = this.getApiUrl("/venue-specs-admin/delete-venue-specs-section")

    return axios.get(url, {
      params: {
        id: id,
        chapter: chapter
      }
    })
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  resortSections(venueSpecsSections: VenueSpecsSection[], chapter: string) {
    const url = this.getApiUrl("/venue-specs-admin/resort-sections")

    return axios.post(url,
      {
        venueSpecsSections: venueSpecsSections,
        chapter: chapter
      }
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getAllVenueSpecsChapters() {
    const url = this.getApiUrl("/venue-specs-chapter/get-all-venue-specs-chapters")

    return axios.get(url)
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getVenueSpecsSectionsForChapter(chapter: string) {
    const url = this.getApiUrl("/venue-specs/get-venue-specs-sections")

    return axios.get(url, {
      params: {
        chapter: chapter
      }
    })
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getVenueSpecsForCompanyAndSection(companyId: number, vssId: number) {
    const url = this.getApiUrl("/venue-specs/get-venue-specs-for-company-and-section")

    return axios.get(url, {
      params: {
        comId: companyId,
        vssId: vssId
      }
    })
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  deleteVenueSpecsSectionCompany(id: number) {
    const url = this.getApiUrl("/venue-specs/delete-venue-specs-section-company")

    return axios.get(url, {
      params: {
        id: id
      }
    })
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  saveVenueSpecsSectionCompany(venueSpecsSectionCompany: VenueSpecsSectionCompany) {
    const url = this.getApiUrl("/venue-specs/save-venue-specs-section-company")

    return axios.post(url, {
      venueSpecsSectionCompany: venueSpecsSectionCompany
    })
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  resortItemsForSection(venueSpecsSectionsCompanyItems: VenueSpecsSectionCompany[], vssId: number, companyId: number) {
    const url = this.getApiUrl("/venue-specs/resort-section")

    return axios.post(url,
      {
        venueSpecsSectionCompanyItems: venueSpecsSectionsCompanyItems,
        vssId: vssId,
        comId: companyId
      }
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getDirectionsFile(companyId: number) {
    const url = this.getApiUrl("/venue-specs/get-directions-file")

    return axios.get(url,
      {
        params: {
          comId: companyId
        }
      }
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getFileByGuid(guid: string) {
    return axios.get(this.getApiUrl("/venue-specs/get-file-by-guid"),
      {
        responseType: "blob",
        params: {
          guid: guid
        }
      })
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processResult(error.message)
      })
  }

  saveDirectionsFile(companyId: number, file) {
    const url = this.getApiUrl("/venue-specs/save-directions-file")

    const headers = {
      "Content-Type": "multipart/form-data"
    }

    return axios.post(url,
      {
        companyId: companyId,
        file: file
      },
      {headers}
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  addNewFile(companyId: number, chapter: string, file, venueSpecsFile: VenueSpecsFile|null = null) {
    const url = this.getApiUrl("/venue-specs/add-new-file")
    venueSpecsFile = venueSpecsFile || null

    const headers = {
      "Content-Type": "multipart/form-data"
    }

    return axios.post(url,
      {
        companyId: companyId,
        type: chapter,
        file: file,
        venueSpecsFile: venueSpecsFile
      },
      {headers}
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  updateFile(venueSpecsFile: VenueSpecsFile) {
    const url = this.getApiUrl("/venue-specs/update-file")

    return axios.post(url, {
      venueSpecsFile: venueSpecsFile
    })
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getUploadedFilesByType(companyId: number, type: string) {
    const url = this.getApiUrl("/venue-specs/get-files-by-type")

    return axios.get(url,
      {
        params: {
          comId: companyId,
          type: type

        }
      }
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  resortUploads(venueSpecsFiles: VenueSpecsFile[], chapter: string, companyId: number) {
    const url = this.getApiUrl("/venue-specs/resort-uploads")

    return axios.post(url,
      {
        venueSpecsFiles: venueSpecsFiles,
        chapter: chapter,
        comId: companyId
      }
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  deleteFileByGuid(guid: string) {
    const url = this.getApiUrl("/venue-specs/delete-file-by-guid")

    return axios.get(url,
      {
        params: {
          guid: guid

        }
      }
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }
}
