<template>
  <v-container v-if="!isLoading" class="pt-10">
    <v-card>
      <v-card-item>
        <v-card-title>
          <div class="d-flex flex-row">
            <div class="flex-grow-1">{{ requirementTourContainer.name }}</div>
            <div class="flex-shrink-1">
              <template v-if="requirementTourContainer.hasPriceAttribute">
              <v-btn
                variant="outlined"
                size="small"
              >
                <v-icon icon="mdi-plus-circle"></v-icon>
                Hinzufügen
                <v-overlay
                  v-model="overlay"
                  location-strategy="connected"
                  scroll-strategy="block"
                  activator="parent"
                  :close-on-content-click="false"
                >
                  <v-card class="pa-2">
                    <v-select
                      v-model="selectedCurrency"
                      :items="allCurrencies"
                      item-title="iso3"
                      item-value="id"
                      density="compact"
                      :clearable="false"
                    ></v-select>

                    <v-btn
                      color="success"
                      @click="addPosition"
                      class="mt-2"
                    >Übernehmen
                    </v-btn>

                    <v-btn
                      class="mt-2 float-right"
                      @click="overlay = false"
                    >Abbrechen
                    </v-btn>
                  </v-card>
                </v-overlay>
              </v-btn>
              </template>

              <template v-else>
                <v-btn
                  variant="outlined"
                  size="small"
                  @click="addPosition"
                >
                  <v-icon
                    icon="mdi-plus-circle"
                    class="pr-2"
                  ></v-icon>
                  Hinzufügen
                </v-btn>
              </template>
            </div>
          </div>
        </v-card-title>
      </v-card-item>

      <v-card-text>
        <RequirementTourPositionsBlock
          :requirementTourPositions="requirementTourPositions"
          :allConcertsForTour="allConcertsForTour"
          @deletePosition="deletePosition"
        ></RequirementTourPositionsBlock>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts" setup>
import {onMounted, Ref, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import RequirementTourContainer from "@/interfaces/RequirementTourContainer";
import RequirementService from "@/api/RequirementService";
import RequirementTourPosition from "@/interfaces/RequirementTourPosition";
import RequirementTourPositionsBlock from "@/components/RequirementTourPositionsBlock.vue";
import Concert from "@/interfaces/Concert";
import Currency from "@/interfaces/Currency"

onMounted(async () => {
  await init()
})

const route = useRoute();
const router = useRouter();

const requirementService = ref(new RequirementService())

const isLoading = ref(true);

const requirementTourPositions: Ref<RequirementTourPosition[]> = ref([])

const selectedCurrency = ref()
const overlay = ref(false)

const props = defineProps<{
  requirementTourContainer: RequirementTourContainer,
  allConcertsForTour: Concert[],
  allCurrencies: Currency[]
}>()

const init = async () => {
  if (props.requirementTourContainer.hasPriceAttribute) {
    selectedCurrency.value = props.allCurrencies[0]?.id
  }

  await requirementService.value.getRequirementPositionsForRequirementContainer(props.requirementTourContainer.id)
    .then(data => {
      if (data.success && data.data) {
        requirementTourPositions.value = data.data
      }
    })

  isLoading.value = false
}

const addPosition = async () => {
  await requirementService.value.addNewPositionForContainer(props.requirementTourContainer.id, selectedCurrency.value)
    .then(data => {
      if (data.success && data.data) {
        requirementTourPositions.value.push(data.data)

        if (props.requirementTourContainer.hasPriceAttribute) {
          selectedCurrency.value = props.allCurrencies[0]?.id
          overlay.value = false
        }
      }
    })
}

const deletePosition = async (id: number) => {
  await requirementService.value.deletePosition(id)
    .then(data => {
      if (data.success) {
        requirementTourPositions.value = requirementTourPositions.value.filter(item => item.id !== id);
      }
    })
}
</script>
