<template>
  <div>
    <v-btn
      :disabled="disabled"
      :size="size"
      :tabindex="tabindex"
      @click="callback"
      :variant="variant"
    >
      <v-icon v-if="isSaving" icon="fa:fas fa-rotate fa-spin"></v-icon>
      <v-icon v-else :icon="archive ? 'mdi-archive' : 'mdi-delete'"></v-icon>

      <v-tooltip
        activator="parent"
        location="top"
      >
        {{ archive ? "Archivieren" : "Löschen" }}
      </v-tooltip>
    </v-btn>

    <Confirm ref="veeConfirmRef" />
  </div>
</template>

<script lang="ts" setup>
import Confirm         from "@/components/Confirm.vue"
import {PropType, ref} from "vue"

enum variants {
  elevated = "elevated",
  outlined = "outlined"
}

const emits = defineEmits(["callback"])
const props = defineProps({
  size: {
    type: String,
    default: "default"
  },
  disabled: {
    type: Boolean,
    default: false
  },
  isSaving: {
    type: Boolean,
    default: false
  },
  callBackId: {
    type: Number,
    default: null
  },
  tabindex: {
    type: Number,
    default: null
  },
  // if set to true, shows the archive icon instead of the delete icon, also changes text
  archive: {
    type: Boolean,
    default: false
  },
  deleteText: {
    type: String,
    default: null
  },
  confirmBoxWidth: {
    type: String,
    default: null
  },
  variant: {
    type: String as PropType<variants>,
    default: "elevated"
  }
})

const veeConfirmRef = ref<InstanceType<typeof Confirm>>()

const callback = () => {
  let title = props.archive ? "Archivieren" : "Löschen"
  let text = props.archive ? "Wirklich archivieren?" : "Wirklich löschen?"

  if (!!props.deleteText) {
    text = props.deleteText
  }

  const confirmParams = ref({})
  confirmParams.value.color = "default"
  if (!!props.confirmBoxWidth) {
    confirmParams.value.width = props.confirmBoxWidth
  }

  veeConfirmRef.value
    .open(title, text, confirmParams.value)
    .then(async confirm => {
      if (confirm) {
        emits("callback", props.callBackId)
      }
    })
}
</script>
