<template>
  <div :class="wikiCategoryItem.id > 0 ? 'section-item' : ''">
    <v-form
      ref="myForm"
      v-model="isValid"
      @submit.prevent="saveItem"
    >
      <div class="d-flex flex-row section-row">
        <div
          v-if="wikiCategoryItem.id > 0 && !editMode"
          class="section-column centered"
          style="width: 50px;"
        >
          <v-icon
            class="handle"
            icon="mdi-drag-horizontal-variant"
          ></v-icon>
        </div>


        <div class="flex-grow-1 section-column centered">
          <template v-if="isEdited || wikiCategoryItem.id < 1">
            <v-text-field
              v-model="wikiCategoryItem.name"
              :disabled="!isEdited"
              :rules="requiredRule"
              :tabindex="(wikiCategoryItem.sortorder ?? 0) * 10 + 1"
              label="Name *"
              @keyup="checkIfNewIsChanged"
            ></v-text-field>
          </template>
          <template v-else>
            <div class="flex-row">
              <div>Name: {{ wikiCategoryItem.name }}</div>
              <div v-if="wikiCategoryItem.link">Link: {{ wikiCategoryItem.link }}</div>
            </div>
          </template>
        </div>

        <div class="flex-grow-1 section-column centered">
          <template v-if="isEdited || wikiCategoryItem.id < 1">
            <v-text-field
              v-model="wikiCategoryItem.link"
              :disabled="!isEdited"
              :tabindex="(wikiCategoryItem.sortorder ?? 0) * 10 + 2"
              label="Link"
              @keyup="checkIfNewIsChanged"
            ></v-text-field>
          </template>
        </div>

        <template v-if="wikiCategoryItem.id < 1 && insertAfterItems.length > 0">
          <div class="flex-grow-1 section-column centered">
            <v-select
              v-model="wikiCategoryItem.insertAfter"
              :disabled="!isEdited"
              :items="getItemsForInsertAfter()"
              :menu-props="{maxHeight: 320, eager: true}"
              :tabindex="(wikiCategoryItem.sortorder ?? 0) * 10 + 5"
              clearable
              item-title="name"
              item-value="newSortorder"
              label="Einfügen nach"
              return-object
            ></v-select>
          </div>
        </template>

        <div class="section-column" style="width: 220px">
          <div class="d-flex">
            <template v-if="isEdited || wikiCategoryItem.id < 1">
              <SaveButton :disabled="isSaving || !isEdited"
                          :is-saving="isSaving"
                          :tabindex="(wikiCategoryItem.sortorder ?? 0) * 10 + 7"
                          @callback="saveItem"
              ></SaveButton>

              <CancelButton :disabled="isSaving || !isEdited"
                            :is-saving="isSaving"
                            :tabindex="(wikiCategoryItem.sortorder ?? 0) * 10 + 8"
                            @callback="reset"
              ></CancelButton>
            </template>

            <template v-else>
              <EditButton :disabled="isSaving || editMode"
                          :tabindex="(wikiCategoryItem.sortorder ?? 0) * 10 + 7"
                          @callback="edit"
              ></EditButton>

              <DeleteButton :archive="!wikiCategoryItem.canBeDeleted"
                            :disabled="isSaving || editMode"
                            :is-saving="isSaving"
                            :tabindex="(wikiCategoryItem.sortorder ?? 0) * 10 + 8"
                            deleteText="Wirklich löschen?<br /><br />Achtung: Hiermit werden auch alle Unter-Kategorien und Inhalte mitgelöscht."
                            confirmBoxWidth="500px"
                            @callback="deleteItem"
              ></DeleteButton>
            </template>

            <v-btn
              v-if="wikiCategoryItem.id > 0"
              @click="redirectToSubCategories"
              :disabled="isSaving || editMode"
            >
              <v-icon v-if="isSaving" icon="fa:fas fa-rotate fa-spin"></v-icon>
              <v-icon v-else icon="mdi-arrow-right"></v-icon>

              <v-tooltip
                activator="parent"
                location="top"
              >
                Zu Unterkategorien
              </v-tooltip>
            </v-btn>
          </div>
        </div>

      </div>
    </v-form>
  </div>
</template>

<script lang="ts" setup>

import useEventsBus          from "@/composables/eventBus"
import {onMounted, ref}      from "vue"
import {useRoute, useRouter} from "vue-router"
import WikiService           from "@/api/WikiService"
import WikiCategory          from "@/interfaces/WikiCategory"
import {VForm}               from "vuetify/components"
import SaveButton            from "@/components/SaveButton.vue"
import CancelButton          from "@/components/CancelButton.vue"
import EditButton            from "@/components/EditButton.vue"
import DeleteButton          from "@/components/DeleteButton.vue"
import {useStore}            from "vuex"


onMounted(async () => {
  await init()
})

const init = async () => {
  objNoReference.value = JSON.parse(JSON.stringify(props.wikiCategoryItem))
  id.value = props.wikiCategoryItem.id

  isLoading.value = false
}

const props = defineProps<{
  wikiCategoryItem: WikiCategory,
  editMode: boolean,
  isEdited: boolean,
  insertAfterItems: []
}>()

const emit = defineEmits(["resetEdit", "setEdit", "refresh", "removeItem"])

const route = useRoute()
const router = useRouter()
const store = useStore()

const wikiService = ref(new WikiService())


const {bus} = useEventsBus()

const id = ref()
const objNoReference = ref()
const isSaving = ref(false)
const isLoading = ref(true)
const isValid = ref()
const myForm = ref({} as VForm)

const reset = () => {
  props.wikiCategoryItem.name = objNoReference.value.name
  props.wikiCategoryItem.link = objNoReference.value.link
  props.wikiCategoryItem.insertAfter = objNoReference.value.insertAfter

  emit("resetEdit")

  resetValidation()
}

const deleteText = () => {
  return "Wirklich löschen? " + "\r\n\r\n" + "Hiermit werden auch alle Unter-Kategorien und Inhalte mitgelöscht."
}

const resetValidation = () => {
  myForm.value.resetValidation()
}

const saveItem = async () => {
  await validate()

  if (isValid.value) {

    wikiService.value.saveWikiCategory(props.wikiCategoryItem).then(data => {
      if (!!data && !!data.data && data.data.success) {
        let allWikiCategories = data.data.wikiCategories
        emit("refresh", {allWikiCategories})

        store.commit('setSuccessNotification', 'Der Eintrag wurde erfolgreich gespeichert.')

        reset()
      } else {
        store.commit('setErrorNotification', 'Der Eintrag konnte nicht gespeichert werden.')
      }
    })
  }
}

const checkIfNewIsChanged = () => {
  if (props.wikiCategoryItem.id < 1) {
    if (props.wikiCategoryItem.name) {
      props.wikiCategoryItem.id = -1
    } else {
      props.wikiCategoryItem.id = 0
    }

    id.value = props.wikiCategoryItem.id
    emit("setEdit", {id: id.value})
  }
}

const getItemsForInsertAfter = () => {
  return props.insertAfterItems
}

const edit = () => {
  objNoReference.value = JSON.parse(JSON.stringify(props.wikiCategoryItem))
  emit("setEdit", {id: id.value})
}

const deleteItem = async () => {
  await wikiService.value.deleteWikiCategory(props.wikiCategoryItem.id).then(data => {
    if (data.success) {
      emit("removeItem", {id: id.value})

      store.commit('setSuccessNotification', 'Der Eintrag wurde erfolgreich gelöscht.')
    } else {
      store.commit('setErrorNotification', 'Der Eintrag konnte nicht gelöscht werden.')
    }
  })
}

const redirectToSubCategories = () => {
  router.push({ name: 'wiki-sub-categories', params: { id: props.wikiCategoryItem.id } })
}

const validate = async () => {
  await myForm.value.validate()
}

const requiredRule = ref([
  v => !!v || "Darf nicht leer sein"
])

</script>

<style>
div.section-row > .section-column {
  padding: 10px;
}

div.section-row > .section-column.centered {
  display: flex;
  align-items: center;
}

div.section-item {
  border: 1px solid #bbb;
}

div.section-editing {
  border: 1px dashed #444;
}

div.section-locked {
  background-color: #f0f0f0;
  color: #bbb;
}

div.section-locked .v-input {
  color: black;
}
</style>
