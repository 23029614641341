<template>
  <template v-if="!isLoading">
    <div class="d-flex flex-row">
      <div class="section-type">
        <span class="font-weight-bold">
          {{ venueSpecsSection.name }}
          <template v-if="venueSpecsSection.comment">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-icon icon="mdi-information" v-bind="props"></v-icon>
              </template>
              <span v-html="nl2br(venueSpecsSection.comment)"></span>
            </v-tooltip>
            </template>
        </span>
      </div>
      <div class="flex-grow-1">


        <draggable
          :list="venueSpecsSectionCompanyItems"
          item-key="id"
          v-bind="dragOptions"
          @end="draggingEnd"
          @start="dragging = true"
        >
          <template #item="{ element, index }">
            <VenueSpecsBlockSectionItem :company-id="companyId"
                                        :edit-mode="editMode"
                                        :idx="index"
                                        :venue-specs-chapter-type="venueSpecsChapterType"
                                        :venue-specs-section="venueSpecsSection"
                                        :venue-specs-section-company-item="element"
                                        @remove-item="removeItem"
                                        @add-item="addItem"
            ></VenueSpecsBlockSectionItem>
          </template>
        </draggable>

        <VenueSpecsBlockSectionItem :company-id="companyId"
                                    :edit-mode="editMode"
                                    :idx="-1"
                                    :venue-specs-chapter-type="venueSpecsChapterType"
                                    :venue-specs-section="venueSpecsSection"
                                    :venue-specs-section-company-item="newVenueSpecsSectionCompanyItem"
                                    @remove-item="removeItem"
                                    @add-item="addItem"
        ></VenueSpecsBlockSectionItem>
      </div>
    </div>
  </template>
</template>

<script lang="ts" setup>

import {onMounted, reactive, Ref, ref} from "vue"
import VenueSpecsService               from "@/api/VenueSpecsService"
import {useRoute, useRouter}           from "vue-router"
import VenueSpecsSection               from "@/interfaces/VenueSpecsSection"
import VenueSpecsSectionCompany        from "@/interfaces/VenueSpecsSectionCompany"
import VenueSpecsBlockSectionItem      from "@/components/VenueSpecsBlockSectionItem.vue"
import draggable                       from "vuedraggable"
import {useString}                     from "@/helpers/string"

onMounted(async () => {
  await init()
})

const init = async () => {
  prepareNewVenueSpecsSectionCompanyItem()

  await venueSpecsService.value.getVenueSpecsForCompanyAndSection(props.companyId, props.venueSpecsSection.id).then(data => {
    venueSpecsSectionCompanyItems.value = data.data.venueSpecsSectionCompanyItems
  })

  isLoading.value = false
}

const dragOptions = ref({
  animation: 200,
  ghostClass: "ghost",
  forceFallback: true,
  scrollSensitivity: 200,
  handle: ".handle"
})

const route = useRoute()
const router = useRouter()
const {nl2br} = useString()

const isLoading = ref(true)

const dragging = ref(false)

const venueSpecsService = ref(new VenueSpecsService())
const newVenueSpecsSectionCompanyItem = new reactive({} as VenueSpecsSectionCompany)
const venueSpecsSectionCompanyItems: Ref<VenueSpecsSectionCompany[]> = ref([])

const props = defineProps<{
  venueSpecsChapterType: string,
  companyId: number,
  venueSpecsSection: VenueSpecsSection,
  editMode: boolean
}>()

const prepareNewVenueSpecsSectionCompanyItem = () => {
  newVenueSpecsSectionCompanyItem.id = -1
  newVenueSpecsSectionCompanyItem.comId = props.companyId
  newVenueSpecsSectionCompanyItem.vssId = props.venueSpecsSection.id
  newVenueSpecsSectionCompanyItem.value1 = null
  newVenueSpecsSectionCompanyItem.value2 = null
  newVenueSpecsSectionCompanyItem.value3 = null
  newVenueSpecsSectionCompanyItem.columnLayout = null
}

const removeItem = (idx: number) => {
  venueSpecsSectionCompanyItems.value.splice(idx, 1)
}

const draggingEnd = () => {
  venueSpecsService.value.resortItemsForSection(venueSpecsSectionCompanyItems.value, props.venueSpecsSection.id, props.companyId).then(data => {
    if (data.data.success) {
      venueSpecsSectionCompanyItems.value = data.data.venueSpecsSectionCompanyItems
    }
  })
  dragging.value = false
}

const addItem = (val) => {
  venueSpecsSectionCompanyItems.value.push(val)
  prepareNewVenueSpecsSectionCompanyItem()
}
</script>

<style>
.section-type {
  min-width: 350px;
  max-width: 350px;
  width: 350px
}
</style>
