<template>
  <v-container v-if="!isLoading" class="pt-10">
    <h1>Wiki (<a href="#" @click.prevent="backToSubCategories">zurück zu Unter-Kategorien</a> | <a
      href="#"
      @click="backToSystem"
    >zurück zum System</a>)</h1>

    <div>
      <h2>Neuen Inhalt für "{{ wikiSubCategory.name }}" anlegen</h2>
      <WikiContentEdit
        :wikiContentItem="newWikiContent"
        :wikiSubCategoryId="wikiSubCategory.id"
        :allVideoUrls="allVideoUrls"
        :editMode="editId !== 0"
        :isEdited="editId === 0 || editId === -1"
        @refresh="refresh"
        @resetEdit="resetEdit"
        @setEdit="setEdit"
      ></WikiContentEdit>
    </div>

    <h2 class="mt-5">Inhalts-Übersicht</h2>
    <template v-if="allWikiContents.length > 0">
      <draggable
        :list="allWikiContents"
        item-key="id"
        v-bind="dragOptions"
        @end="draggingEnd"
        @start="dragging = true"
      >
        <template #item="{ element }">
          <WikiContentEdit
            :wikiContentItem="element"
            :wikiSubCategoryId="wikiSubCategory.id"
            :allVideoUrls="allVideoUrls"
            :editMode="editId !== 0"
            :isEdited="editId === element.id"
            @refresh="refresh"
            @resetEdit="resetEdit"
            @setEdit="setEdit"
            @removeItem="removeItem"
          ></WikiContentEdit>
        </template>
      </draggable>
    </template>

    <template v-else>
      Aktuell sind noch keine Inhalte vorhanden.
    </template>

  </v-container>
</template>

<script lang="ts" setup>

import useEventsBus          from "@/composables/eventBus"
import {onMounted, Ref, ref} from "vue"
import {useRoute, useRouter} from "vue-router"
import WikiService           from "@/api/WikiService"
import draggable             from "vuedraggable"
import WikiSubCategory       from "@/interfaces/WikiSubCategory"
import WikiContent           from "@/interfaces/WikiContent"
import WikiContentEdit       from "@/components/WikiContentEdit.vue"

onMounted(async () => {
  await init()
})

const init = async () => {
  subCategoryId.value = parseInt(route.params.id.toString())

  await wikiService.value.getWikiSubCategoryBySubCategoryId(subCategoryId.value).then(data => {
    if (data.success) {
      wikiSubCategory.value = data.data
    }
  })

  await wikiService.value.getWikiContentsBySubCategoryId(subCategoryId.value).then(data => {
    if (data.success) {
      allWikiContents.value = data.data
    }
  })

  await wikiService.value.getAllVideoUrls().then(data => {
    if (data.success) {
      allVideoUrls.value = data.data
    }
  })

  // by default "new category" is set for editing
  editId.value = 0
  newWikiContent.value.id = 0
  newWikiContent.value.text = "<p></p>"

  isLoading.value = false
}

const route = useRoute()
const router = useRouter()

const wikiService = ref(new WikiService())

const subCategoryId = ref()
const wikiSubCategory: Ref<WikiSubCategory> = ref({})
const allWikiContents: Ref<WikiContent[]> = ref([])
const allVideoUrls = ref([])

const {bus} = useEventsBus()

const isLoading = ref(true)

const editMode = ref(false)
const editId = ref()

const newWikiContent = ref({} as WikiContent)


const dragging = ref(false)

const dragOptions = ref({
  animation: 200,
  ghostClass: "ghost",
  forceFallback: true,
  scrollSensitivity: 200,
  handle: ".handle"
})

const draggingEnd = () => {
  wikiService.value.resortWikiContents(allWikiContents.value, subCategoryId.value).then(data => {
    if (data.success) {
      allWikiContents.value = data.data.wikiContents
    }
  })
  dragging.value = false
}

const resetEdit = () => {
  editId.value = 0
}

const setEdit = (val) => {
  editId.value = val?.id ?? 0
}

const refresh = (val) => {
  allWikiContents.value = val.allWikiContents
}

const removeItem = (val) => {
  let id = val?.id ?? 0
  allWikiContents.value = allWikiContents.value.filter(item => {
    return item.id !== id
  })
}

const backToSubCategories = () => {
  router.push({name: "wiki-sub-categories", params: {id: wikiSubCategory.value.categoryId}})
}

const backToSystem = () => {
  window.location.href = import.meta.env.VITE_CONCERTON_URL + ""
}
</script>
