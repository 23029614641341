<template>
  <div :class="isEditing ? 'mt-2' : ''" class="d-flex flex-row">
    <div
      class="centered pt-2"
      style="width: 50px; min-width: 50px;"
    >
      <v-icon
        v-if="venueSpecsSectionCompanyItem.id > 0"
        :class="venueSpecsSectionCompanyItem.id > 0 && !editMode ? 'handle' : 'handle-disabled'"
        icon="mdi-drag-horizontal-variant"
      ></v-icon>
    </div>

    <template v-if="venueSpecsSectionCompanyItem.id >= 0">
      <template v-if="venueSpecsSectionCompanyItem.columnLayout >= 1">
        <div :class="getClassesForFirstColumn(venueSpecsSectionCompanyItem)">
          <template v-if="isEditing">
            <v-textarea v-model="venueSpecsSectionCompanyItem.value1"
                        maxlength="2000"
                        placeholder="Inhalt 1"
            ></v-textarea>
          </template>

          <template v-else>
            <TextClamp :max-lines="3"
                       :text="nl2br(venueSpecsSectionCompanyItem.value1 ?? '')"
                       :use-icon="false"
            ></TextClamp>
          </template>
        </div>
      </template>

      <template v-if="venueSpecsSectionCompanyItem.columnLayout >= 2">
        <div class="d-flex flex-grow-1">
          <template v-if="venueSpecsSectionCompanyItem.columnLayout >= 2">
            <div :class="isEditing ? 'pl-2 pb-2' : 'border pa-2'" class="flex-grow-1" style="width: 0">
              <template v-if="isEditing">
                <v-textarea v-model="venueSpecsSectionCompanyItem.value2"
                            maxlength="2000"
                            placeholder="Inhalt 2"
                ></v-textarea>
              </template>

              <template v-else>
                <TextClamp :max-lines="3"
                           :text="nl2br(venueSpecsSectionCompanyItem.value2 ?? '')"
                           :use-icon="false"
                ></TextClamp>
              </template>
            </div>
          </template>

          <template v-if="venueSpecsSectionCompanyItem.columnLayout >= 3">
            <div :class="isEditing ? 'pl-2 pb-2' : 'border pa-2'" class="flex-grow-1" style="width: 0">
              <template v-if="isEditing">
                <v-textarea v-model="venueSpecsSectionCompanyItem.value3"
                            maxlength="2000"
                            placeholder="Inhalt 3"
                ></v-textarea>
              </template>

              <template v-else>
                <TextClamp :max-lines="3"
                           :text="nl2br(venueSpecsSectionCompanyItem.value3 ?? '')"
                           :use-icon="false"
                ></TextClamp>
              </template>
            </div>
          </template>
        </div>
      </template>

      <div class="d-flex flex-column pl-3" style="width: 170px">
        <div class="d-flex">
          <template v-if="isEditing">
            <SaveButton :disabled="isSaving"
                        :is-saving="isSaving"
                        @callback="save"
            ></SaveButton>

            <CancelButton :disabled="isSaving"
                          :is-saving="isSaving"
                          @callback="cancel"
            ></CancelButton>
          </template>

          <template v-else>
            <EditButton :disabled="isSaving || editMode && !isEditing"
                        @callback="edit"
            ></EditButton>

            <DeleteButton :call-back-id="idx"
                          :disabled="isSaving || editMode && !isEditing"
                          :is-saving="isSaving"
                          @callback="deleteItem"
            ></DeleteButton>
          </template>

          <div v-if="venueSpecsSectionCompanyItem.lastChangeText" class="pt-2 pl-2">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-icon icon="mdi-history" v-bind="props"></v-icon>
              </template>
              <span v-html="nl2br(venueSpecsSectionCompanyItem.lastChangeText)"></span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="!editMode">
      <div class="flex-grow-1 pa-2">
        <div class="d-flex flex-row">
          <div>Hinzufügen:</div>

          <div :class="venueSpecsSection.defaultColumnLayout === 1 ? 'default-column-layout' : ''"
               class="layout-block d-flex ml-5"
               @click="createNew(1)"
          >
            <div class="flex-fill flex-grow-1 d-flex" style="border-radius: 3px;"></div>
          </div>

          <div :class="venueSpecsSection.defaultColumnLayout === 2 ? 'default-column-layout' : ''"
               class="layout-block d-flex ml-2"
               @click="createNew(2)"
          >
            <div class="d-flex" style="width: 30px; border-right: 1px solid black !important;"></div>
            <div class="d-flex" style="width: 60px; border-left: none !important;"></div>
          </div>

          <div :class="venueSpecsSection.defaultColumnLayout === 3 ? 'default-column-layout' : ''"
               class="layout-block d-flex ml-2"
               @click="createNew(3)"
          >
            <div class="d-flex" style="width: 30px; border-right: none !important;"></div>
            <div class="d-flex"
                 style="width: 30px; border-left: 1px solid black !important; border-right: 1px solid black !important;"
            ></div>
            <div class="d-flex" style="width: 30px; border-left: none !important; "></div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column align-end" style="width: 100px"></div>
    </template>
  </div>
</template>

<script lang="ts" setup>

import {ref}                    from "vue"
import useEventsBus             from "@/composables/eventBus"
import VenueSpecsService        from "@/api/VenueSpecsService"
import {useRoute, useRouter}    from "vue-router"
import VenueSpecsSection        from "@/interfaces/VenueSpecsSection"
import VenueSpecsSectionCompany from "@/interfaces/VenueSpecsSectionCompany"
import {useString}              from "@/helpers/string"
import TextClamp                from "@/components/TextClamp.vue"
import DeleteButton             from "@/components/DeleteButton.vue"
import EditButton               from "@/components/EditButton.vue"
import SaveButton               from "@/components/SaveButton.vue"
import CancelButton             from "@/components/CancelButton.vue"

const route = useRoute()
const router = useRouter()
const {nl2br} = useString()

const isEditing = ref(false)
const isSaving = ref(false)

const objNoReference = ref()

const venueSpecsService = ref(new VenueSpecsService())

const props = defineProps<{
  venueSpecsChapterType: string,
  companyId: number,
  venueSpecsSection: VenueSpecsSection,
  venueSpecsSectionCompanyItem: VenueSpecsSectionCompany,
  editMode: boolean,
  idx: number
}>()

const emits = defineEmits(["removeItem", "addItem"])

const {emit} = useEventsBus()

const createNew = (num: number) => {
  props.venueSpecsSectionCompanyItem.columnLayout = num
  props.venueSpecsSectionCompanyItem.id = 0
  isEditing.value = true

  emit("setEditMode", true)
}

const edit = () => {
  // save previous values in case of reset
  objNoReference.value = JSON.parse(JSON.stringify(props.venueSpecsSectionCompanyItem))

  // start editing
  isEditing.value = true
  emit("setEditMode", true)
}

const cancel = () => {
  // reset values
  if (props.venueSpecsSectionCompanyItem.id === 0) {
    props.venueSpecsSectionCompanyItem.columnLayout = 0
    props.venueSpecsSectionCompanyItem.id = -1
    props.venueSpecsSectionCompanyItem.value1 = null
    props.venueSpecsSectionCompanyItem.value2 = null
    props.venueSpecsSectionCompanyItem.value3 = null
  } else {
    props.venueSpecsSectionCompanyItem.value1 = objNoReference.value.value1
    props.venueSpecsSectionCompanyItem.value2 = objNoReference.value.value2
    props.venueSpecsSectionCompanyItem.value3 = objNoReference.value.value3
  }

  // end editing
  isEditing.value = false
  emit("setEditMode", false)
}

const save = async () => {
  isSaving.value = true
  await venueSpecsService.value.saveVenueSpecsSectionCompany(props.venueSpecsSectionCompanyItem).then(data => {
    if (data.data.success) {
      props.venueSpecsSectionCompanyItem.lastChangeText = data.data.venueSpecsSectionCompany.lastChangeText
      if (props.venueSpecsSectionCompanyItem.id === 0) {
        emits("addItem", data.data.venueSpecsSectionCompany)
      }
      emit("setEditMode", false)
      isEditing.value = false
    }
  })
  isSaving.value = false
}

const deleteItem = (idx) => {
  venueSpecsService.value.deleteVenueSpecsSectionCompany(props.venueSpecsSectionCompanyItem.id).then(data => {
    if (data.data.success) {
      emits("removeItem", idx)
    }
  })
}

const getClassesForFirstColumn = (item => {
  let classes = item.columnLayout === 1 ? "first-column-single flex-grow-1" : "first-column-multi"
  if (isEditing.value) {
    classes += " pb-2"
  } else {
    classes += " border pa-2"
  }
  return classes
})
</script>

<style scoped>
.first-column-single {
}

.first-column-multi {
  width: 250px !important;
}

.handle-disabled {
  color: #cccccc;
}

.layout-block {
  height: 25px;
  width: 90px;
  cursor: pointer;
}

.layout-block > div {
  height: 25px;
  border: 1px solid black;
}

.layout-block.default-column-layout > div {
  border: 3px solid black !important;
}

.layout-block > div:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.layout-block > div:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
</style>
