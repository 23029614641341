<template>
  <v-container v-if="!isLoading" class="pt-14">
    <TourCashRegisterNavigationButtonBack
      router-name="tour-cash-register"
    ></TourCashRegisterNavigationButtonBack>
    <TourCashRegisterNavigationButtonLogout></TourCashRegisterNavigationButtonLogout>

    <div class="text-center">
      <h2>
        {{ tourCashRegister.tourName }}
      </h2>
      <div class="tour-cash-register-headline">
        Akt. Kassenbestand: {{ formatAmountAndCurrency(tourCashRegister.amount, tourCashRegister.currency) }}<br>
      </div>

      <div class="pt-10">
        <router-link
          class="button-box"
          :to="{ name: 'tour-cash-register-receipt-create', params: { id: tourCashRegister.id, type: 'multiple' } }">
          Massen-Beleg-Erfassung
        </router-link>
      </div>

      <div class="pt-5">
        <router-link
          class="button-box"
          :to="{ name: 'tour-cash-register-receipt-create', params: { id: tourCashRegister.id, type: 'single' } }">
          Einzel-Beleg-Erfassung
        </router-link>
      </div>

      <div class="pt-5">
        <router-link
          class="button-box"
          :class="{'disabled': unfinishedReceipts().length === 0}"
          :to="unfinishedReceipts().length > 0 ? { name: 'tour-cash-register-receipt-edit', params: { type: 'multiple', id: unfinishedReceipts()[0].id } } : ''">
          Fehlende Belege anreichern<br />
          ({{ unfinishedReceipts().length }} Beleg(e) offen)
        </router-link>
      </div>

      <div class="pt-5">
        <v-row dense>
          <v-col>
            <v-text-field
              :disabled="unfinishedReceipts().length === 0"
              v-model="documentNumber"
              label="Beleg-Nr."
              v-on:keyup.enter="openDocument"
              hide-details="true"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn
              style="width: 100%"
              variant="outlined"
              class="button-open-document"
              :disabled="!documentNumber"
              @click="openDocument"
            >Beleg öffnen</v-btn>
          </v-col>
        </v-row>

        <div class="document-select-error" v-if="documentSelectError">
          {{ documentSelectError }}
        </div>
      </div>
    </div>
  </v-container>
</template>

<style scoped>
@import "@/styles/tour-cash-register.css";
.button-open-document {
  border: 1px solid #ddd;
  height: 56px;
  text-transform: none;
}
.document-select-error {
  font-size: 0.8rem;
  color: #B00020;
}
</style>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import TourCashRegisterService from "@/api/TourCashRegisterService";
import { useNumberFormatter } from "@/helpers/formatters";
import TourCashRegisterNavigationButtonBack from "@/components/TourCashRegisterNavigationButtonBack.vue";
import TourCashRegisterNavigationButtonLogout from "@/components/TourCashRegisterNavigationButtonLogout.vue";
import TourCashRegister from "@/interfaces/TourCashRegister";

export default {
  components: { TourCashRegisterNavigationButtonBack, TourCashRegisterNavigationButtonLogout },
  setup() {
    onMounted(async () => {
      await init()
    })

    const route = useRoute()
    const router = useRouter()

    const isLoading = ref(true)
    const tourCashRegister = ref({} as TourCashRegister)

    const tourCashRegisterService = ref(new TourCashRegisterService())

    const { formatAmountAndCurrency } = useNumberFormatter()

    const documentNumber = ref()
    const documentSelectError = ref()

    const init = async () => {
      let id = parseInt(route.params.id.toString())

      await tourCashRegisterService.value.getTourCashRegister(id).then(data => {
        if (data.success) {
          tourCashRegister.value = data.data
        }
      })

      isLoading.value = false
    }

    const unfinishedReceipts = () => {
      return tourCashRegister.value.tourCashRegisterReceipts.filter((item) => {
        return !item.type
      })
    }

    const finishedReceipts = () => {
      return tourCashRegister.value.tourCashRegisterReceipts.filter((item) => {
        return !!item.type
      })
    }

    const openDocument = () => {
      documentSelectError.value = ''
      let selectedTourCashRegisterReceipt = tourCashRegister.value.tourCashRegisterReceipts.filter((item) => {
        return item.documentNumber === documentNumber.value && !item.type
      }).find((x) => typeof x !== 'undefined')

      if (selectedTourCashRegisterReceipt) {
        router.push({ name: 'tour-cash-register-receipt-edit', params: { type: 'single', id: selectedTourCashRegisterReceipt.id } })
      } else {
        documentSelectError.value = 'Dieser Beleg existiert nicht in dieser Tour oder wurde bereits bearbeitet.'
      }
    }


    return {
      isLoading,
      tourCashRegister,
      formatAmountAndCurrency,
      documentNumber,
      documentSelectError,
      openDocument,
      finishedReceipts,
      unfinishedReceipts
    }
  },
}

</script>
