<template>

  <template v-if="!isLoading">
    <v-card class="mb-4">
      <v-card-title>
        {{ requirementTourContainer.name }}
        <template v-if="requirementTourContainer.canBeEdited">
          (<a :href="'/requirements/' + requirementTourContainer.tour.id">{{ requirementTourContainer.tour.name }}</a>)
        </template>
        <template v-else>
          ({{ requirementTourContainer.tour.name }})
        </template>
      </v-card-title>

      <v-card-item>
        <v-table density="compact">
          <thead>
          <tr>
            <template v-for="requirementValue in requirementTourPositions.slice(0, 1)[0].requirementValues">
              <template v-if="requirementValue.requirementAttribute.requirementAttributeType.systemKey === 'concert'">
                <th>Konzert</th>
                <th>Ort</th>
                <th>Venue</th>
                <th>Promoter</th>
              </template>
              <template v-else>
                <th>{{ requirementValue.requirementAttribute.name }}</th>
              </template>
            </template>
          </tr>
          </thead>

          <tbody>
          <template v-for="requirementTourPosition in requirementTourPositions">
            <tr>
              <template v-for="requirementValue in requirementTourPosition.requirementValues">
                <template v-if="requirementValue.requirementAttribute.requirementAttributeType.systemKey === 'concert'">
                  <td style="min-width: 150px;">{{ requirementValue.concert.date + (requirementValue.concert.begin ? " - " + requirementValue.concert.begin : "") }}</td>
                  <td style="min-width: 150px;">{{ requirementValue.concert.city ?? "-" }}</td>
                  <td style="min-width: 200px;">{{ requirementValue.concert.venue ?? "-" }}</td>
                  <td style="min-width: 200px;">{{ requirementValue.concert.promoter ?? "-" }}</td>
                </template>

                <template v-else>
                  <td :style="style(requirementValue)" style="vertical-align: top;" class="pt-1">

                    <template v-if="requirementValue.requirementAttribute.requirementAttributeType.datatype === 'bool'">
                      {{ requirementValue.valueBool ? "ja" : "nein" }}
                    </template>

                    <template v-else-if="requirementValue.requirementAttribute.requirementAttributeType.datatype === 'file'">
                      <template v-if="requirementValueFile" v-for="requirementValueFile in requirementValue.requirementValueFiles">
                        <RequirementValueFileItem
                            :requirementValueFile="requirementValueFile"
                        ></RequirementValueFileItem>
                      </template>

                      <template v-else>
                        -
                      </template>
                    </template>

                    <template v-else-if="requirementValue.requirementAttribute.requirementAttributeType.datatype === 'float'">
                      {{ requirementValue.valueFloat ?? "-" }}
                    </template>

                    <template v-else-if="requirementValue.requirementAttribute.requirementAttributeType.datatype === 'int'">
                      {{ requirementValue.valueInt ?? "-" }}
                    </template>

                    <template v-else-if="requirementValue.requirementAttribute.requirementAttributeType.datatype === 'item'">
                      {{ requirementValue.requirementAttributeTypeItem?.value ?? "-" }}
                    </template>

                    <template v-else-if="requirementValue.requirementAttribute.requirementAttributeType.datatype === 'price'">
                      {{ requirementValue.valueFloat ? formatAmountAndCurrency(requirementValue.valueFloat, requirementValue.currency) : "-" }}
                    </template>

                    <template v-else-if="requirementValue.requirementAttribute.requirementAttributeType.datatype === 'string'">
                      {{ requirementValue.valueString ?? "-" }}
                    </template>

                    <template v-else-if="requirementValue.requirementAttribute.requirementAttributeType.datatype === 'string_long'">
                      <template v-if="requirementValue.valueString">
                        <span v-html="nl2br(requirementValue.valueString)"></span>
                      </template>
                      <template v-else>
                        -
                      </template>
                    </template>
                  </td>
                </template>
              </template>
            </tr>
          </template>
          </tbody>
        </v-table>
      </v-card-item>
    </v-card>
  </template>
</template>

<script lang="ts" setup>

import {onMounted, Ref, ref}    from "vue"
import {useNumberFormatter}     from "@/helpers/formatters"
import RequirementService       from "@/api/RequirementService"
import RequirementTourContainer from "@/interfaces/RequirementTourContainer"
import RequirementTourPosition  from "@/interfaces/RequirementTourPosition"
import {useString}              from "@/helpers/string"
import RequirementValueFileItem from "@/components/RequirementValueFileItem.vue"
import RequirementValue         from "@/interfaces/RequirementValue"

onMounted(async () => {
  await init()
})

const requirementService = ref(new RequirementService())

const id = ref()
const isLoading = ref(true)

const requirementTourPositions: Ref<RequirementTourPosition[]> = ref([])

const {formatAmountAndCurrency} = useNumberFormatter()
const {nl2br} = useString()

const props = defineProps<{
  requirementTourContainer: RequirementTourContainer,
}>()

const init = async () => {
  await requirementService.value.getRequirementPositionsForRequirementContainer(props.requirementTourContainer.id)
      .then(data => {
        if (data.success && data.data) {
          requirementTourPositions.value = data.data
        }
      })

  isLoading.value = false
}

const style = (requirementValue: RequirementValue) => {

  if (requirementValue.requirementAttribute.requirementAttributeType.datatype === "bool") {
    return "width: 100px; min-width: 100px; white-space: nowrap;"
  }

  if (requirementValue.requirementAttribute.requirementAttributeType.datatype === "file") {
    return "width: 400px;  min-width: 400px; max-width: 350px; white-space: nowrap;"
  }

  if (requirementValue.requirementAttribute.requirementAttributeType.datatype === "float") {
    return "width: 175px; min-width: 175px; white-space: nowrap;"
  }

  if (requirementValue.requirementAttribute.requirementAttributeType.datatype === "int") {
    return "width: 130px; min-width: 130px; white-space: nowrap;"
  }

  if (requirementValue.requirementAttribute.requirementAttributeType.datatype === "item") {
    return "width: 250px; min-width: 200px; white-space: nowrap;"
  }

  if (requirementValue.requirementAttribute.requirementAttributeType.datatype === "price") {
    return "width: 150px; min-width: 150px; white-space: nowrap;"
  }

  if (requirementValue.requirementAttribute.requirementAttributeType.datatype === "string") {
    return "width: 250px; min-width: 250px; white-space: nowrap;"
  }

  if (requirementValue.requirementAttribute.requirementAttributeType.datatype === "string_long") {
    return "width: 400px; min-width: 400px; white-space: nowrap;"
  }

  return ""
}
</script>

<style>
#app {
  max-width: inherit !important;
}

.v-container {
  max-width: inherit !important;
}
</style>
