<template>
  <div>
    <div ref="clampedText" :class="clampedClass" :style="'-webkit-line-clamp: ' + maxLines ">
      <span v-html="thisText"></span>
    </div>

    <a v-if="isClamped && !useIcon"
       class="showMoreLess"
       @click="toggleClamped"
    >{{ clampedTextMoreLess }}</a>

    <v-btn v-if="isClamped && useIcon"
           @click="toggleClamped"
    >
      <v-icon :icon="icon"></v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts" setup>
import {nextTick, onMounted, onUnmounted, ref, watch} from "vue"

onMounted(async () => {
  thisText.value = props.text
})

const props = defineProps<{
  text: string,
  useIcon: boolean,
  maxLines: number
}>()

const thisText = ref()

const clampedText = ref(null)

const isClamped = ref(false)

const clampedClass = ref("clamped-text")
const clampedTextMoreLess = ref("mehr anzeigen")
const icon = ref("mdi-plus")

const toggleClamped = () => {
  if (clampedClass.value === "") {
    clampedClass.value = "clamped-text"
    clampedTextMoreLess.value = "mehr anzeigen"
    icon.value = "mdi-plus"
  } else {
    clampedClass.value = ""
    clampedTextMoreLess.value = "weniger anzeigen"
    icon.value = "mdi-minus"
  }
}

const checkIfClamped = () => {
  if (props.text.length === 0) {
    isClamped.value = false
  } else if (clampedText.value) {
    isClamped.value = clampedText.value.scrollHeight > clampedText.value.clientHeight
  }
}

const handleResize = () => {
  nextTick(() => {
    checkIfClamped()
  })
}

watch(thisText, async () => {
  await nextTick()
  checkIfClamped() // Check if clamped when text changes
})

onMounted(() => {
  checkIfClamped() // Initial check when component mounts
  window.addEventListener("resize", handleResize) // Add event listener for window resize
})

onUnmounted(() => {
  window.removeEventListener("resize", handleResize) // Clean up event listener
})
</script>

<style scoped>
.clamped-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.showMoreLess {
  cursor: pointer;
}
</style>
