<template>
  <v-container v-if="!isLoading" class="pa-0">
    <template v-if="wikiCategories.length > 0">
      <div class="flex-row">
        <div class="d-flex">
          <div class="flex-shrink-0" style="width: 250px;">

            <v-card class="mx-auto">
              <v-list density="compact" v-model:opened="open">
                <v-list-group v-for="wikiCategory in wikiCategories" :value="wikiCategory.id">
                  <template v-slot:activator="{ props }">
                    <v-list-item
                      v-bind="props"
                      :title="wikiCategory.name ?? '- fehlt -'"
                    ></v-list-item>
                  </template>

                  <v-list-item
                    v-for="wikiSubCategory in wikiCategory.wikiSubCategories"
                    :key="wikiSubCategory.id"
                    :title="wikiSubCategory.name ?? '- fehlt -'"
                    :value="wikiSubCategory.id"
                    :active="wikiSubCategory.id === selectedWikiSubCategory.id"
                    @click="selectItem(wikiCategory.id, wikiSubCategory.id)"
                  ></v-list-item>
                </v-list-group>
              </v-list>
            </v-card>

          </div>
          <div class="flex-grow-1" style="border-left: 1px solid #888; margin-left: 10px;">
            <WikiViewSubCategory :wikiContentItems="selectedContents"></WikiViewSubCategory>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      Es ist ein Fehler aufgetreten oder dieser Wiki-Eintrag existiert nicht.
    </template>
  </v-container>
</template>

<script lang="ts" setup>

import {onMounted, Ref, ref} from "vue"
import {useRoute, useRouter} from "vue-router"
import WikiService           from "@/api/WikiService"
import WikiCategory          from "@/interfaces/WikiCategory"
import WikiSubCategory       from "@/interfaces/WikiSubCategory"
import WikiViewSubCategory   from "@/components/WikiViewSubCategory.vue"
import WikiContent           from "@/interfaces/WikiContent"


const route = useRoute()
const router = useRouter()
const isLoading = ref(true)

const wikiService = ref(new WikiService())

const wikiCategories: Ref<WikiCategory[]> = ref([])
const selectedWikiSubCategory: Ref<WikiSubCategory> = ref({})
const selectedContents: Ref<WikiContent[]> = ref([])
const open = ref([])

const id = ref()

onMounted(async () => {
  await init()
})

const init = async () => {
  id.value = parseInt(route.params.id.toString() ?? -1)

  await wikiService.value.getWikiCategoryWithSubsByCategoryId(id.value).then(data => {
    if (data.success) {
      wikiCategories.value = data.data

      if (wikiCategories.value.length > 0) {
        open.value = [wikiCategories.value[0]?.id]

        selectItem(wikiCategories.value[0]?.id, wikiCategories.value[0]?.wikiSubCategories[0].id)
      }
    }
  })

  isLoading.value = false
}

const selectItem = async (wikiCategoryId, wikiSubCategoryId) => {
  const tmpWikiCategory: Ref<WikiCategory> = ref({})

  tmpWikiCategory.value = wikiCategories.value.filter(item => item.id === wikiCategoryId)[0] ?? []

  if (tmpWikiCategory.value?.wikiSubCategories) {
    selectedWikiSubCategory.value = tmpWikiCategory.value.wikiSubCategories.filter(item => item.id === wikiSubCategoryId)[0]
  }

  await wikiService.value.getWikiContentsBySubCategoryId(wikiSubCategoryId).then(data => {
    if (data.success) {
      selectedContents.value = data.data
    }
  })
}
</script>

<style>
.v-main {
  margin: 15px !important;
}

.v-container {
  padding-top: 0 !important;
}
</style>
