<template>
  <v-app-bar app color="on-primary" density="compact">
    <v-app-bar-title>
      <div class="header">
        <div class="header-logo">
          <img src="/logo.png" alt="Live Nation GmbH - Berlin (Chausseestraße)" @click="backToSystem" :class="system !== 'default' ? 'cursor-pointer' : ''"/>
        </div>
      </div>
    </v-app-bar-title>
  </v-app-bar>
</template>

<script lang="ts">

import { useRoute } from "vue-router"
import { onMounted, ref } from "vue"

export default {
  setup () {
    const route = useRoute()
    const system = ref('default')
    const id = ref()

    onMounted(async () => {
      if (route && route.name === "incoming-invoice") {
        system.value = "incoming-invoice"
      }
      if (route && route.name === "concert-create") {
        system.value = "concert-create"
      }
      if (route && route.name === "concert-add-oracle-codes") {
        system.value = "concert-add-oracle-codes"
      }
      if (route && route.name === "venue-specs-admin") {
        system.value = "venue-specs-admin"
      }
      if (route && route.name === "venue-specs") {
        system.value = "venue-specs"
        id.value = parseInt(route.params.id.toString())
      }
      if (route && route.name === "requirements") {
        system.value = "requirements"
        id.value = parseInt(route.params.id.toString())
      }
      if (route && route.name === "requirements-overview") {
        system.value = "requirements-overview"
      }
    })

    const backToSystem = () => {
      if (system.value === 'incoming-invoice') {
        window.location.href = import.meta.env.VITE_CONCERTON_URL + '/incoming-invoice/index';
      }
      if (system.value === 'concert-create') {
        window.location.href = import.meta.env.VITE_CONCERTON_URL
      }
      if (system.value === 'concert-add-oracle-codes') {
        window.location.href = import.meta.env.VITE_CONCERTON_URL
      }
      if (system.value === 'venue-specs') {
        window.location.href = import.meta.env.VITE_CONCERTON_URL + '/company/details?id=' + id.value + '&tab=venue-specs'
      }
      if (system.value === 'venue-specs-admin') {
        window.location.href = import.meta.env.VITE_CONCERTON_URL
      }
      if (system.value === 'requirements') {
        window.location.href = import.meta.env.VITE_CONCERTON_URL + '/tour/details?id=' + id.value + '&tab=requirements'
      }
      if (system.value === 'requirements-overview') {
        window.location.href = import.meta.env.VITE_CONCERTON_URL
      }
    }

    return {
      backToSystem,
      system,
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.v-toolbar__content > .v-toolbar-title {
  margin-inline-start: 0;
}

.header {
  height: 50px;
  padding-top: 5px;
  background-color: #1E262B;
  text-align: center;
}

.header-logo img {
  height: 40px;
}

.v-btn.v-theme--myCustomLightTheme.v-btn--density-default.v-btn--size-default.v-btn--variant-text {
  color: white;
}
</style>
