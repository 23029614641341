<template>
  <div class="navigation-button back" @click="back()">zurück</div>
</template>

<style scoped>
@import "@/styles/navigation-button.css";
</style>

<script lang="ts">
import { useRouter } from "vue-router";

export default {
  name: 'NavigationBackButton',
  props: {
    routerName: { type: String, required: true },
    routerParams: { default: {} },
  },
  setup(props) {
    const router = useRouter()

    const back = () => {
      if (!props.routerParams) {
        router.push({ name: props.routerName })
      } else {
        router.push({ name: props.routerName, params: props.routerParams })
      }
    }

    return { back }
  }
}
</script>
