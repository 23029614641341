<template>
  <template v-if="requirementTourPositions.length === 0">
    Es wurden noch keine Positionen hinterlegt.
  </template>

  <template v-else>
    <v-table density="compact">
      <thead>
      <tr>
        <template v-for="requirementValue in requirementTourPositions[0].requirementValues">
          <template v-if="requirementValue.requirementAttribute.requirementAttributeType.systemKey === 'concert'">
            <th>Konzert / Datum</th>
            <th>Ort</th>
            <th>Venue</th>
            <th>Promoter</th>
          </template>

          <th v-else>{{ requirementValue.requirementAttribute.name }}</th>
        </template>
        <th></th>
      </tr>
      </thead>

      <tbody>
      <template v-for="requirementTourPosition in requirementTourPositions">
        <RequirementTourPositionItem
          :requirementTourPosition="requirementTourPosition"
          :allConcertsForTour="allConcertsForTour"
          @deletePosition="deletePosition"
        ></RequirementTourPositionItem>
      </template>
      </tbody>
    </v-table>
  </template>
</template>

<script lang="ts" setup>
import RequirementTourPosition from "@/interfaces/RequirementTourPosition";
import RequirementTourPositionItem from "@/components/RequirementTourPositionItem.vue";
import Concert from "@/interfaces/Concert";

const props = defineProps<{
  requirementTourPositions: RequirementTourPosition[],
  allConcertsForTour: Concert[],
}>()

const emits = defineEmits(["deletePosition"])

const deletePosition = async (requirementTourPositionId: number) => {
  emits("deletePosition", requirementTourPositionId)
}
</script>
