import axios from 'axios'
import BaseApiService from '../BaseApiService'

export default class CompanyService extends BaseApiService {
  findVenues(searchString: string) {
    return axios.get(this.getApiUrl('/concert/company/find-venues'), {params: {searchString: searchString}})
      .then(res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  getVenue(id: number) {
    return axios.get(this.getApiUrl('/concert/company/get-venue'), {params: {id: id, expand: 'address'}})
      .then(res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  findCoPromoters(searchString: string) {
    return axios.get(this.getApiUrl('/concert/company/find-co-promoters'), {params: {searchString: searchString}})
      .then(res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  getCoPromoter(id: number) {
    return axios.get(this.getApiUrl('/concert/company/get-co-promoter'), {params: {id: id, expand: 'address'}})
      .then(res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  findArrangeurs(searchString: string) {
    return axios.get(this.getApiUrl('/concert/company/find-arrangeurs'), {params: {searchString: searchString}})
      .then(res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  getArrangeur(id: number) {
    return axios.get(this.getApiUrl('/concert/company/get-arrangeur'), {params: {id: id, expand: 'address'}})
      .then(res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }
}
