<template>
  <v-row dense>
    <v-col>
      <h3>Rechnung hochladen</h3>
    </v-col>
  </v-row>

  <v-row dense>
    <v-col cols="12" v-cloak @drop.prevent="addDropFileForInvoice" @dragover.prevent>
      <v-file-input
        v-model="incomingInvoice.invoicePdf"
        @change="previewImage"
        @click:clear="clearPreviewImage"
        accept="application/pdf"
        label="Rechnung * (.pdf)"
        prepend-icon="mdi-file-document-edit-outline"
        :disabled="lookingUpInvoice"
      ></v-file-input>
      <div
        v-if="lookingUpInvoice"
        class="invoice-check-info border mb-4"
      >
        <div class="float-left"><v-icon icon="fa:fas fa-rotate fa-spin"></v-icon></div>
        <div class="float-left pl-2">Rechnung wird geprüft um Daten zu ermitteln.</div>
        <br style="clear: both" />
      </div>
      <div
        v-if="lookingUpInvoiceCausedError"
        class="invoice-check-error border mb-4"
      >
        <div class="">Rechnung konnte nicht überprüft werden. Daten aus Rechnung konnten nicht übernommen werden.</div>
      </div>
    </v-col>
  </v-row>

  <v-row dense>
    <v-col>
      <embed
        v-if="invoiceImagePreview"
        type="application/pdf"
        :src="invoiceImagePreview"
        width="100%"
        style="height: 40rem"
      />
    </v-col>
  </v-row>

  <v-row
    v-if="incomingInvoice.invoicePdf && incomingInvoice.invoicePdf[0]"
    dense
  >
    <v-col>
      <v-btn
        size="small"
        style="width:100%"
        @click="openPdfInWindow"
      >Rechnung in eigenem Fenster öffnen</v-btn>
    </v-col>
  </v-row>

  <v-row dense>
    <v-col>
      <h3>Anhänge hochladen</h3>
    </v-col>
  </v-row>

  <v-row dense>
    <v-col v-cloak @drop.prevent="addDropFilesForUploads" @dragover.prevent>
      <v-file-input
        v-model="incomingInvoice.attachments"
        accept="application/pdf,image/png,image/jpeg,image/tiff"
        show-size
        counter
        multiple
        label="Anhänge (.pdf, .jpg, .png, .tiff)"
      ></v-file-input>
    </v-col>
  </v-row>
</template>

<style>
.invoice-check-info {
  padding: 10px;
  background-color: rgba(236, 235, 140, 0.83);
}
.invoice-check-error {
  padding: 10px;
  background-color: rgba(236, 140, 154, 0.83);
}
</style>

<script lang="ts">

import { PropType, ref } from "vue"
import IncomingInvoice from "@/interfaces/IncomingInvoice";
import IncomingInvoiceService from "@/api/IncomingInvoiceService";

export default {
  props: {
    incomingInvoice: { type: Object as PropType<IncomingInvoice>, required: true },
    availableCurrencies: { type: Array, required: true  },
    checkDuplicate: { type: Function, required: true },
  },
  setup(props) {
    const invoiceImagePreview = ref(props.incomingInvoice.invoicePdf ? URL.createObjectURL(props.incomingInvoice.invoicePdf[0]) : null)

    const lookingUpInvoice = ref(false)
    const lookingUpInvoiceCausedError = ref(false)

    const incomingInvoiceService = ref(new IncomingInvoiceService())

    const previewImage = () => {
      if (props.incomingInvoice.invoicePdf) {
        invoiceImagePreview.value = URL.createObjectURL(props.incomingInvoice.invoicePdf[0])

        openPdfInWindow()

        checkInvoice()
      }
    }

    function openPdfInWindow() {
      if (props.incomingInvoice.invoicePdf && props.incomingInvoice.invoicePdf[0]) {
        window.open(URL.createObjectURL(props.incomingInvoice.invoicePdf[0]), 'iin_preview_window', 'width=1500, height=800');
      }
    }

    function getCurrentDate() {
      const current = new Date();
      return `${current.getDate()}.${current.getMonth() + 1}.${current.getFullYear()}`;
    }

    const clearPreviewImage = () => {
      invoiceImagePreview.value = null
      props.incomingInvoice.vatId = null
      props.incomingInvoice.iban = null
    }

    const addDropFileForInvoice = (e) => {
      props.incomingInvoice.invoicePdf = Array.from(e.dataTransfer.files).splice(0, 1);
      invoiceImagePreview.value = URL.createObjectURL(props.incomingInvoice.invoicePdf[0])

      openPdfInWindow()

      checkInvoice()
    }

    const addDropFilesForUploads = (e) => {
      props.incomingInvoice.attachments = Array.from(e.dataTransfer.files);
    }

    function checkInvoice() {
      props.incomingInvoice.vatId = null
      props.incomingInvoice.iban = null

      lookingUpInvoice.value = true
      lookingUpInvoiceCausedError.value = false

      incomingInvoiceService.value.checkInvoicePdf(props.incomingInvoice).then(data => {
        if (!!data && !!data.data && data.data.success) {
          let invoiceData = data.data.data

          if (!props.incomingInvoice.totalAmount && invoiceData.totalAmount) {
            props.incomingInvoice.totalAmount = invoiceData.totalAmount
          }
          if (!props.incomingInvoice.company && invoiceData.company) {
            props.incomingInvoice.company = invoiceData.company
          }
          if (!props.incomingInvoice.number && invoiceData.number) {
            props.incomingInvoice.number = invoiceData.number
          }
          if ((!props.incomingInvoice.currency || props.incomingInvoice.currency.iso3 === 'EUR') && invoiceData.currency) {
            let currencySelected = props.availableCurrencies.filter((item) => {
              return item.id === invoiceData.currency.id
            })

            props.incomingInvoice.currency = currencySelected[0]
          }
          if ((!props.incomingInvoice.date || props.incomingInvoice.date === getCurrentDate()) && invoiceData.date) {
            props.incomingInvoice.date = invoiceData.date

            if (!props.incomingInvoice.dateOfPayment && invoiceData.dateOfPayment) {
              props.incomingInvoice.dateOfPayment = invoiceData.dateOfPayment
            }
          }

          if (invoiceData.vatId) {
            props.incomingInvoice.vatId = invoiceData.vatId
          } else {
            props.incomingInvoice.vatId = null
          }
          if (invoiceData.iban) {
            props.incomingInvoice.iban = invoiceData.iban
          } else {
            props.incomingInvoice.iban = null
          }

          props.incomingInvoice.senderCompany.name = invoiceData.Sender.Name ?? null
          props.incomingInvoice.senderCompany.city = invoiceData.Sender.City ?? null
          props.incomingInvoice.senderCompany.country = invoiceData.Sender.Country ?? null
          props.incomingInvoice.senderCompany.street = invoiceData.Sender.Street ?? null
          props.incomingInvoice.senderCompany.zipcode = invoiceData.Sender.ZipCode ?? null
          props.incomingInvoice.senderCompany.email = invoiceData.Sender.Email ?? null
          props.incomingInvoice.senderCompany.phone = invoiceData.Sender.Phone ?? null
          props.incomingInvoice.senderCompany.fax = invoiceData.Sender.Fax ?? null
          props.incomingInvoice.senderCompany.homepage = invoiceData.Sender.WebsiteUrl ?? null
        } else {
          lookingUpInvoiceCausedError.value = true
        }
        lookingUpInvoice.value = false
        props.checkDuplicate()
      });
    }

    return {
      invoiceImagePreview,
      previewImage,
      clearPreviewImage,
      addDropFileForInvoice,
      addDropFilesForUploads,
      lookingUpInvoice,
      lookingUpInvoiceCausedError,
      openPdfInWindow
    }
  },
}

</script>
