export function useDateFormatter() {

  function getShortWeekday(dateString: string|null) {
    if (!dateString) {
      return null
    }

    const weekdayShort = ['So','Mo','Di','Mi','Do','Fr','Sa'];

    let myDate = getDateByDateString(dateString);

    if (!myDate) {
      return null
    }

    let dayNumber = myDate.getDay();

    return weekdayShort[dayNumber]
  }

  function getDateByDateString(dateString: string|null) : null|Date {
    if (!dateString) {
      return null
    }

    let parts = dateString.split('.');
    return new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
  }

  function getDateStringByDate(date: Date|null) {
    if (date !== null) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();

      return day + '.' + month + '.' + year
    }

    return null
  }

  function getTimeStringByDate(date: Date|null) {
    if (date !== null) {
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');

      return hours + ':' + minutes + ':' + seconds
    }

    return null
  }

  function getDateTimeStringByDate(dateString: string|null) {
    if (dateString !== null) {
      const date = new Date(dateString)
      return getDateStringByDate(date) + ', ' + getTimeStringByDate(date) + ' Uhr'
    }

    return null
  }

  return { getShortWeekday, getDateByDateString, getDateStringByDate, getDateTimeStringByDate }
}
