<template>
  <template v-if="!isLoading">
    <template v-for="venueSpecsSection in allVenueSpecsSections">
      <VenueSpecsBlockSection :company-id="companyId"
                              :edit-mode="editMode"
                              :venue-specs-chapter-type="venueSpecsChapterType"
                              :venue-specs-section="venueSpecsSection"
                              :venue-specs-section-company-entries="getVenueSpecsSectionCompanyEntriesForSection(venueSpecsSection.id)"
      ></VenueSpecsBlockSection>
      <hr class="mt-2 mb-2">
    </template>
  </template>
</template>

<script lang="ts" setup>

import {onMounted, Ref, ref}    from "vue"
import useEventsBus             from "@/composables/eventBus"
import VenueSpecsService        from "@/api/VenueSpecsService"
import {useRoute, useRouter}    from "vue-router"
import VenueSpecsSection        from "@/interfaces/VenueSpecsSection"
import VenueSpecsSectionCompany from "@/interfaces/VenueSpecsSectionCompany"
import VenueSpecsBlockSection   from "@/components/VenueSpecsBlockSection.vue"

onMounted(async () => {
  await init()
})

const init = async () => {
  await venueSpecsService.value.getVenueSpecsSectionsForChapter(props.venueSpecsChapterType).then(data => {
    allVenueSpecsSections.value = data.data.venueSpecsSections
  })
  isLoading.value = false
}

const {bus} = useEventsBus()

const route = useRoute()
const router = useRouter()

const isLoading = ref(true)

const venueSpecsService = ref(new VenueSpecsService())

const allVenueSpecsSections: Ref<VenueSpecsSection[]> = ref([])
const venueSpecsSectionCompanyEntries: Ref<VenueSpecsSectionCompany[]> = ref([])

const props = defineProps<{
  venueSpecsChapterType: string,
  companyId: number,
  editMode: boolean
}>()

const getVenueSpecsSectionCompanyEntriesForSection = (sectionId: number) => {
  const filtered: Ref<VenueSpecsSectionCompany[]> = ref([])
  filtered.value = venueSpecsSectionCompanyEntries.value.filter(item => {
    return item.vssId === sectionId
  }).sort((a, b) => {
    if (a.sortorder < b.sortorder) {
      return -1
    }
    if (a.sortorder > b.sortorder) {
      return 1
    }
    return 0
  })

  return filtered.value
}
</script>

<style>
.section-header > div {
  padding: 10px;
  font-weight: bold;
}
</style>
