<template>
  <v-number-input
    v-model="number"
    :label="label"
    :min="min"
    :max="max"
    :control-variant="controlVariant"
    :density="density"
    :clearable="clearable"
    :persistent-counter="persistentCounter"
    @update:modelValue="debouncedUpdate"
  >
  </v-number-input>
</template>

<script setup lang="ts">
import {PropType, ref} from 'vue';
import _ from 'lodash';

const number = ref(0);

type Density = null | 'default' | 'comfortable' | 'compact';
type ControlVariant = 'default' | 'stacked' | 'split';

const emits = defineEmits(["callback"])

const props = defineProps({
  min: {
    type: Number,
    default: 1
  },
  max: {
    type: Number,
    default: 1000000000
  },
  label: {
    type: String,
    default: null
  },
  controlVariant: {
    type: String as PropType<ControlVariant>,
    default: 'split'
  },
  density: {
    type: String as PropType<Density>,
    default: 'compact'
  },
  clearable: {
    type: Boolean,
    default: true
  },
  persistentCounter: {
    type: Boolean,
    default: true
  }
})

const debouncedUpdate = _.debounce((value: number) => {
  if (value > props.max) {
    return;
  }
  emits('callback', value); // Emit the value with the event
}, 500);

</script>

