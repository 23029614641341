import axios from 'axios'
import BaseApiService from '../BaseApiService'
import Concert from "@/interfaces/Concert"
import Tour from "@/interfaces/Tour"

export default class ConcertService extends BaseApiService {
  findTours(searchString: string) {
    return axios.get(this.getApiUrl('/concert/tour/index'), {params: {searchString: searchString}})
      .then(res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  getTour(id: number) {
    return axios.get(this.getApiUrl('/concert/tour/view'), {params: {id: id, expand: 'concerts,concerts.arrangeurObj,concerts.coPromoterObj,concerts.venueObj,concerts.venueObj.address'}})
      .then(res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  getToursWithoutOracleCode() {
    return axios.get(this.getApiUrl('/concert/tour/get-all-without-oracle-code'), {params: {expand: 'concerts,concerts.venueObj,concerts.venueObj.address,projectManager,concerts.coPromoterObj,concerts.temporaryType,concerts.department'}})
      .then(res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  saveOracleCodesForTourAndSend(tour: Tour) {
    const url = this.getApiUrl('/concert/tour/save-oracle-codes-for-tour-and-send');

    return axios.post(url,
      { tour: tour }
    )
      .then(async res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  findTypes() {
    return axios.get(this.getApiUrl('/concert/concert/find-types'))
      .then(res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  sendAndSave(concerts: Concert[], tourId: number, type: string) {
    const url = this.getApiUrl('/concert/concert/send-and-save');

    return axios.post(url,
      { concerts: concerts, tourId: tourId, type: type }
    )
      .then(async res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  saveOracleCodes(concerts: Concert[]) {
    const url = this.getApiUrl('/concert/concert/set-oracle-codes');

    return axios.post(url,
      { concerts: concerts }
    )
      .then(async res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }

  getAllDepartments() {
    const url = this.getApiUrl('/concert/concert/get-all-departments');

    return axios.get(url)
      .then(async res => {
        return this.processResult(res);
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      });
  }
}
