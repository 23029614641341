<template>
  <template v-if="!isLoading">
    <div v-if="wikiContentItem">
      <div v-if="wikiContentItem.type === 'text'">
        <div class="tiptap-wysiwyg" v-html="wikiContentItem.text"></div>
      </div>

      <div v-if="wikiContentItem.type === 'image'">
        <img
          class="wiki-content-image"
          :src="image"
          alt="image"
        />
      </div>

      <div v-if="wikiContentItem.type === 'video'">
        <video controls class="wiki-content-video">
          <source :src="video" type="video/mp4" />
        </video>
      </div>

      <WikiContentCreationInfo :wikiContentItem="wikiContentItem"></WikiContentCreationInfo>
    </div>

    <template v-else>
      Nicht vorhanden!
    </template>
  </template>
</template>

<script lang="ts" setup>

import { onMounted, ref, watch } from "vue"
import WikiService               from "@/api/WikiService"
import WikiContent               from "@/interfaces/WikiContent"
import WikiContentCreationInfo   from "@/components/WikiContentCreationInfo.vue"

const wikiService = ref(new WikiService())

const isLoading = ref(true)

const image = ref()
const video = ref()

const props = defineProps<{
  wikiContentItem: WikiContent,
}>()

onMounted(async () => {
  await init()
})

watch(() => props.wikiContentItem,
  async () => {
    await init()
  },
  { deep: true }
)

const init = async () => {
  if (props.wikiContentItem.imageGuid && props.wikiContentItem.type === "image") {
    await wikiService.value.getFileByGuid(props.wikiContentItem.imageGuid).then(data => {
      if (data.success) {
        image.value = URL.createObjectURL(new File([data.data], "image", { type: props.wikiContentItem.imageMimetype ?? "image/jpg" }))
      }
    })
  }
  if (props.wikiContentItem.vidId && props.wikiContentItem.type === "video") {
    await wikiService.value.getVideoById(props.wikiContentItem.vidId).then(data => {
      if (data.success) {
        video.value = URL.createObjectURL(new File([data.data], "video", { type: "video/mp4" }))
      }
    })
  }

  isLoading.value = false
}
</script>

<style>
.wiki-content-image {
  border: 1px solid #eee;
  max-width: 50%;
}

.wiki-content-video {
  border: 1px solid #eee;
  max-width: 50%;
}
</style>
