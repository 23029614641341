<template>
  <v-container v-if="!isLoading" class="pt-10">
    <h1>Venue Specs (<a href="#" @click="backToSystem">{{company.nameShort ?? company.name}}</a>)</h1>

    <v-tabs
      v-model="tab"
      bg-color="grey-lighten-2"
    >
      <template v-for="venueSpecsChapter in venueSpecsChapters">
        <v-tab :disabled="venueSpecsChapter.systemKey !== tab && editMode" :value="venueSpecsChapter.systemKey">
          {{ venueSpecsChapter.name }}
          <v-icon
            v-if="venueSpecsChapter.systemKey === tab && editMode"
            class="ml-2"
            icon="mdi-content-save"
            size="medium"
          ></v-icon>
        </v-tab>
      </template>
    </v-tabs>

    <v-card-text>
      <v-window v-model="tab">
        <template v-for="venueSpecsChapter in venueSpecsChapters">
          <v-window-item :value="venueSpecsChapter.systemKey">
            <VenueSpecsBlock v-if="venueSpecsChapter.systemKey === 'general' || venueSpecsChapter.systemKey === 'production'"
                             :company-id="companyId"
                             :edit-mode="editMode"
                             :venue-specs-chapter-type="venueSpecsChapter.systemKey"
            ></VenueSpecsBlock>

            <VenueSpecsDirectionsUploadBlock v-else-if="venueSpecsChapter.systemKey === 'directions'"
                                             :company-id="companyId"
                                             :edit-mode="editMode"
                                             :venue-specs-chapter-type="venueSpecsChapter.systemKey"
            ></VenueSpecsDirectionsUploadBlock>

            <VenueSpecsUploadBlock v-else
                                   :company-id="companyId"
                                   :edit-mode="editMode"
                                   :venue-specs-chapter-type="venueSpecsChapter.systemKey"
            ></VenueSpecsUploadBlock>
          </v-window-item>
        </template>
      </v-window>
    </v-card-text>

    <v-row>
      <v-col>
        <v-btn :disabled="editMode" @click="backToSystem">Zurück</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts" setup>

import useEventsBus                    from "@/composables/eventBus"
import {onMounted, Ref, ref, watch}    from "vue"
import {useRoute, useRouter}           from "vue-router"
import VenueSpecsBlock                 from "@/components/VenueSpecsBlock.vue"
import VenueSpecsService               from "@/api/VenueSpecsService"
import VenueSpecsChapter               from "@/interfaces/VenueSpecsChapter"
import VenueSpecsUploadBlock           from "@/components/VenueSpecsUploadBlock.vue"
import VenueSpecsDirectionsUploadBlock from "@/components/VenueSpecsDirectionsUploadBlock.vue"
import Company from "@/interfaces/Company";


onMounted(async () => {
  await init()
})

const init = async () => {
  companyId.value = parseInt(route.params.id.toString())

  await venueSpecsService.value.getCompany(companyId.value).then(data=> {
    company.value = data.data.company
  })

  await venueSpecsService.value.getAllVenueSpecsChapters().then(data => {
    venueSpecsChapters.value = data.data.venueSpecsChapters
  })

  isLoading.value = false
}

const route = useRoute()
const router = useRouter()

const venueSpecsService = ref(new VenueSpecsService())
const venueSpecsChapters: Ref<VenueSpecsChapter[]> = ref([])

const companyId = ref()
const company: Ref<Company> = ref({})

const {bus} = useEventsBus()

const tab = ref("general")
const isLoading = ref(true)

const editMode = ref(false)

watch(() => bus.value.get("setEditMode"), (val) => {
  // destruct the parameters
  const [editModeBus] = val ?? null
  editMode.value = editModeBus
})

const backToSystem = () => {
  window.location.href = import.meta.env.VITE_CONCERTON_URL + "/company/details?id=" + companyId.value + "&tab=venue-specs"
}
</script>
