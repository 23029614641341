<template>
  <v-app :class="appClass">
    <v-main>
      <TheNotification />

      <template v-if="showHeader()">
        <TheLayoutHeader />
      </template>

      <AppMain />

      <template v-if="showHeader()">
        <TheLayoutFooter />
      </template>
    </v-main>
  </v-app>
</template>

<style>
@media only screen and (min-width: 601px) {
  .v-application.tour-cash-register {
    margin: auto;
    max-width: 600px !important;
    border-left: 1px solid black;
    border-right: 1px solid black;
  }

  body {
    /*background-color: black;*/
  }
}
</style>

<script>
import { computed }    from 'vue';
import { useRoute }    from 'vue-router';
import AppMain         from './components/AppMain.vue';
import TheLayoutFooter from './components/TheLayoutFooter.vue';
import TheLayoutHeader from './components/TheLayoutHeader.vue';
import TheNotification from './components/TheNotification.vue';

export default {
  name: 'App',

  components: {
    TheLayoutFooter,
    TheLayoutHeader,
    TheNotification,
    AppMain,
  },

  setup() {
    const route = useRoute();

    const appClass = computed(() => {
      if (route.name.match(/^tour-cash-register/)) {
        document.title = 'Tourkasse';
        return 'tour-cash-register';
      }

      if (route.name.match(/^concert-create/)) {
        document.title = 'Konzertanlage';
        return 'concert-create';
      }

      if (route.name.match(/^concert-add-oracle-codes/)) {
        document.title = 'Oracle-ID Vergabe';
        return 'concert-add-oracle-codes';
      }

      if (route.name.match(/^requirements-overview/)) {
        document.title = 'Requirements-Übersicht';
        return 'requirements-overview';
      }

      if (route.name.match(/^requirements/)) {
        document.title = 'Requirements';
        return 'requirements';
      }

      if (route.name.match(/^venue-specs\/admin/)) {
        document.title = 'Venue Specs Verwaltung';
        return 'venue-specs-admin';
      }

      if (route.name.match(/^venue-specs/)) {
        document.title = 'Venue Specs';
        return 'venue-specs';
      }

      if (route.name.match(/^wiki/)) {
        document.title = 'Wiki';
        return 'wiki';
      }

      if (route.name === 'watchdog-page') {
        document.title = 'Watchdog';
        return 'watchdog-page';
      }

      document.title = 'Eingangsrechnungserfassung';
      return 'incoming-invoice';
    });

    const showHeader = () => {
      return (window.location === window.parent.location);
    }

    return { appClass, showHeader };
  },

  data: () => ({})
};
</script>
