<template>
  <v-btn @click="showVideo">
    <v-icon icon="mdi-video"></v-icon>
    Videoanleitung
  </v-btn>
</template>

<script lang="ts" setup>
const props = defineProps<{
  videoId: number,
}>()

const showVideo = () => {
  window.open(import.meta.env.VITE_CONCERTON_URL + "/video/play?id=" + props.videoId)
}
</script>
