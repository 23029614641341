import axios           from "axios"
import BaseApiService  from "./BaseApiService"
import WikiCategory    from "@/interfaces/WikiCategory"
import WikiSubCategory from "@/interfaces/WikiSubCategory"
import WikiContent     from "@/interfaces/WikiContent"

export default class WikiService extends BaseApiService {
  // region categories
  getAllWikiCategories() {
    return axios.get(this.getApiUrl("/wiki-admin/get-all-wiki-categories"))
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getWikiCategoryByCategoryId(categoryId: number | null) {
    return axios.get(this.getApiUrl("/wiki-admin/get-wiki-category-by-category-id"), {params: {categoryId: categoryId}})
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getWikiCategoryWithSubsByCategoryId(categoryId: number | null) {
    return axios.get(this.getApiUrl("/wiki-view/get-wiki-categories-for-list"), {params: {categoryId: categoryId, expand: "wikiSubCategories"}})
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  saveWikiCategory(wikiCategory: WikiCategory) {
    const url = this.getApiUrl("/wiki-admin/save-wiki-category")

    return axios.post(url,
      {
        wikiCategory: wikiCategory
      }
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  deleteWikiCategory(categoryId: number | null) {
    const url = this.getApiUrl("/wiki-admin/delete-wiki-category")

    return axios.get(url,
      {
        params: {
          categoryId: categoryId
        }
      }
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  resortWikiCategories(wikiCategories: WikiCategory[]) {
    const url = this.getApiUrl("/wiki-admin/resort-wiki-categories")

    return axios.post(url,
      {
        wikiCategories: wikiCategories
      }
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  // endregion

  // region subcategories
  getWikiSubCategoriesByCategoryId(categoryId: number) {
    return axios.get(this.getApiUrl("/wiki-admin/get-all-wiki-sub-categories-by-category-id"), {params: {categoryId: categoryId}})
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getWikiSubCategoryBySubCategoryId(subCategoryId) {
    return axios.get(this.getApiUrl("/wiki-admin/get-wiki-sub-category-by-sub-category-id"), {params: {subCategoryId: subCategoryId}})
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  saveWikiSubCategory(wikiSubCategory: WikiSubCategory, categoryId: number) {
    const url = this.getApiUrl("/wiki-admin/save-wiki-sub-category")

    return axios.post(url,
      {
        wikiSubCategory: wikiSubCategory,
        categoryId: categoryId
      }
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  changeVisibilityForSubCategoryItem(wikiSubCategory: WikiSubCategory, isVisible: boolean) {
    const url = this.getApiUrl("/wiki-admin/change-visibility-wiki-sub-category")

    return axios.post(url,
      {
        wikiSubCategory: wikiSubCategory,
        isVisible: isVisible
      }
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  deleteWikiSubCategory(subCategoryId: number | null) {
    const url = this.getApiUrl("/wiki-admin/delete-wiki-sub-category")

    return axios.get(url,
      {
        params: {
          subCategoryId: subCategoryId
        }
      }
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  resortWikiSubCategories(wikiSubCategories: WikiSubCategory[], categoryId: number | null) {
    const url = this.getApiUrl("/wiki-admin/resort-wiki-sub-categories")

    return axios.post(url,
      {
        wikiSubCategories: wikiSubCategories,
        categoryId: categoryId
      }
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  // endregion

  // region content
  getWikiContentsBySubCategoryId(subCategoryId: number) {
    return axios.get(this.getApiUrl("/wiki-view/get-all-wiki-contents-by-sub-category-id"), {params: {subCategoryId: subCategoryId}})
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getFileByGuid(guid: string) {
    return axios.get(this.getApiUrl("/wiki-view/get-file-by-guid"),
      {
        responseType: "blob",
        params: {
          guid: guid
        }
      })
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processResult(error.message)
      })
  }

  getVideoById(id: number) {
    return axios.get(this.getApiUrl("/wiki-view/get-video-by-id"),
      {
        responseType: "blob",
        params: {
          id: id
        }
      })
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processResult(error.message)
      })
  }

  saveWikiContent(wikiContent: WikiContent, type: string, subCategoryId: number | null) {
    const url = this.getApiUrl("/wiki-admin/save-wiki-content")

    const headers = {
      "Content-Type": "multipart/form-data"
    }

    return axios.post(url,
      {
        wikiContent: wikiContent,
        type: type,
        subCategoryId: subCategoryId
      },
      {headers}
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  deleteWikiContent(contentId: number | null) {
    const url = this.getApiUrl("/wiki-admin/delete-wiki-content")

    return axios.get(url,
      {
        params: {
          contentId: contentId
        }
      }
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  resortWikiContents(wikiContents: WikiContent[], subCategoryId: number | null) {
    const url = this.getApiUrl("/wiki-admin/resort-wiki-contents")

    return axios.post(url,
      {
        wikiContents: wikiContents,
        subCategoryId: subCategoryId
      }
    )
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  getAllVideoUrls() {
    const url = this.getApiUrl("/wiki-admin/get-all-video-urls")

    return axios.get(url)
      .then(async res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }

  // endregion
}
