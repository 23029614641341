<template>
  <tr :class='tour === selectedTour ? "selected" : ""'>
    <td>
      {{ getOldestConcert(tour.concerts)?.tstampCreated.substring(0, 10) }},
      {{ getOldestConcert(tour.concerts)?.tstampCreated.substring(11, 16) }} Uhr
    </td>
    <td>{{ tour.name }}</td>
    <td>{{ getConcertSplitUp(tour) }}</td>
    <td>{{ tour.projectManager?.name ?? "-" }}</td>
    <td>
      <v-btn @click="emit('setSelectedTour', { tour })"
             size="small"
             :disabled="!!selectedTour"
      >
        anzeigen
      </v-btn>
    </td>
  </tr>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useDateFormatter } from "@/helpers/dateFormatter"
import Tour from "@/interfaces/Tour"
import Concert from "@/interfaces/Concert"
import useEventsBus from "@/composables/eventBus"

const props = defineProps<{
  tour: Tour,
  selectedTour: Tour | null
}>()

const { emit } = useEventsBus()
const { getDateByDateString } = useDateFormatter()

const getConcertCountByTourAndIso2 = (tour, iso2) => {
  return tour.concerts.filter(item => {
    return item?.venueObj?.address?.country?.iso2 === iso2
  }).length
}

const getConcertSplitUp = (tour) => {
  let str = tour.concerts.length

  str += " ("
  str += getConcertCountByTourAndIso2(tour, "DE") + " | "
  str += getConcertCountByTourAndIso2(tour, "AT") + " | "
  str += getConcertCountByTourAndIso2(tour, "CH")
  str += ")"

  return str
}

const getOldestConcert = (concerts): Concert => {
  return concerts.reduce((oldest, current) => {
    let oldestDate = getDateByDateString(oldest.tstampCreated)
    let currentDate = getDateByDateString(current.tstampCreated)

    if (currentDate < oldestDate) {
      return current
    } else {
      return oldest
    }
  }, concerts[0])
}
</script>
