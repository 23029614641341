import { reactive } from "vue";
import IncomingInvoice from "@/interfaces/IncomingInvoice";
import InvoiceGroup from "@/interfaces/InvoiceGroup";

const defaultNotificationData = { message: null, timeout: 3000, color: 'green' };

export default {
  state: {
    globalNotification: defaultNotificationData,
    incomingInvoice: reactive({} as IncomingInvoice),
    availableInvoiceGroups: reactive({} as InvoiceGroup[])
  },
  getters: {
    getGlobalNotification(state) {
      return state.globalNotification;
    }
  },
  mutations: {
    setSuccessNotification(state, message) {
      state.globalNotification = {
        message: message,
        timeout: 3000,
        color: 'green',
      };
    },
    setErrorNotification(state, message) {
      state.globalNotification = {
        message: message,
        timeout: -1,
        color: 'red',
      };
    },
    clearNotification(state) {
      state.globalNotification = defaultNotificationData;
    }
  },
}
