<template>
  <v-container v-if="!isLoading" class="pt-14">
    <div v-if="saveOrDeleteIsDone && saveType === 'update'">
      <div class="text-center">Sie können dieses Fenster <a href="#" @click="closeWindow()">jetzt schließen.</a></div>
    </div>
    <div v-else>
      <TourCashRegisterNavigationButtonBack
        router-name="tour-cash-register-receipt-index"
        :router-params="{id: tourCashRegisterReceipt.tourCashRegister.id}"
      ></TourCashRegisterNavigationButtonBack>
      <TourCashRegisterNavigationButtonLogout></TourCashRegisterNavigationButtonLogout>

      <div>
        <div class="text-center">
          <h2>
            {{ tourCashRegisterReceipt.tourCashRegister.tourName }}
          </h2>
          <div class="tour-cash-register-headline">
            Akt. Kassenbestand: {{ formatAmountAndCurrency(tourCashRegisterReceipt.tourCashRegister.amount, tourCashRegisterReceipt.tourCashRegister.currency) }}
          </div>

          <div class="tour-cash-register-receipt-document-number mt-3">
            {{ tourCashRegisterReceipt.documentNumber }}
          </div>

          <div class="mt-3">
            <object
              :type="tourCashRegisterReceipt.tourCashRegisterFile.mimeType"
              :data="documentFile"
              :style="tourCashRegisterReceipt.tourCashRegisterFile.mimeType === 'application/pdf' ? 'width: 100%; height: 40rem' : 'max-width: 100%;'"
            />
          </div>

          <a href="" @click="openFile($event)">Beleg in neuem Fenster öffnen</a>
        </div>

        <hr class="mt-3">

        <v-form @submit.prevent="saveReceipt" ref="myForm" v-model="isValid">
          <div class="mt-3" v-if="!tourCashRegisterReceipt.type">
            <div>
              <v-btn
                style="width: 100%"
                @click="tourCashRegisterReceipt.type = TourCashRegisterReceiptType.EXPENDITURE"
                color="success"
              >
                {{ getTextByType(TourCashRegisterReceiptType.EXPENDITURE) }}
              </v-btn>
            </div>

            <div class="mt-3">
              <v-btn
                style="width: 100%"
                @click="tourCashRegisterReceipt.type = TourCashRegisterReceiptType.INCREASE"
                color="success"
              >
                {{ getTextByType(TourCashRegisterReceiptType.INCREASE) }}
              </v-btn>
            </div>
          </div>

          <div v-else class="mt-3">
            <div class="tour-cash-register-headline text-center mb-3">
              {{ getTextByType(tourCashRegisterReceipt.type) }}
            </div>

            <DatepickerInput
              :data-model="tourCashRegisterReceipt"
              field="date"
              label="Belegdatum"
              :required="true"
              :clearable="true"
              return-object
            ></DatepickerInput>

            <template v-for="(tourCashRegisterReceiptPosition, idx) in tourCashRegisterReceipt.tourCashRegisterReceiptPositions">
              <div :class="{'bordered-amount-block': idx > 0, 'mb-5': idx > 0}">
                <v-text-field
                  v-if="tourCashRegisterReceipt.type !== TourCashRegisterReceiptType.INCREASE && !!tourCashRegisterReceiptPosition.amountNet"
                  :model-value="tourCashRegisterReceiptPosition.amountGrossCalculated ?? null"
                  label="Bruttobetrag (inkl. MwSt.)"
                  class="calculated-field"
                  :reverse="true"
                  disabled
                ></v-text-field>

                <CurrencyInput
                  v-if="!tourCashRegisterReceiptPosition.amountNet"
                  v-model="tourCashRegisterReceiptPosition.amountGross"
                  :options="{ }"
                  :currency="tourCashRegisterReceipt.tourCashRegister.currency"
                  :label="tourCashRegisterReceipt.type === TourCashRegisterReceiptType.INCREASE ? 'Erhaltener Betrag' : 'Bruttobetrag (inkl. MwSt.)'"
                  :disabled="!!tourCashRegisterReceiptPosition.amountNet"
                  :required="(idx === 0 && !tourCashRegisterReceiptPosition.amountNet) || (idx > 0 && !!tourCashRegisterReceiptPosition.fee)"
                  @change="calculatePosition(tourCashRegisterReceiptPosition)"
                />

                <v-autocomplete
                  v-if="tourCashRegisterReceipt.type !== TourCashRegisterReceiptType.INCREASE"
                  v-model="tourCashRegisterReceiptPosition.fee"
                  item-title="valueName"
                  item-value="id"
                  label="MwSt."
                  :items="feeItems"
                  :menu-props="{maxHeight: 320, eager: true}"
                  :update="calculatePosition(tourCashRegisterReceiptPosition)"
                  :rules="(idx === 0 || !!tourCashRegisterReceiptPosition.amountNet || !!tourCashRegisterReceiptPosition.amountGross) ? requiredRule : []"
                  :clearable="true"
                  return-object
                ></v-autocomplete>

                <v-text-field
                  v-if="tourCashRegisterReceipt.type !== TourCashRegisterReceiptType.INCREASE && !!tourCashRegisterReceiptPosition.amountGross"
                  :model-value="tourCashRegisterReceiptPosition.amountNetCalculated ?? null"
                  label="Nettobetrag (exkl. MwSt.)"
                  class="calculated-field"
                  :reverse="true"
                  disabled
                ></v-text-field>

                <CurrencyInput
                  v-if="tourCashRegisterReceipt.type !== TourCashRegisterReceiptType.INCREASE && !tourCashRegisterReceiptPosition.amountGross"
                  v-model="tourCashRegisterReceiptPosition.amountNet"
                  :options="{ }"
                  :currency="tourCashRegisterReceipt.tourCashRegister.currency"
                  label="Nettobetrag (exkl. MwSt.)"
                  :disabled="!!tourCashRegisterReceiptPosition.amountGross"
                  :required="(idx === 0 && !tourCashRegisterReceiptPosition.amountGross) || (idx > 0 && !!tourCashRegisterReceiptPosition.fee)"
                  @change="calculatePosition(tourCashRegisterReceiptPosition)"
                />


                <v-btn
                  v-if="idx > 0"
                  size="x-small"
                  style="width: 100%"
                  @click="deletePosition(idx)"
                  class="mb-3"
                >
                  Position entfernen
                </v-btn>

                <v-btn
                  v-if="idx === (tourCashRegisterReceipt.tourCashRegisterReceiptPositions.length - 1) && checkIfAllPositionValuesAreSet()"
                  size="x-small"
                  style="width: 100%"
                  @click="addEmptyPosition()"
                  class="mb-3"
                >
                  Weiteren Betrag mit abweichendem MwSt.-Satz anlegen
                </v-btn>
              </div>
            </template>

            <v-text-field
              v-if="tourCashRegisterReceipt.type === TourCashRegisterReceiptType.EXPENDITURE"
              v-model="tourCashRegisterReceipt.text"
              label="Buchungstext"
              :rules="requiredRule"
            ></v-text-field>

            <v-autocomplete
              v-model="tourCashRegisterReceipt.oracleCodeMixedId"
              item-title="name"
              item-value="id"
              label="Zuordnung"
              :items="oracleCodeItems"
              :menu-props="{maxHeight: 320, eager: true}"
              :rules="requiredRule"
              return-object
            ></v-autocomplete>

            <v-autocomplete
              v-if="tourCashRegisterReceipt.type === TourCashRegisterReceiptType.EXPENDITURE"
              v-model="tourCashRegisterReceipt.tourCashRegisterReceiptGroup"
              item-title="name"
              item-value="id"
              label="Gruppe wählen"
              :items="tourCashRegisterReceiptGroups"
              :menu-props="{maxHeight: 320, eager: true}"
              :rules="requiredRule"
              return-object
            ></v-autocomplete>

            <v-text-field
              v-if="tourCashRegisterReceipt.type === TourCashRegisterReceiptType.EXPENDITURE"
              v-model="tourCashRegisterReceipt.budgetline"
              label="Budgetline"
            ></v-text-field>

            <v-text-field
              v-model="tourCashRegisterReceipt.comment"
              label="Bemerkung"
            ></v-text-field>

            <v-btn
              color="success"
              style="width: 100%"
              @click="saveReceipt()"
              :disabled="isSaving"
            >
          <span
            class="pr-2"
            v-if="isSaving"
          >
            <v-icon
              icon="fa:fas fa-rotate fa-spin"
            ></v-icon>
          </span>
              Speichern
            </v-btn>
          </div>

          <hr class="mt-5" />

          <v-btn
            v-if="!!tourCashRegisterReceipt.type && saveType !== 'update'"
            size="x-small"
            class="mt-5"
            style="width: 100%"
            @click="resetForm()"
          >
            Zurücksetzen
          </v-btn>

          <v-btn
            color="error"
            size="x-small"
            class="mt-5"
            style="width: 100%"
            @click="deleteReceipt()"
          >
            Löschen
          </v-btn>

          <v-btn
            v-if="type === 'multiple' && !!nextUnfinishedReceiptId"
            size="x-small"
            @click="skipToNext()"
            class="mt-5"
            style="width: 100%"
            :disabled="isSaving"
          >
          <span
            class="pr-2"
            v-if="isSaving"
          >
            <v-icon
              icon="fa:fas fa-rotate fa-spin"
            ></v-icon>
          </span>
            Überspringen
          </v-btn>
        </v-form>
      </div>
    </div>
  </v-container>

  <Confirm ref="veeConfirmRef" />
</template>

<style scoped>
@import "@/styles/tour-cash-register.css";

hr {
  color: #ddd
}

.calculated-field .v-field__input {
  padding-inline-end: 5px !important;
}

.bordered-amount-block {
  border: 1px solid #ccc;
  padding: 15px 15px 0 15px;
}
</style>

<script lang="ts">

import { useRoute, useRouter } from "vue-router";
import { onMounted, reactive, ref, watch } from "vue";
import TourCashRegisterService from "@/api/TourCashRegisterService";
import { useNumberFormatter } from "@/helpers/formatters";
import TourCashRegisterNavigationButtonBack from "@/components/TourCashRegisterNavigationButtonBack.vue";
import TourCashRegisterNavigationButtonLogout from "@/components/TourCashRegisterNavigationButtonLogout.vue";
import TourCashRegisterReceipt from "@/interfaces/TourCashRegisterReceipt";
import { getTextByType, TourCashRegisterReceiptType } from "@/modules/TourCashRegisterReceiptType";
import TourCashRegisterReceiptPosition from "@/interfaces/TourCashRegisterReceiptPosition";
import CurrencyInput from "@/components/CurrencyInput.vue";
import DatepickerInput from "@/components/DatepickerInput.vue";
import { useStore } from "vuex";
import Confirm from "@/components/Confirm.vue";
import { VForm } from "vuetify/components";

export default {
  components: { Confirm, DatepickerInput, CurrencyInput, TourCashRegisterNavigationButtonBack, TourCashRegisterNavigationButtonLogout },
  setup(props, { refs }) {
    onMounted(async () => {
      await init()
    })

    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const isValid = ref()
    const isLoading = ref(true)
    const isSaving = ref(false)
    const saveOrDeleteIsDone = ref(false)
    const saveType = ref('finish')

    const myForm = ref({} as VForm)
    const tourCashRegisterReceipt = ref({} as TourCashRegisterReceipt)
    const feeItems = ref([])
    const oracleCodeItems = ref([])
    const tourCashRegisterReceiptGroups = ref([])

    const veeConfirmRef = ref<InstanceType<typeof Confirm>>();

    const tourCashRegisterService = ref(new TourCashRegisterService())

    const { formatAmountAndCurrency, formatForCalendar } = useNumberFormatter()

    const documentNumber = ref()
    const documentFile = ref()
    const nextUnfinishedReceiptId = ref()

    let type = route.params.type

    watch(
      () => route.params, // Vue 2: props.value
      () => {
        init()
      }
    )

    watch(
      () => tourCashRegisterReceipt.value.type,
      async (value) => {
        tourCashRegisterReceiptGroups.value = []
        if (value && value !== TourCashRegisterReceiptType.INCREASE) {
          await tourCashRegisterService.value.getTourCashRegisterReceiptGroups(value).then(data => {
            if (data.success) {
              tourCashRegisterReceiptGroups.value = data.data
            }
          })
        }
      }
    )

    const init = async () => {
      let success = true
      isSaving.value = false

      isLoading.value = true
      nextUnfinishedReceiptId.value = null
      let id = parseInt(route.params.id.toString())
      let editType = route.query.editType ? route.query.editType.toString() : null

      await tourCashRegisterService.value.getTourCashRegisterReceipt(id, editType).then(data => {
        if (data.success) {
          tourCashRegisterReceipt.value = data.data

          if (editType === 'admin') {
            saveType.value = 'update'
          }
        } else {
          success = false
          router.push({ name: 'tour-cash-register' })
        }
      })

      if (success) {
        await tourCashRegisterService.value.getFileByGuid(tourCashRegisterReceipt.value.tourCashRegisterFile.guid).then(attachmentData => {
          if (!!attachmentData && attachmentData.success) {
            tourCashRegisterReceipt.value.documentFile = (new File([attachmentData.data], tourCashRegisterReceipt.value.tourCashRegisterFile.originalName, { type: tourCashRegisterReceipt.value.tourCashRegisterFile.mimeType }))
            documentFile.value = URL.createObjectURL(tourCashRegisterReceipt.value.documentFile)
          }
        })
      }

      if (success) {
        if (!tourCashRegisterReceipt.value.tourCashRegisterReceiptPositions || tourCashRegisterReceipt.value.tourCashRegisterReceiptPositions.length === 0) {
          tourCashRegisterReceipt.value.tourCashRegisterReceiptPositions = []
          addEmptyPosition()
        }
      }

      if (success) {
        await tourCashRegisterService.value.getOracleCodesForTour(tourCashRegisterReceipt.value.tourCashRegister.tourId).then(data => {
          if (data.success) {
            oracleCodeItems.value = data.data.data
          } else {
            success = false
          }
        })
      }

      if (success) {
        await tourCashRegisterService.value.getAllFees().then(data => {
          if (data.success) {
            feeItems.value = data.data
          } else {
            success = false
          }
        })
      }

      if (success && type === 'multiple') {
        await tourCashRegisterService.value.getNextUnfinishedReceiptId(tourCashRegisterReceipt.value.id).then(data => {
          if (data.success) {
            nextUnfinishedReceiptId.value = data.data.id
          }
        })
      }


      if (success) {
        isLoading.value = false
      }
    }

    const validate = async () => {
      await myForm.value.validate()

      // validate date
      let previousDate = tourCashRegisterReceipt.value.date ?? null
      tourCashRegisterReceipt.value.date = '01.01.2000'
      tourCashRegisterReceipt.value.date = previousDate

      tourCashRegisterReceipt.value.tourCashRegisterReceiptPositions.forEach((item, idx) => {
        let previousVal

        previousVal = item.amountGross ?? null
        item.amountGross = 1
        item.amountGross = previousVal

        previousVal = item.amountNet ?? null
        item.amountNet = 1
        item.amountNet = previousVal

        if (idx === 0 && !item.amountNet && !item.amountGross) {
          isValid.value = false
        }

        if (idx > 0
          && (
            ((!!item.amountNet || !!item.amountGross) && !item.fee)
            || !!item.fee && !item.amountNet && !item.amountGross
          )
        ) {
          isValid.value = false
        }
      })
    }

    const saveReceipt = async () => {
      isSaving.value = true
      await validate()

      if (isValid.value) {
        await tourCashRegisterService.value.saveReceipt(saveType.value, tourCashRegisterReceipt.value).then(data => {
          if (!!data && !!data.data && data.data.success) {
            if (saveType.value === 'update') {
              store.commit('setSuccessNotification', 'Der Beleg wurde erfolgreich gespeichert.')
              saveOrDeleteIsDone.value = true
            } else {
              store.commit('setSuccessNotification', 'Der Beleg wurde erfolgreich übermittelt.')

              if (type === 'single' || !nextUnfinishedReceiptId.value) {
                router.push({ name: 'tour-cash-register-receipt-index', params: { id: tourCashRegisterReceipt.value.tourCashRegister.id } })
              }
              if (type === 'multiple' && !!nextUnfinishedReceiptId.value) {
                router.push({ name: 'tour-cash-register-receipt-edit', params: { type: 'multiple', id: nextUnfinishedReceiptId.value } })
              }
            }
          } else {
            isSaving.value = false
            let msg = 'Der Beleg konnte nicht übermittelt werden.'
            if (!!data && !!data.data && !!data.data.msg) {
              msg = data.data.msg
            }
            store.commit('setErrorNotification', msg)
          }
        })
      } else {
        isSaving.value = false
        store.commit('setErrorNotification', 'Bitte prüfen Sie die Felder auf Fehler.')
      }
    }

    const skipToNext = () => {
      router.replace({ name: 'tour-cash-register-receipt-edit', params: { type: 'multiple', id: nextUnfinishedReceiptId.value } })
    }

    const formatDate = () => {
      return formatForCalendar(tourCashRegisterReceipt.value.date)
    }

    const resetForm = async () => {
      veeConfirmRef.value
        .open('Zurücksetzen', 'Wirklich zurücksetzen?', { color: "default" })
        .then(confirm => {
          if (confirm) {
            tourCashRegisterReceipt.value.type = null
            tourCashRegisterReceipt.value.date = null
            tourCashRegisterReceipt.value.text = null
            tourCashRegisterReceipt.value.oracleCodeMixedId = null
            tourCashRegisterReceipt.value.comment = null

            tourCashRegisterReceipt.value.tourCashRegisterReceiptPositions = []
            addEmptyPosition()
          }
        });
    }

    const deleteReceipt = async () => {
      veeConfirmRef.value
        .open('Löschen', 'Wirklich löschen?', { color: "default" })
        .then(confirm => {
          if (confirm) {
            let deleteType = 'unfinished'
            if (saveType.value === 'update') {
              deleteType = 'adminDelete'
            }
            tourCashRegisterService.value.deleteReceipt(tourCashRegisterReceipt.value, deleteType).then(data => {
              if (!!data && !!data.data && data.data.success) {
                if (saveType.value === 'update') {
                  store.commit('setSuccessNotification', 'Der Beleg wurde erfolgreich gelöscht.')
                  saveOrDeleteIsDone.value = true
                } else {
                  if (type === 'single' || !nextUnfinishedReceiptId.value) {
                    router.push({ name: 'tour-cash-register-receipt-index', params: { id: tourCashRegisterReceipt.value.tourCashRegister.id } })
                  }
                  if (type === 'multiple' && !!nextUnfinishedReceiptId.value) {
                    router.push({ name: 'tour-cash-register-receipt-edit', params: { type: 'multiple', id: nextUnfinishedReceiptId.value } })
                  }
                }
              }
            })
          }
        });
    }

    const requiredRule = ref([
      v => !!v || 'Darf nicht leer sein',
    ])

    const calculatePosition = (position) => {
      if (!!position.fee && !!position.amountNet) {
        let val = parseFloat(position.amountNet) * (1 + position.fee.value / 100)
        position.amountGrossCalculated = formatAmountAndCurrency(val, tourCashRegisterReceipt.value.tourCashRegister.currency)
      }

      if (!!position.fee && !!position.amountGross) {
        let val = parseFloat(position.amountGross) / (1 + position.fee.value / 100)
        position.amountNetCalculated = formatAmountAndCurrency(val, tourCashRegisterReceipt.value.tourCashRegister.currency)
      }
    }

    function addEmptyPosition() {
      const tourCashRegisterReceiptPosition = reactive({} as TourCashRegisterReceiptPosition)
      tourCashRegisterReceipt.value.tourCashRegisterReceiptPositions.push(tourCashRegisterReceiptPosition)
    }

    function deletePosition(positionIdx) {
      veeConfirmRef.value
        .open('Position löschen', 'Wirklich löschen?', { color: "default" })
        .then(confirm => {
          if (confirm) {
            tourCashRegisterReceipt.value.tourCashRegisterReceiptPositions.splice(positionIdx, 1)
          }
        })
    }

    const checkIfAllPositionValuesAreSet = () => {
      let receipts = tourCashRegisterReceipt.value.tourCashRegisterReceiptPositions.filter((item) => {
        return (!item.amountGross && !item.amountNet) || !item.fee
      })

      return receipts.length === 0
    }

    const openFile = (evt) => {
      evt.preventDefault()
      window.open(import.meta.env.VITE_CONCERTON_URL + '/tour-cash-register-file/getfile?file=' + tourCashRegisterReceipt.value.tourCashRegisterFile.guid, 'tour-cash-register-file-preview', 'width=1500, height=800');
    }

    const closeWindow = () => {
      window.close();
    }

    return {
      type,
      nextUnfinishedReceiptId,
      TourCashRegisterReceiptType,
      getTextByType,
      myForm,
      isValid,
      isSaving,
      isLoading,
      saveOrDeleteIsDone,
      saveType,
      tourCashRegisterReceipt,
      feeItems,
      formatAmountAndCurrency,
      documentNumber,
      documentFile,
      requiredRule,
      oracleCodeItems,
      tourCashRegisterReceiptGroups,
      addEmptyPosition,
      deletePosition,
      formatDate,
      saveReceipt,
      skipToNext,
      calculatePosition,
      checkIfAllPositionValuesAreSet,
      resetForm,
      deleteReceipt,
      veeConfirmRef,
      openFile,
      closeWindow,
    }
  },
}

</script>
