<template>
  <v-container v-if="!isLoading" class="pt-10">
    <h1 class="mb-4">Requirement-Übersicht (<a href="#" @click="backToSystem">{{tour.name}}</a>)</h1>

    <template v-for="requirementContainerForTour in allRequirementTourContainers">
      <RequirementTourContainerBlock
        :requirementTourContainer="requirementContainerForTour"
        :allConcertsForTour="allConcertsForTour"
        :allCurrencies="allCurrencies"
      ></RequirementTourContainerBlock>
    </template>

  </v-container>
</template>

<script lang="ts" setup>

import {onMounted, Ref, ref} from "vue"
import {useRoute, useRouter} from "vue-router"
import RequirementService from "@/api/RequirementService";
import RequirementTourContainer from "@/interfaces/RequirementTourContainer";
import RequirementTourContainerBlock from "@/components/RequirementTourContainerBlock.vue";
import Concert from "@/interfaces/Concert";
import CurrencyService from "@/api/CurrencyService"
import Currency from "@/interfaces/Currency"
import TourService from "@/api/TourService"
import Tour from "@/interfaces/Tour"

onMounted(async () => {
  await init()
})

const route = useRoute()
const router = useRouter()

const requirementService = ref(new RequirementService())
const currencyService = ref(new CurrencyService())
const tourService = ref(new TourService())

const id = ref()
const isLoading = ref(true)

const allRequirementTourContainers: Ref<RequirementTourContainer[]> = ref([])
const allConcertsForTour: Ref<Concert[]> = ref([])
const allCurrencies: Ref<Currency[]> = ref([])
const tour: Ref<Tour> = ref({})

const init = async () => {
  id.value = parseInt(route.params.id.toString())

  await requirementService.value.getAllRequirementContainersForTour(id.value).then(data => {
    if (data.success && data.data) {
      allRequirementTourContainers.value = data.data
    }
  })

  await requirementService.value.getAllConcertsForTour(id.value).then(data => {
    if (data.success&& data.data) {
      allConcertsForTour.value = data.data
    }
  })

  await currencyService.value.getCurrencies().then(data => {
    if (data.success&& data.data) {
      allCurrencies.value = data.data
    }
  })

  await tourService.value.getTourById(id.value).then(data => {
    if (data.success&& data.data) {
      tour.value = data.data
    }
  })

  isLoading.value = false
}

const backToSystem = () => {
  window.location.href = import.meta.env.VITE_CONCERTON_URL + "/tour/details?id=" + id.value + "&tab=requirements"
}
</script>

<style>
#app {
  max-width: inherit !important;
}
.v-container {
  max-width: inherit !important;
}
</style>
