<template>
  <tr>
    <td>
      <template v-if="concert.temporaryComment">
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-icon icon="mdi-comment" v-bind="props"></v-icon>
          </template>
          <span v-html="nl2br(concert.temporaryComment)"></span>
        </v-tooltip>
      </template>
    </td>
    <td>{{ getShortWeekday(concert.date) }}</td>
    <td>{{ concert.date }}</td>
    <td>{{ concert.begin }}</td>
    <td>{{ concert.venueObj?.address?.city }}</td>
    <td>{{ concert.venueObj?.nameWithoutCity }}</td>
    <td>{{ concert.capacity }}</td>
    <td>{{ concert.temporaryType }}</td>
    <td>
      <v-select
        v-model="concert.department"
        :items="allDepartments"
        item-title="desc"
        item-value="id"
        :menu-props="{maxHeight: 320, eager: true}"
        style="min-width: 300px; width: 300px;"
        label="Department"
        return-object
        class="pt-5"
        density="compact"
      ></v-select>
    </td>
    <td>{{ concert.coPromoterObj?.name }}</td>
    <td>{{ concert.selfPromoted ? "ja" : "nein" }}</td>
    <td>{{ concert.flatDeal ? "ja" : "nein" }}</td>
    <td>
      <v-text-field
        v-model="concert.oracleCode"
        label="Oracle-ID *"
        class="pt-5"
        density="compact"
        style="width: 155px"
        :rules="oracleCodeRule"
        required
      >
      </v-text-field>
    </td>
    <td>{{ concert.projectName }}</td>
  </tr>
</template>

<script setup lang="ts">
import {useDateFormatter} from "@/helpers/dateFormatter"
import {ref}              from "vue"
import Concert            from "@/interfaces/Concert"
import {useString}        from "@/helpers/string"

const {getShortWeekday} = useDateFormatter()
const {nl2br} = useString()

const props = defineProps<{
  concert: Concert,
  allDepartments: any
}>()

const oracleCodeRule = ref([
  v => !!v || "Darf nicht leer sein"
])

</script>
