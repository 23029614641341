<template>
  <v-container v-if="!isLoading" class="pt-10">
    <div class="d-flex">
      <div>
        <h1>Konzertanlage</h1>
      </div>
      <div style="margin-left: auto;">
        <TutorialButton video-id="2"></TutorialButton>
      </div>
    </div>

    <div v-if="isSaved" class="text-center">
      <div class="text-success">Die Konzerte wurden erfolgreich gespeichert.</div>
      <br/>
      <div>
        <div>
          <v-btn @click="reloadPage">Weitere Konzerts anlegen</v-btn>
          &nbsp;
          <v-btn @click="backToSystem">Zurück zum System</v-btn>
        </div>
      </div>
    </div>
    <v-form @submit.prevent="saveConcerts" ref="myForm" v-model="isValid" v-else>

      <v-row dense>
        <v-col>
          <v-autocomplete
            label="Tournee"
            v-model="selectedTour"
            v-model:search="tourSearch"
            :loading="tourLoading"
            :items="tourItems"
            item-title="name"
            item-value="id"
            @update:modelValue="selectTour"
            :custom-filter="customFilter"
            :no-data-text="noResultsText"
            :menu-props="{maxHeight: 320, eager: true}"
            :disabled="!!selectedType"
            return-object
            clearable
          ></v-autocomplete>
          <input v-if="showFocusElem" ref="tourSelectedElem">
        </v-col>
      </v-row>

      <v-row dense v-if="selectedTour">
        <v-col>
          <v-select
            label="Art"
            v-model="selectedType"
            item-title="name"
            item-value="id"
            :items="getDepartments()"
            :menu-props="{maxHeight: 320, eager: true}"
            return-object
            clearable
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="mb-2"
             v-if="selectedType"
             dense
      >
        <v-btn @click="addNewConcert"
               size="small"
               :disabled="concerts.filter((item) => { return item.isNew && (!item.isMarkedForSave || item.showSeriesBlock) }).length > 0"
        >
          Neue Konzert(e) hinzufügen
        </v-btn>
      </v-row>

      <v-row v-if="selectedTour && selectedType">
        <v-col>
          <v-table density="compact">
            <thead>
            <tr>
              <th></th>
              <th style="width: 85px;">Oracle-ID</th>
              <th class="text-left" style="width: 50px;">Tag</th>
              <th class="text-left" style="width: 150px">Datum</th>
              <th class="text-left" style="width: 100px">Beginn</th>
              <th class="text-left">Multiple-Konzerte</th>
              <th class="text-left">Ort</th>
              <th class="text-left" style="width: 300px">Location</th>
              <th class="text-left" style="width: 100px">Kap</th>
              <th class="text-center" style="width: 75px">SP</th>
              <th class="text-center" style="width: 75px">Flat-Deal</th>
              <th class="text-left" style="width: 300px">Co-Promoter</th>
              <th class="text-left" :style="concerts.filter((item) => { return item.selfPromoted }).length > 0 ? 'width: 300px;' : 'width: 50px;'">Arr.</th>
              <th class="text-center" style="width: 75px">External-<br/>money</th>
              <th class="text-left"></th>
            </tr>
            </thead>

            <tbody>
            <template v-for="(concert, concertIndex) in concerts">
              <ConcertCreateItem :concert="concert"
                                 :concert-index="concertIndex"
                                 :concerts="concerts"
                                 @saveConcert="saveConcert"
                                 @addNewConcert="addNewConcert"
              ></ConcertCreateItem>
            </template>
            </tbody>
          </v-table>
        </v-col>
      </v-row>

      <v-row v-if="selectedType">
        <v-col>
          <v-btn @click="backToSystem">Abbrechen</v-btn>
          <v-btn @click="saveConcerts" :disabled="!canSaveConcerts()" class="ml-3">Speichern und Konzert(e) jetzt anlegen</v-btn>
        </v-col>
      </v-row>

    </v-form>
  </v-container>
</template>

<script setup lang="ts">
import { useStore } from "vuex"
import { onMounted, reactive, Ref, ref, watch } from "vue"
import ConcertService from "@/api/concert/ConcertService"
import Concert from "@/interfaces/Concert"
import { useDateFormatter } from "@/helpers/dateFormatter"
import ConcertCreateItem from "@/components/ConcertCreateItem.vue"
import { useRoute } from "vue-router"
import TutorialButton from "@/components/TutorialButton.vue"

onMounted(async () => {
  await init()
})

const init = async () => {
  await concertService.value.findTypes().then(data => {
    availableDepartments.value = data.data
  })

  let tourId = route.params.id ? parseInt(route.params.id.toString()) : null
  if (tourId) {
    await concertService.value.getTour(tourId).then(data => {
      selectedTour.value = data.data
      concerts.value = data.data.concerts ?? []
    })

    addNewConcert()
  }

  isLoading.value = false
}

const route = useRoute()
const store = useStore()

const concertService = ref(new ConcertService())

const myForm = ref(null)
const isValid = ref(true)

const isSaved = ref(false)
const isSaving = ref(false)
const isLoading = ref(true)

const selectedTour = ref()
const selectedType = ref()
const concerts: Ref<Concert[]> = ref([])

const tourLoading = false
const tourItems = ref()
const tourSearch = ref()
const tourSearchTimer = ref()
const tourSelectedElem = ref()

const availableDepartments = ref()

const showFocusElem = ref(false)

const noResultsText = "Keine Ergebnisse"

const { getDateByDateString, getDateStringByDate } = useDateFormatter()

watch(() => tourSearch,
  (currentValue) => {
    clearTimeout(tourSearchTimer.value)
    tourSearchTimer.value = setTimeout(() => {
      if (currentValue.value.length > 2) {
        concertService.value.findTours(currentValue.value).then(data => {
          tourItems.value = data.data
        })
      } else {
        tourItems.value = []
      }
    }, 500)
  },
  { deep: true }
)

const selectTour = async () => {
  if (selectedTour.value?.id) {
    await concertService.value.getTour(selectedTour.value.id).then(data => {
      concerts.value = data.data.concerts ?? []
    })

    addNewConcert()
  }
}

const addNewConcert = () => {
  let date = null
  if (concerts.value.length > 0) {
    let tmp = concerts.value[concerts.value.length - 1].toDate ?? concerts.value[concerts.value.length - 1].date
    if (tmp) {
      let tmpDate = getDateByDateString(tmp)
      tmpDate?.setDate(tmpDate?.getDate() + 1)
      date = getDateStringByDate(tmpDate)
    }
  }
  if (!date) {
    date = getDateStringByDate(new Date())
  }

  const concert = reactive({} as Concert)
  concert.date = date
  concert.isNew = true
  concerts.value.unshift(concert)
}

const sortConcertsByDate = () => {
  concerts.value.sort((a, b) => {
    let myDateA = getDateByDateString(a.date)
    let myDateB = getDateByDateString(b.date)

    return myDateA - myDateB
  })
}

const getDepartments = () => {
  return availableDepartments.value
}

const customFilter = () => {
  // this just disables the vuetify filter, filtering items is done via axios
  return true
}

const validate = async (concertIndex) => {
  await myForm.value.validate(`concerts[${concertIndex}]`)
  concerts.value[concertIndex].isValid = isValid.value
}

const saveConcert = async (concertIndex, callback) => {
  await validate(concertIndex)
  callback()

  if (concerts.value[concertIndex].isValid) {
    concerts.value[concertIndex].toDate = concerts.value[concertIndex].date
    sortConcertsByDate()
  }
}

const canSaveConcerts = () => {
  let canSave = !!selectedTour

  canSave &= concerts.value.filter((item) => {
    return item.isNew
  }).length > 0
  canSave &= concerts.value.filter((item) => {
    return item.isMarkedForSave
  }).length > 0
  canSave &= concerts.value.filter((item) => {
    return item.isNew && ( !item.isMarkedForSave || item.showSeriesBlock )
  }).length === 0

  return canSave
}

const validateAll = () => {
  myForm.value.validate()
}

const saveConcerts = async () => {
  isSaving.value = true
  await validateAll()

  if (isValid.value) {
    concertService.value.sendAndSave(concerts.value, selectedTour.value.id, selectedType.value.id).then(data => {
      if (!!data && !!data.data && data.data.success) {
        store.commit("unsetDirty")
        store.commit("setSuccessNotification", "Die Konzerte wurden erfolgreich übermittelt.")
        isSaved.value = true
      } else {
        isSaving.value = false
        store.commit("setErrorNotification", "Die Konzerte konnten nicht übermittelt werden.")
      }
    })
  } else {
    isSaving.value = false
    store.commit("setErrorNotification", "Bitte prüfen Sie die Felder auf Fehler.")
  }
}

const reloadPage = () => {
  window.location.reload()
}

const backToSystem = () => {
  window.location.href = import.meta.env.VITE_CONCERTON_URL + '/tour/details?id=' + selectedTour.value.id
}
</script>

<style>
.dp-field {
  height: 56px;
  background: #F6F6F6;
  border-bottom: 1px solid #A5A5A5;
  opacity: 1;
  /*margin-bottom: 22px;*/
  border-radius: 2px 2px 0 0;
}

.v-theme--myCustomDarkTheme .dp-field {
  background: #2A3339;
  border-bottom: 1px solid #70767A;
}

.v-theme--myCustomDarkTheme .dp-field input {
  color: white;
}

.dp-field:hover {
  height: 56px;
  background: #EDEDED;
  border-bottom: 1px solid #3A3A3A;
  opacity: 1;
}

.v-theme--myCustomDarkTheme .dp-field:hover {
  background: #323B40;
  border-bottom: 1px solid #CDCFD1;
}

.dp-label {
  top: 7px;
  font-size: 12px;
  padding: 0 16px;
  color: #666
}

.v-theme--myCustomDarkTheme .dp-label {
  color: rgba(255, 255, 255, 0.87);
}

.dp__input.dp__input_icon_pad {
  border: none;
  border-radius: 0;
  background: transparent;
  padding: 0 12px 6px 35px;
}

.dp__icon.dp__input_icon.dp__input_icons {
  padding: 2px 12px 6px 12px;
}

a.v-btn, a.v-btn:hover {
  text-decoration: none;
}

.dp-block .v-input__details {
  padding-inline-start: 16px;
  padding-inline-end: 16px;
}

i.fa-spin {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-text-grey .v-messages__message {
  color: #666;
}
</style>
