<template>
  <template v-if="!isLoading">
    <VenueSpecsUploadCreate :company-id="companyId"
                            :edit-mode="editMode"
                            :venue-specs-chapter-type="venueSpecsChapterType"
                            @add-item="addItem"
    ></VenueSpecsUploadCreate>

    <draggable :list="venueSpecsFiles"
               item-key="id"
               v-bind="dragOptions"
               @end="draggingEnd"
               @start="dragging = true"
    >
      <template #item="{ element, index }">
        <VenueSpecsUploadItem :edit-mode="editMode"
                              :idx="index"
                              :venue-specs-chapter-type="venueSpecsChapterType"
                              :venue-specs-file="element"
                              @remove-item="removeItem"
                              @update-item="updateItem"
        ></VenueSpecsUploadItem>
      </template>
    </draggable>
  </template>
</template>

<script lang="ts" setup>

import {onMounted, Ref, ref}  from "vue"
import VenueSpecsService      from "@/api/VenueSpecsService"
import VenueSpecsFile         from "@/interfaces/VenueSpecsFile"
import VenueSpecsUploadItem   from "@/components/VenueSpecsUploadItem.vue"
import VenueSpecsUploadCreate from "@/components/VenueSpecsUploadCreate.vue"
import draggable              from "vuedraggable"

onMounted(async () => {
  await init()
})

const init = async () => {
  await venueSpecsService.value.getUploadedFilesByType(props.companyId, props.venueSpecsChapterType).then(data => {
    venueSpecsFiles.value = data.data
  })
  isLoading.value = false
}

const props = defineProps<{
  companyId: number,
  venueSpecsChapterType: string,
  editMode: boolean
}>()

const isLoading = ref(true)

const venueSpecsService = ref(new VenueSpecsService())
const venueSpecsFiles: Ref<VenueSpecsFile[]> = ref([])

const dragging = ref(false)
const dragOptions = ref({
  animation: 200,
  ghostClass: "ghost",
  forceFallback: true,
  scrollSensitivity: 200,
  handle: ".handle"
})

const draggingEnd = async () => {
  await venueSpecsService.value.resortUploads(venueSpecsFiles.value, props.venueSpecsChapterType, props.companyId).then(data => {
    if (data.success) {
      venueSpecsFiles.value = data.data.venueSpecsFiles
    }
  })
  dragging.value = false
}

const addItem = (item) => {
  venueSpecsFiles.value.push(item)
}

const removeItem = async (idx: number) => {
  venueSpecsFiles.value.splice(idx, 1)
}

const updateItem = async (idx: number, updatedItem: VenueSpecsFile) => {
  venueSpecsFiles.value[idx] = updatedItem
}
</script>

<style>
.section-header > div {
  padding: 10px;
  font-weight: bold;
}
</style>
