<template>
  <div>
    <div class="d-flex flex-row float-right">
      <div class="pr-2">
        Angelegt von:
        <a :href="hrefToPerson(wikiContentItem.createdById)" target="_blank">
          {{ wikiContentItem.createdByPersonName }}
        </a>
        am
        {{ getDateTimeStringByDate(wikiContentItem.createdTimestamp) }}
      </div>

      <template v-if="wikiContentItem.updatedById">
        <div class="pr-2 pl-2 separator-left">
          Zuletzt bearbeitet von:
          <a :href="hrefToPerson(wikiContentItem.updatedById)" target="_blank">
            {{ wikiContentItem.updatedByPersonName }}
          </a>
          am
          {{ getDateTimeStringByDate(wikiContentItem.updatedTimestamp) }}
        </div>
      </template>
    </div>

    <br style="clear: both" />
  </div>
</template>

<script lang="ts" setup>
import WikiContent          from "@/interfaces/WikiContent"
import { useDateFormatter } from "@/helpers/dateFormatter"

const { getDateTimeStringByDate } = useDateFormatter()

const props = defineProps<{
  wikiContentItem: WikiContent,
}>()

const hrefToPerson = (id: number | null) => {
  return import.meta.env.VITE_CONCERTON_URL + "/person/details?id=" + id
}

</script>

<style scoped>
div {
  font-size: 12px;
  color: #888;
}

.separator-left {
  border-left: 1px solid #ddd;
}
</style>
