import axios           from "axios"
import BaseApiService  from "./BaseApiService"

export default class TourService extends BaseApiService {
  getTourById(tourId: number) {
    return axios.get(this.getApiUrl("/tour/get-tour-by-id"), {params: {tourId: tourId}})
      .then(res => {
        return this.processResult(res)
      })
      .catch(error => {
        return this.processErrorResult(error.response)
      })
  }
}
