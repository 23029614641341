<template>

  <div class="currency-block" :class="extraClass">
    <div class="currency-field" :class="{'currency-field-compact': compact, 'focused': (focused || modelValue), 'disabled': disabled, 'hasError': !!errorMessage }">
      <div class="currency-item">
        <label class="currency-label" @click="inputRef.focus()">{{ label }}</label>

        <input
          ref="inputRef"
          type="text"
          @focus="focused = true"
          @blur="handleBlur()"
          :disabled="disabled"
          reverse
        />
      </div>

      <div class="currency-symbol">
        {{ currency.symbol }}
      </div>
    </div>

    <div class="v-input__details">
      <div class="v-messages">{{ errorMessage ?? hint }}</div>
    </div>
  </div>

</template>


<style>
.currency-field {
  height: 56px;
  background: #F6F6F6;
  border-bottom: 1px solid #A5A5A5;
  opacity: 1;
  border-radius: 4px 4px 0 0;
  display: grid;
  grid-template-areas: "prepend-inner field clear append-inner";
  grid-template-columns: min-content minmax(0,1fr) min-content min-content;
}
.table-cost-assignment .selected-group-row .currency-field {
  background: #c4c4c4;
  border-bottom: 1px solid #828282;
}
.table-cost-assignment .selected-group-row .currency-field .currency-label {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.6
}
.table-cost-assignment .selected-group-item .currency-field {
  background: #e5e5e5;
  border-bottom: 1px solid #989898;
}
.table-cost-assignment .selected-group-item .currency-field .currency-label {
  color: #6d6d6d;
}
.table-cost-assignment .selected-group-row .currency-field.disabled {
  background: #c9c9c9;
  border-bottom: 1px dashed #b9b9b9;
  color: #888888;
  opacity: 0.7;
}
.table-cost-assignment .selected-group-row .currency-field.disabled .currency-symbol {
  color: #666;
  opacity: 0.9;
}
.table-cost-assignment .selected-group-row .currency-field.disabled .currency-label {
  color: #8e8e8e;
}
.table-cost-assignment .selected-group-row .currency-field.disabled:hover {
  background: #c9c9c9;
  border-bottom: 1px dotted #bfbfbf;
}
.currency-field.hasError {
  border-bottom: 1px solid #B00020;
}

.currency-field.hasError .currency-label,
.currency-block .v-input__details .v-messages
 {
  color: #B00020;
  opacity: 1;
}

.currency-field.disabled {
  color: #ddd;
}
.currency-field.disabled .currency-label {
  color: #ddd;
}

.currency-field .currency-item {
  position: relative;
  display: flex;
  flex: 1 0;
  grid-area: field;
  align-items: flex-start;
}

.currency-field .currency-symbol {
  font-size: 16px;
  align-items: flex-start;
  display: flex;
  flex: 1 0;
  grid-area: append-inner;
  padding: 26px 6px 2px 0;
}
.currency-field.currency-field-compact .currency-symbol {
  padding-top: 16px;
}

.currency-field.currency-field-compact {
  height: 40px;
}

.v-theme--myCustomDarkTheme .currency-field {
  background: #2A3339;
  border-bottom: 1px solid #70767A;
}

.v-theme--myCustomDarkTheme .currency-field.hasError {
  border-bottom: 1px solid #B00020;
}

.v-theme--myCustomDarkTheme .currency-field input {
  color: white;
}

.currency-field:hover {
  height: 56px;
  background: #EDEDED;
  border-bottom: 1px solid #3A3A3A;
  opacity: 1;
}
.table-cost-assignment .selected-group-row .currency-field:hover {
  background: #bdbdbd;
  border-bottom: 1px solid #2d2d2d;
}
.table-cost-assignment .selected-group-item .currency-field:hover {
  background: #dddddd;
  border-bottom: 1px solid #353535;
}
.currency-field.hasError:hover {
  border-bottom: 1px solid #B00020;
}
.currency-field.currency-field-compact:hover {
  height: 40px;
}

.v-theme--myCustomDarkTheme .currency-field:hover {
  background: #323B40;
  border-bottom: 1px solid #CDCFD1;
}

.v-theme--myCustomDarkTheme .currency-field.hasError:hover {
  border-bottom: 1px solid #B00020;
}

.currency-label {
  position: absolute;
  top: 16px;
  font-size: 1rem;
  margin: 0 16px;
  color: #666;
}
.currency-field.currency-field-compact .currency-label {
  top: 7px;
}
.currency-field.focused .currency-label {
  top: 3px;
  color: black;
  font-size: 12px;
}
.currency-field.focused.hasError .currency-label {
  color: #B00020;
}

.v-theme--myCustomDarkTheme .currency-label {
  color: rgba(255, 255, 255, 0.87);
}

.currency-field input {
  border: none;
  border-radius: 0;
  background: transparent;
  padding: 26px 6px 2px 16px;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  outline: none;
  text-align: right;
  font-size: 16px;
}

.currency-field.currency-field-compact input {
  padding-top: 16px;
  padding-bottom: 0;
}

.currency-field.focused.hasError {
  border-bottom: 2px solid #B00020;
}

.currency-block .v-input__details {
  padding-inline-start: 16px;
  padding-inline-end: 16px;
}

.v-input__append {
  margin-left: 5px !important;
  margin-right: 10px;
}
</style>

<script lang="ts">
import { useCurrencyInput } from 'vue-currency-input'
import { PropType, ref, watch } from "vue";
import Currency from "@/interfaces/Currency";

export default {
  name: 'CurrencyInput',
  props: {
    modelValue: Number,
    options: Object,
    currency: { type: Object as PropType<Currency>, required: true },
    compact: { type: Boolean, default: false },
    label: { type: String, default: 'Kosten' },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    hint: { type: String, default: null },
    extraClass: { type: String, default: null }
  },
  setup(props, { emit }) {
    props.options.currencyDisplay = 'hidden'
    props.options.currency = props.currency.iso3
    if (!props.options.precision) {
      props.options.precision = 2
    }
    const { inputRef, setValue } = useCurrencyInput(props.options)

    const errorMessage = ref()

    watch(
      () => props.modelValue, // Vue 2: props.value
      (value) => {
        setValue(value)

        // check for required error
        errorMessage.value = null
        if (!!props.required && !value) {
          errorMessage.value = 'Darf nicht leer sein'
        }
      }
    )

    const handleBlur = () => {
      focused.value = false
      emit('blur')
    }

    const focused = ref(false)

    return { inputRef, focused, handleBlur,  errorMessage }
  },
}
</script>
