export function useString() {
  const nl2br = (str: string | null) => {
    return str?.replace(/\n/g, "<br />")
  }

  const isValidHttpUrl = (str: string) => {
    let url;

    try {
      url = new URL(str);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  return { nl2br, isValidHttpUrl }
}
