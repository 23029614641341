<template>
  <div>
    <template v-if="!isLoading">
      <v-form ref="myForm" v-model="isValid" @submit.prevent="save">
        <div class="d-flex">
          <div>
            <v-icon
              :class="!editMode ? 'handle' : 'handle-disabled'"
              icon="mdi-drag-horizontal-variant"
            ></v-icon>
          </div>

          <div class="d-flex flex-grow-1 ml-4">
            <div class="d-flex flex-grow-1 pa-2 item-block-style">
              <div class="image-col">
                <img
                  v-if="preview"
                  :src="preview"
                  alt="image"
                />
              </div>

              <div class="flex-grow-1 ml-2">
                <template v-if="isEditing">
                  <div v-if="venueSpecsChapterType === 'venue_overlay'" class="flex-grow-1">
                    <v-text-field
                      v-model="venueSpecsFile.headline"
                      label="Überschrift"
                    ></v-text-field>
                  </div>

                  <div v-if="venueSpecsChapterType === 'impressions'" class="flex-grow-1">
                    <v-text-field
                      v-model="venueSpecsFile.subline"
                      :rules="requiredRule"
                      label="Unterschrift *"
                    ></v-text-field>
                  </div>

                  <div v-if="venueSpecsChapterType === 'impressions'" class="flex-grow-1">
                    <v-text-field
                      v-model="venueSpecsFile.url"
                      :rules="urlRule"
                      label="Link (https://...)"
                    ></v-text-field>
                  </div>

                  <div v-if="venueSpecsChapterType === 'impressions'" class="flex-grow-1">
                    <v-radio-group
                      v-model="venueSpecsFile.flgFullwidth"
                      inline
                    >
                      <v-radio
                        :value="false"
                        label="Bild hat 50% der Breite"
                      ></v-radio>

                      <v-radio
                        :value="true"
                        label="Bild nutzt die volle Breite"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </template>

                <template v-else>
                  <div v-if="venueSpecsFile.headline">{{ venueSpecsFile.headline }}</div>
                  <div v-if="venueSpecsFile.subline">{{ venueSpecsFile.subline }}</div>
                  <div v-if="venueSpecsFile.url"><a :href="venueSpecsFile.url" target="_blank">Link</a></div>
                  <div v-if="venueSpecsChapterType === 'impressions'">
                    <template v-if="venueSpecsFile.flgFullwidth">
                      <div class="default-column-layout layout-block d-flex">
                        <div class="flex-fill flex-grow-1" style="border: 3px solid black;"></div>
                      </div>
                    </template>

                    <template v-else>
                      <div class="default-column-layout layout-block d-flex">
                        <div style="width: 45px; border: 3px solid black !important;"></div>
                        <div style="width: 45px; border: 1px solid black !important;"></div>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </div>

            <div class="d-flex ml-2">
              <template v-if="isEditing">
                <SaveButton :disabled="isSaving"
                            :is-saving="isSaving"
                            @callback="save"
                ></SaveButton>

                <CancelButton :disabled="isSaving"
                              :is-saving="isSaving"
                              @callback="cancel"
                ></CancelButton>
              </template>

              <template v-else>
                <EditButton v-if="venueSpecsChapterType !== 'rigging'"
                            :disabled="isSaving || editMode"
                            @callback="edit"
                ></EditButton>

                <DeleteButton :disabled="isSaving || editMode"
                              :is-saving="isSaving"
                              @callback="deleteItem"
                ></DeleteButton>
              </template>

              <div class="pt-2 pl-2">
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <v-icon icon="mdi-history" v-bind="props"></v-icon>
                  </template>
                  <span v-html="nl2br(venueSpecsFile.lastChangeText)"></span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </div>
      </v-form>
    </template>
  </div>
</template>

<script lang="ts" setup>

import {onMounted, ref}  from "vue"
import VenueSpecsService from "@/api/VenueSpecsService"
import VenueSpecsFile    from "@/interfaces/VenueSpecsFile"
import {useString}       from "@/helpers/string"
import DeleteButton      from "@/components/DeleteButton.vue"
import useEventsBus      from "@/composables/eventBus"
import SaveButton        from "@/components/SaveButton.vue"
import CancelButton      from "@/components/CancelButton.vue"
import EditButton        from "@/components/EditButton.vue"

onMounted(async () => {
  await init()
})

const init = async () => {
  await venueSpecsService.value.getFileByGuid(props.venueSpecsFile.guid).then(data => {
    if (data.success) {
      preview.value = URL.createObjectURL(new File([data.data], "image", {type: "image/jpg"}))
    }
  })
  isLoading.value = false
}

const props = defineProps<{
  venueSpecsFile: VenueSpecsFile,
  venueSpecsChapterType: string,
  editMode: boolean,
  idx: number
}>()

const emits = defineEmits(["removeItem", "updateItem"])

const {emit} = useEventsBus()
const {nl2br, isValidHttpUrl} = useString()

const venueSpecsService = ref(new VenueSpecsService())

const isLoading = ref(true)
const isSaving = ref(false)
const isEditing = ref(false)
const isValid = ref(true)
const myForm = ref()

const preview = ref()
const objNoReference = ref()
const objNoReferenceFile = ref()

const edit = () => {
  setOriginal()
  emit("setEditMode", true)
  isEditing.value = true
}

const save = async () => {
  isSaving.value = true

  await venueSpecsService.value.updateFile(props.venueSpecsFile).then(data => {
    if (data.success) {
      emit("setEditMode", false)
      emits("updateItem", props.idx, data.data.venueSpecsFile)
      isEditing.value = false
      isSaving.value = false
    }
  })
}

const cancel = () => {
  resetToOriginal()
  emit("setEditMode", false)
  isEditing.value = false
}

const setOriginal = () => {
  // save previous values in case of reset
  objNoReference.value = JSON.parse(JSON.stringify(preview.value))
  objNoReferenceFile.value = JSON.parse(JSON.stringify(props.venueSpecsFile))
}

const resetToOriginal = () => {
  preview.value = JSON.parse(JSON.stringify(objNoReference.value))
  props.venueSpecsFile.url = objNoReferenceFile.value.url
  props.venueSpecsFile.subline = objNoReferenceFile.value.subline
  props.venueSpecsFile.headline = objNoReferenceFile.value.headline
  props.venueSpecsFile.flgFullwidth = objNoReferenceFile.value.flgFullwidth
  isEditing.value = false
}

const deleteItem = async () => {
  isSaving.value = true

  await venueSpecsService.value.deleteFileByGuid(props.venueSpecsFile.guid).then(data => {
    if (data.success) {
      emits("removeItem", props.idx)
    }
  })

  isSaving.value = false
}

const requiredRule = ref([
  v => !!v || "Darf nicht leer sein"
])

const urlRule = ref([
  v => {
    if (!!v && !isValidHttpUrl(v)) {
      return "Ungültiger Link"
    }

    return true
  }
])
</script>

<style scoped>
.image-col {
  border: 1px dashed #aaa;
  width: 160px;
  height: 120px;
}

.item-block-style {
  border: 1px solid #eee;
}

.image-col img {
  max-width: 100%;
  max-height: 100%;
}

.layout-block {
  height: 25px;
  width: 90px;
}

.layout-block > div {
  height: 25px;
  border: 1px solid black;
}

.layout-block > div:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.layout-block > div:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
</style>
