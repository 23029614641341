import Currency from "@/interfaces/Currency";

export function useNumberFormatter() {
  const formatAmountAndCurrency = (amount, currency: Currency, maximumSignificantDigits = 0) => {

    let options = {
      style: 'currency',
      currency: currency.iso3
    }

    if (maximumSignificantDigits !== 0) {
      options.maximumSignificantDigits = maximumSignificantDigits
    }

    const formatter = new Intl.NumberFormat('de-DE', options);

    return formatter.format(amount)
  }

  const formatPercentage = (amount) => {

    let options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }

    const formatter = new Intl.NumberFormat('de-DE', options);

    return formatter.format(amount)
  }

  function formatForCalendar(dateToFormat) {

    let parts = dateToFormat.split('.');
    let myDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));

    // ignore padStart error
    // @ts-ignore
    const day = myDate.getDate().toString().padStart(2, '0');

    // ignore padStart error
    // @ts-ignore
    const month = (myDate.getMonth() + 1).toString().padStart(2, '0');

    const year = myDate.getFullYear();

    return `${day}.${month}.${year}`;
  }

  function formatDateRange(dateFrom, dateTo) {
    if (dateFrom === dateTo) {
      return dateFrom
    }

    let partsFrom = dateFrom.split('.');
    let myDateFrom = new Date(parseInt(partsFrom[2]), parseInt(partsFrom[1]) - 1, parseInt(partsFrom[0]));

    let dayFrom = myDateFrom.getDate().toString().padStart(2, '0');
    const monthFrom = (myDateFrom.getMonth() + 1).toString().padStart(2, '0');
    const yearFrom = myDateFrom.getFullYear();


    let partsTo = dateTo.split('.');
    let myDateTo = new Date(parseInt(partsTo[2]), parseInt(partsTo[1]) - 1, parseInt(partsTo[0]));

    let dayTo = myDateTo.getDate().toString().padStart(2, '0');
    const monthTo = (myDateTo.getMonth() + 1).toString().padStart(2, '0');
    const yearTo = myDateTo.getFullYear();


    let dateRange = `${dayFrom}.${monthFrom}.`


    if (yearFrom !== yearTo) {
      dateRange += `${yearFrom}`
    }

    dateRange += ` - ${dayTo}.${monthTo}.${yearTo}`

    return dateRange
  }

  return { formatAmountAndCurrency, formatPercentage, formatForCalendar, formatDateRange }
}
