<template>
  <template v-if="!isLoading">
    <div class="d-flex">
      <div v-cloak class="flex-grow-1 file-input-item" @drop.prevent="addDropFile" @dragover.prevent>
        <v-file-input
          v-model="file"
          :append-icon="isSaved ? 'mdi-content-save' : 'mdi-content-save-alert'"
          :clearable="false"
          :prepend-icon="''"
          :accept="allowedMimeTypes.toString()"
          label="Bild * (.jpg, .jpeg, .png)"
          @change="previewImage"
          @click:clear="clearPreviewImage"
        ></v-file-input>
      </div>

      <div class="button-block">
        <SaveButton :disabled="!file || file.length === 0"
                    class="ml-2"
                    size="x-large"
                    @callback="save"
        ></SaveButton>

        <CancelButton v-if="file && file.length !== 0"
                      class="ml-2"
                      size="x-large"
                      @callback="clearPreviewImage"
        ></CancelButton>
      </div>
    </div>

    <div class="d-flex">
      <div class="flex-shrink-1" style="width: 300px; height: 400px; position: relative; border: 1px dashed #444">
        <div style="position: absolute; z-index: 1; text-align: center; top: 190px; left: 140px;"
             v-if="preview"
        >
          <v-icon icon="fa:fas fa-rotate fa-spin"></v-icon>
        </div>
        <v-img
          v-if="preview"
          :src="preview"
          alt="image"
          class="prev"
          style="z-index: 2; position: absolute; width: 100%; height: 100%; object-fit: contain;"
          @click="showFullsize = !showFullsize"
        >
          <v-overlay
            v-model="showFullsize"
            class="align-center justify-center"
          >
                <img
                  :src="preview"
                  style="object-fit: contain; max-height: 80vh; max-width: 80vw;"
                  @click="showFullsize = false"
                 alt=""
                />
          </v-overlay>
        </v-img>
      </div>

      <div v-if="venueSpecsFile.guid && isSaved">
        <div class="d-flex">
          <DeleteButton class="ml-2"
                        @callback="deleteItem"
          ></DeleteButton>

          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-icon class="ml-2 mt-2"
                      icon="mdi-history"
                      v-bind="props"
              ></v-icon>
            </template>
            <span v-html="nl2br(venueSpecsFile.lastChangeText)"></span>
          </v-tooltip>
        </div>
      </div>
    </div>
  </template>
</template>

<script lang="ts" setup>

import {onMounted, Ref, ref} from "vue"
import useEventsBus          from "@/composables/eventBus"
import VenueSpecsService     from "@/api/VenueSpecsService"
import {useRoute, useRouter} from "vue-router"
import VenueSpecsFile        from "@/interfaces/VenueSpecsFile"
import {useString}           from "@/helpers/string"
import DeleteButton          from "@/components/DeleteButton.vue"
import CancelButton          from "@/components/CancelButton.vue"
import SaveButton            from "@/components/SaveButton.vue"

onMounted(async () => {
  await init()
})

const showFullsize = ref(false)

const init = async () => {
  await venueSpecsService.value.getDirectionsFile(props.companyId).then(pdfData => {
    if (pdfData.success) {
      venueSpecsFile.value = pdfData.data
    }
  })

  if (venueSpecsFile.value.guid) {
    await venueSpecsService.value.getFileByGuid(venueSpecsFile.value.guid).then(data => {
      if (data.success) {
        preview.value = URL.createObjectURL(new File([data.data], "image", {type: "image/jpg"}))
        setOriginal()
      }
    })
  }

  isLoading.value = false
}

const {bus} = useEventsBus()
const {emit} = useEventsBus()
const {nl2br} = useString()

const route = useRoute()
const router = useRouter()

const venueSpecsService = ref(new VenueSpecsService())

const isLoading = ref(true)
const isSaved = ref(true)

const venueSpecsFile: Ref<VenueSpecsFile> = ref({})

const file = ref()
const preview = ref()
const objNoReference = ref()

const props = defineProps<{
  venueSpecsChapterType: string,
  companyId: number
}>()

const allowedMimeTypes = ['image/jpeg', 'image/png']

const previewImage = async () => {
  preview.value = null
  if (file && mimeTypeCheck(file.value[0].type ?? '')) {
    isSaved.value = false
    setTimeout(function() {
      preview.value = URL.createObjectURL(file.value[0])
    }, 10)

    emit("setEditMode", true)
  }
}

const addDropFile = (e) => {
  if (mimeTypeCheck(e.dataTransfer.files[0].type ?? '')) {
    file.value = Array.from(e.dataTransfer.files).splice(0, 1)
    preview.value = URL.createObjectURL(file.value[0])
    isSaved.value = false
    emit("setEditMode", true)
  }
}

const clearPreviewImage = () => {
  resetToOriginal()
  file.value = []
  emit("setEditMode", false)
}

const save = async () => {
  await venueSpecsService.value.saveDirectionsFile(props.companyId, file.value).then(data => {
    if (data.data.success) {
      isSaved.value = true
      setOriginal()
      file.value = []
      venueSpecsFile.value = data.data.venueSpecsFile
    } else {
      if (data.data.errorMessage) {
        alert(data.data.errorMessage)
      }
    }
  })
  emit("setEditMode", false)
}

const deleteItem = () => {
  venueSpecsService.value.deleteFileByGuid(venueSpecsFile.value.guid).then(data => {
    if (data.data.success) {
      venueSpecsFile.value = <VenueSpecsFile>{}
      preview.value = null
      setOriginal()
    }
  })
}

const setOriginal = () => {
  // save previous values in case of reset
  objNoReference.value = JSON.parse(JSON.stringify(preview.value))
}

const resetToOriginal = () => {
  if (objNoReference.value) {
    preview.value = JSON.parse(JSON.stringify(objNoReference.value))
  } else {
    preview.value = null
  }
  isSaved.value = true
}

function mimeTypeCheck(mimeType: string) {
  return allowedMimeTypes.includes(mimeType)
}
</script>

<style>
.section-header > div {
  padding: 10px;
  font-weight: bold;
}

.file-input-item .mdi-content-save {
  color: green;
}

.file-input-item .mdi-content-save-alert {
  color: red;
}

.prev {
  max-width: 100%;
}
</style>
