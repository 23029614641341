<template>
  <div class="d-flex">
    <div class="no-overflow flex-grow-1">
      <a @click="downloadFile" style="cursor: pointer;">
        <v-tooltip
          activator="parent"
          location="top"
        >
          Herunterladen: {{ requirementValueFile.originalName }}
        </v-tooltip>
        {{ requirementValueFile.originalName }}
      </a>
    </div>
    <div class="text-right d-flex ml-4">
      <DeleteButton
        @callback="deleteFile"
        variant="outlined"
        size="x-small"
      ></DeleteButton>

      <div class="ml-2">
        <v-icon>
          mdi-history
        </v-icon>

        <v-tooltip
          activator="parent"
          location="top"
        >
          {{ requirementValueFile.lastChangeText ?? "-" }}
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {ref} from "vue";
import RequirementValueFile from "@/interfaces/RequirementValueFile";
import DeleteButton from "@/components/DeleteButton.vue";
import RequirementService from "@/api/RequirementService";

const requirementService = ref(new RequirementService())

const emits = defineEmits(["deleteFile"])
const props = defineProps<{
  requirementValueFile: RequirementValueFile,
}>()

const downloadFile = async () => {
  requirementService.value.getFileByGuid(props.requirementValueFile.guid, false).then(data => {
    if (data.success) {
      const url = URL.createObjectURL(data.data)
      const link = document.createElement("a")
      link.href = url
      link.download = props.requirementValueFile.originalName
      link.click()
      URL.revokeObjectURL(url)
    }
  })
}

const deleteFile = async () => {
  emits('deleteFile', props.requirementValueFile.id)
}
</script>

<style scoped>
.no-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
